// Internal dependencies
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components

// Services
import { LibraryService } from '@/services/library.service';
import { DialogService } from '@/services/dialog.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { LoadingService } from '@/services/loading.service';

// Interfaces
import { Domain } from '@/structures/domain';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-domains',
    templateUrl: './domains.component.html',
    styleUrls: ['./domains.component.scss'],
    imports: [CommonModule, SharedModule, MatIconModule, MatButtonModule]
})
export class DomainsComponent implements OnInit {
    domains: Array<Domain>;

    constructor(
        private dialogService: DialogService,
        private libraryService: LibraryService,
        private flashMessageService: FlashMessageService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.domains = [];
        this.loadingService.startLoading('DomainsComponent', 'getDomains');
        this.libraryService.getDomains().subscribe((data: Array<Domain>) => {
            this.domains = data;
            this.loadingService.stopLoading('DomainsComponent', 'getDomains');
        });
    }

    // #region Getters

    isLoading() {
        return this.loadingService.isLoading('DomainsComponent');
    }

    // #endregion Getters

    // #region Handlers

    openDomainDialog(domain?: Domain) {
        this.dialogService.openCreateDomain(domain).subscribe((data: string) => {
            if (data) {
                if (domain) {
                    this.domains.find((_domain: Domain) => domain.name === _domain.name).name =
                        data;
                    this.flashMessageService.flash(`Le domaine <b>${data}</b> a été mis à jour`);
                } else {
                    this.domains.push({ name: data });
                    this.flashMessageService.flash(`Le domaine <b>${data}</b> a été créé`);
                }
            }
        });
    }

    // #endregion Handlers
}
