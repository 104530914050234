@if (draft) {
    <mat-expansion-panel
        class="draft-container"
        displayMode="flat"
        [(expanded)]="opened"
        [hideToggle]="isActivity()"
        [ngClass]="{ 'not-assignable': !canDropContent() }"
        dndDropzone
        (dndDrop)="handleDropContent(undefined)"
        dndDragover
    >
        <mat-expansion-panel-header
            class="header"
            [ngClass]="{ assembly: !isActivity() }"
            [collapsedHeight]="'50px'"
            [expandedHeight]="'50px'"
            [dndDraggable]="draft()"
            [dndDisableIf]="parent() && parent().status === 'approved'"
            (dndStart)="handleStartDrag()"
            (dndEnd)="handleEndDrag()"
        >
            @if (getForbiddenDropTooltipMessage()) {
                <div class="dragover-message">
                    <span class="icon-Warning"></span>{{ getForbiddenDropTooltipMessage() }}
                </div>
            }
            @if (parent() && parent().editingOrder) {
                <div class="order-container">
                    <div class="icon-down up" (click)="handleClickIncreaseOrder($event)"></div>
                    @if (draft().orderNumber) {
                        <div class="order-number">{{ draft().orderNumber }}</div>
                    }
                    <div class="icon-down down" (click)="handleClickDecreaseOrder($event)"></div>
                </div>
            }
            @if (parent() && !parent().editingOrder) {
                <div class="order-container">
                    @if (draft().orderNumber) {
                        <div class="order-number with-margin">
                            {{ draft().orderNumber }}
                        </div>
                    }
                </div>
            }
            <div class="icon" [ngClass]="getIcon()"></div>
            <div
                class="title"
                [tooltip]="getDraftTooltip()"
                tooltipPosition="below-right"
                [ngClass]="{ locked: draft().locked }"
            >
                <div class="name">{{ draft().title }}</div>
                @if (draft().type) {
                    <div class="type">({{ getTypeLabel() }})</div>
                }
            </div>
            <div class="icons">
                <div class="hover-icons">
                    @if (displayIcon('fiche')) {
                        <div
                            class="icon-Fiche"
                            (click)="handleClickViewFiche($event)"
                            [tooltip]="getFicheTooltip()"
                        >
                            @if (draft().publishable === false) {
                                <div class="icon-Warning"></div>
                            }
                        </div>
                    }
                    @if (displayIcon('guide')) {
                        <div
                            class="icon-guide"
                            (click)="handleClickViewGuide($event)"
                            [tooltip]="'Voir le guide'"
                        ></div>
                    }
                    @if (displayIcon('ordoOn')) {
                        <div
                            class="icon-OrdoOn"
                            (click)="handleClickToggleOrdoOn($event)"
                            [tooltip]="'Ordonner le contenu'"
                        ></div>
                    }
                    @if (displayIcon('ordoOff')) {
                        <div
                            class="icon-OrdoOff"
                            (click)="handleClickToggleOrdoOff($event)"
                            [tooltip]="'Désordonner le contenu'"
                        ></div>
                    }
                    @if (displayIcon('clone')) {
                        <div
                            class="icon-Cloner"
                            (click)="handleClickCloneDraft($event)"
                            [tooltip]="'Dupliquer le brouillon'"
                        ></div>
                    }
                    @if (displayIcon('bin')) {
                        <div
                            class="icon-bin"
                            (click)="handleClickDeleteDraft($event)"
                            [tooltip]="'Supprimer d\'easi training'"
                        ></div>
                    }
                    @if (displayIcon('publish')) {
                        <div
                            class="icon-Publier"
                            (click)="handleClickPublishDraft($event)"
                            [tooltip]="'Publier'"
                        ></div>
                    }
                    @if (displayIcon('send-to-validation')) {
                        <div
                            class="icon-envoyer-valideur"
                            (click)="handleClickSendDraftToValidation($event)"
                            [tooltip]="'Envoyer en validation'"
                        ></div>
                    }
                    @if (displayIcon('historique')) {
                        <div
                            class="icon-historique"
                            (click)="handleClickOpenHistory($event)"
                            [tooltip]="'Voir l\'historique'"
                        ></div>
                    }
                    @if (displayIcon('add-child')) {
                        <div
                            class="icon-plus-cercle"
                            (click)="handleClickAddChild($event)"
                            [tooltip]="'Créer un assemblage enfant'"
                        ></div>
                    }
                    @if (displayIcon('remove-child')) {
                        <div
                            class="icon-close"
                            (click)="handleClickRemoveChild($event)"
                            [tooltip]="'Retirer de l\'assemblage'"
                        ></div>
                    }
                    @if (displayIcon('add-to-favorites')) {
                        <div
                            class="icon-Favoris"
                            (click)="handleClickAddToFavorites($event)"
                            [tooltip]="'Ajouter aux favoris'"
                        ></div>
                    }
                    @if (displayIcon('remove-from-favorites')) {
                        <div
                            class="icon-Retirerdesfavoris"
                            (click)="handleClickRemoveFromFavorites($event)"
                            [tooltip]="'Retirer des favoris'"
                        ></div>
                    }
                    @if (displayIcon('edit-activity')) {
                        @if (isActivity()) {
                            <div
                                class="icon-Editer"
                                (click)="handleClickEditActivity($event)"
                                [tooltip]="'Éditer le contenu'"
                            ></div>
                        }
                    }
                    @if (displayIcon('view-activity')) {
                        @if (isActivity()) {
                            <div
                                class="icon-voir"
                                (click)="handleClickOpenActivity($event)"
                                [tooltip]="'Voir l\'activité'"
                            ></div>
                        }
                    }
                </div>
                <div class="default-icons">
                    @if (displayIcon('view-ordered')) {
                        <div class="icon-OrdoOn" [tooltip]="'Contenu ordonné'"></div>
                    }
                    @if (displayIcon('view-locked')) {
                        <div
                            class="icon-cadenas"
                            [tooltip]="
                                'Contenu publié, issu de la bibliothèque, non éditable ici.<br>Pour transformer ce contenu, passez-le en modification'
                            "
                        ></div>
                    }
                    @if (displayIcon('view-favorites')) {
                        <div class="icon-Favoris" [tooltip]="'Contenu favori'"></div>
                    }
                </div>
            </div>
        </mat-expansion-panel-header>
        @if (draft().level && draft().level !== 'activity') {
            <ng-template matExpansionPanelContent>
                @for (child of draft().children; track child.id) {
                    @if (isFirstElement($index)) {
                        <div
                            class="separator-container"
                            dndDropzone
                            [dndDisableIf]="!canDropContent()"
                            (dndDrop)="handleDropContent(0)"
                        >
                            <div class="separator"></div>
                        </div>
                    }
                    <app-creation-space-entry
                        class="expansion-panel-child"
                        [(draft)]="draft().children[$index]"
                        [(parent)]="draft"
                        (deletedDraft)="deletedDraft.emit($event)"
                    ></app-creation-space-entry>
                    <div
                        class="separator-container"
                        dndDropzone
                        [dndDisableIf]="!canDropContent()"
                        (dndDrop)="handleDropContent($index + 1)"
                    >
                        <div class="separator"></div>
                    </div>
                }
                @if (draft().editingOrder && !draft().locked) {
                    <div class="validate-order-container">
                        <div class="confirm button" (click)="handleConfirmOrder($event)">
                            Valider
                        </div>
                        <div class="cancel button" (click)="handleCancelOrder($event)">Annuler</div>
                    </div>
                }
            </ng-template>
        }
    </mat-expansion-panel>
}
