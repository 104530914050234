import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Subscription } from 'rxjs';

import { LibraryService } from '@/services/library.service';
import { ArchiveService } from '@/services/archive.service';
import { FavoriteService } from '@/services/favorite.service';
import { MultiselectService } from '@/services/library-multiselect.service';
import { LoadingService } from '@/services/loading.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { PanelService } from '@/services/panel.service';
import { LoginService } from '@/services/login.service';

import * as FileSaver from 'file-saver';

import { Content } from '@/structures/content';
import { Filter } from '@/structures/filter';

import { UntilDestroy } from '@ngneat/until-destroy';
import { EasiHttpParams } from 'src/app/interceptors/easi-http-params';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-library',
    templateUrl: './library.component.html',
    styleUrls: ['./library.component.scss'],
    standalone: false
})
export class LibraryComponent implements OnInit {
    contents = [];
    counts = {
        parcours: 0,
        bloc: 0,
        competence: 0,
        module: 0,
        sequence: 0,
        activity: 0
    };

    filters: Filter = {
        domain: [],
        level: [],
        category: [],
        type: [],
        h5p_key: [],
        localStructure: '',
        search: '',
        note: '',
        status: ''
    };

    isCatalogView = false;
    isFavoritesView = false;
    isArchiveView = false;
    isReportView = false;
    isDisabledView = false;
    isPreviousVersionView = false;
    fromInit = false;

    isParentOfView = false;
    parentOfContent: Content;
    contentParents: Array<Content> = [];

    tooltipMessage: string = undefined;

    currentPageContent: number;
    currentPageParent: number;
    LIMIT = 30;

    subscriptions = new Subscription();

    getContents$ = new Subscription();
    getContentsCount$ = new Subscription();
    scrollingSubscription = new Subscription();

    constructor(
        private http: HttpClient,
        private loginService: LoginService,
        private libraryService: LibraryService,
        private favoriteService: FavoriteService,
        private archiveService: ArchiveService,
        private multiselectService: MultiselectService,
        private loadingService: LoadingService,
        private flashMessageService: FlashMessageService,
        private panelService: PanelService
    ) {}

    ngOnInit() {
        this.currentPageContent = -1;
        this.currentPageParent = -1;

        this.subscriptions.add(
            this.libraryService.refreshLibrary.subscribe(() => {
                this.refreshLibrary();
            })
        );

        this.subscriptions.add(
            this.favoriteService.contentRemovedFromFavorites.subscribe((content: Content) => {
                if (this.isFavoritesView) {
                    const index = this.contents.indexOf(content);
                    if (index > -1) {
                        this.contents.splice(index, 1);
                    }
                    if (content.level) {
                        this.counts[content.level]--;
                    } else {
                        this.counts.activity--;
                    }
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.contentArchived.subscribe((content: Content) => {
                for (const i in this.contents) {
                    if (this.contents[i].id === content.id) {
                        this.contents.splice(+i, 1);
                        if (content.level) {
                            this.counts[content.level]--;
                        } else {
                            this.counts.activity--;
                        }
                    }
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.contentUnarchived.subscribe((content: Content) => {
                for (const i in this.contents) {
                    if (this.contents[i].id === content.id) {
                        this.contents.splice(+i, 1);
                        if (content.level) {
                            this.counts[content.level]--;
                        } else {
                            this.counts.activity--;
                        }
                    }
                }
            })
        );
        this.subscriptions.add(
            this.libraryService.contentDeleted.subscribe((content: Content) => {
                for (const i in this.contents) {
                    if (this.contents[i].id === content.id) {
                        this.contents.splice(+i, 1);
                        if (content.level) {
                            this.counts[content.level]--;
                        } else {
                            this.counts.activity--;
                        }
                    }
                }
            })
        );

        if (
            !this.loginService.getUser().roles.nationalAdmin &&
            !this.loginService.getUser().roles.nationalTeacher
        ) {
            this.isCatalogView = true;
            this.fromInit = true;
        }
    }

    updateFilters($event: Filter): void {
        this.filters = $event;
        this.refreshLibrary();
    }

    handleViewChange($event) {
        this.isCatalogView = $event.catalog;
        this.isFavoritesView = $event.favorites;
        this.isArchiveView = $event.archive;
        this.isReportView = $event.report;
        this.isDisabledView = $event.disabled;
        this.isPreviousVersionView = $event.previousVersion;

        this.refreshLibrary();
    }

    isLoading(): boolean {
        return this.loadingService.isLoading('library');
    }

    isLoadingNextPage(): boolean {
        return this.loadingService.isLoading('libraryNextPage');
    }

    isAnyFilterSet(): boolean {
        return (
            (!!this.filters.domain && !!this.filters.domain.length) ||
            (!!this.filters.level && !!this.filters.level.length) ||
            (!!this.filters.category && !!this.filters.category.length) ||
            (!!this.filters.type && !!this.filters.type.length) ||
            (!!this.filters.h5p_key && !!this.filters.h5p_key.length) ||
            (!!this.filters.localStructure && !!this.filters.localStructure.length) ||
            (!!this.filters.author && !!this.filters.author.length) ||
            !!this.filters.search ||
            !!this.filters.timecreatedstart ||
            !!this.filters.timecreatedend ||
            !!this.filters.timemodifiedstart ||
            !!this.filters.timemodifiedend ||
            !!this.filters.note
        );
    }

    hasResults(): boolean {
        return this.contents.length >= 1;
    }

    hasParentOfResults(): boolean {
        return this.contentParents.length >= 1;
    }

    refreshLibrary(): void {
        this.currentPageContent = -1;
        this.contents = [];
        this.counts = {
            parcours: 0,
            bloc: 0,
            competence: 0,
            module: 0,
            sequence: 0,
            activity: 0
        };
        this.getContents();
    }

    getContents(): void {
        if (this.getContents$) {
            this.getContents$.unsubscribe();
            this.loadingService.stopLoading('library', 'getContents');
        }
        if (this.getContentsCount$) {
            this.getContentsCount$.unsubscribe();
            this.loadingService.stopLoading('library', 'getContentsCount');
        }
        if (
            this.isAnyFilterSet() ||
            this.isCatalogView ||
            this.isFavoritesView ||
            this.isArchiveView ||
            this.isReportView ||
            this.isDisabledView ||
            this.isPreviousVersionView
        ) {
            this.currentPageContent++;

            this.filters.limit = this.LIMIT;
            this.filters.offset = this.LIMIT * this.currentPageContent;
            if (this.currentPageContent === 0) {
                this.loadingService.startLoading('library', 'getContents');
            } else {
                this.loadingService.startLoading('libraryNextPage', 'getContents');
            }
            let queryContent: any;
            if (this.isCatalogView) {
                const tmp = { ...this.filters };
                tmp.localStructure = this.filters.catalogLocalStructure;
                queryContent = this.libraryService.getCatalogContents(tmp);
            } else if (this.isArchiveView) {
                this.filters.author = this.filters.archivedAuthor;
                queryContent = this.archiveService.getArchivedContents(this.filters);
            } else if (this.isFavoritesView) {
                queryContent = this.favoriteService.getFavoritesContents(this.filters);
            } else if (this.isReportView) {
                queryContent = this.libraryService.getReportedContents(this.filters);
            } else if (this.isDisabledView) {
                queryContent = this.libraryService.getDisabledContents(this.filters);
            } else if (this.isPreviousVersionView) {
                queryContent = this.libraryService.getPreviousVersionContents(this.filters);
            } else {
                queryContent = this.libraryService.getContents(this.filters);
            }
            this.getContents$ = queryContent.subscribe((data: any) => {
                if (this.fromInit) {
                    this.fromInit = false;
                    if (data.length === 0) {
                        this.isCatalogView = false;
                        this.libraryService.emitSetNationalCatalog();
                        this.refreshLibrary();
                    }
                }
                this.contents = this.contents.concat(data);
                this.loginService.getUser().ed_has_seen_catalog = true;
                if (this.currentPageContent === 0) {
                    this.loadingService.stopLoading('library', 'getContents');
                } else {
                    this.loadingService.stopLoading('libraryNextPage', 'getContents');
                }
            });
            this.subscriptions.add(this.getContents$);
            if (this.currentPageContent === 0) {
                this.loadingService.startLoading('library', 'getContentsCount');
                let queryContentCount: any;
                if (this.isCatalogView) {
                    queryContentCount = this.libraryService.getCatalogContentsCount(this.filters);
                } else if (this.isArchiveView) {
                    queryContentCount = this.archiveService.getArchivedContentsCount(this.filters);
                } else if (this.isFavoritesView) {
                    queryContentCount = this.favoriteService.getFavoritesContentsCount(
                        this.filters
                    );
                } else if (this.isReportView) {
                    queryContentCount = this.libraryService.getReportedContentsCount(this.filters);
                } else if (this.isDisabledView) {
                    queryContentCount = this.libraryService.getDisabledContentsCount(this.filters);
                } else if (this.isPreviousVersionView) {
                    queryContentCount = this.libraryService.getPreviousVersionContentsCount(
                        this.filters
                    );
                } else {
                    queryContentCount = this.libraryService.getContentsCount(this.filters);
                }
                this.getContentsCount$ = queryContentCount.subscribe((data: any) => {
                    this.counts = data;
                    this.loadingService.stopLoading('library', 'getContentsCount');
                });
                this.subscriptions.add(this.getContentsCount$);
            }
        } else {
            this.contents = [];
            this.counts = {
                parcours: 0,
                bloc: 0,
                competence: 0,
                module: 0,
                sequence: 0,
                activity: 0
            };
        }
    }

    downloadReportedContents() {
        this.libraryService.getReportedContentsCSV().subscribe((response: HttpResponse<Blob>) => {
            const blob = new Blob([response.body], {
                type: 'text/plain;charset=iso-8859-1;'
            });
            FileSaver.saveAs(blob, 'easi-signalements.csv');
        });
    }

    downloadCatalogContents() {
        FileSaver.saveAs(
            'https://cdn.easi-training.fr/assets/pdf/catalogue_national.pdf',
            'catalogue_national.pdf'
        );
    }

    searchInLibrary($event) {
        this.filters.search = $event;
    }

    /**
     * Utilise le panelService pour informer MoreActionsDirective d'un evt de scroll
     * la directive infiniteScroll semble bloquer l'utilisation native de cdkScrollable, et le scrollDispatcher n'est jamais informé alors.
     */
    onScroll($event): void {
        this.panelService.libraryScrolled();
    }

    /****************PARENTS OF : GETTERS****************/

    getParentOf($event): void {
        this.contentParents = [];
        this.isParentOfView = true;
        this.loadingService.startLoading('library', 'getParentOf');
        this.parentOfContent = $event;
        this.currentPageParent = 0;
        this.subscriptions.add(
            this.libraryService
                .getParentOf(this.parentOfContent.id, this.currentPageParent * 30, this.LIMIT)
                .subscribe((data: any) => {
                    this.contentParents = data;
                    this.loadingService.stopLoading('library', 'getParentOf');
                })
        );
    }

    nextParentOf(): void {
        this.currentPageParent++;

        this.loadingService.startLoading('libraryNextPage', 'getParentOf');

        this.libraryService
            .getParentOf(this.parentOfContent.id, this.currentPageParent * 30, this.LIMIT)
            .subscribe((data: any) => {
                this.contentParents = this.contentParents.concat(data);
                this.loadingService.stopLoading('libraryNextPage', 'getParentOf');
            });
    }

    getParentOfIconStyle(): string {
        return this.libraryService.getIcon(this.parentOfContent);
    }

    getParentOfContentTitle(): string {
        return this.parentOfContent.title;
    }

    getParentOfContentType(): string {
        if (this.parentOfContent.type) {
            return `(${this.parentOfContent.type})`;
        }
    }

    exitParentOfView(): void {
        this.isParentOfView = false;
    }

    /****************MULTISELECT GETTERS****************/

    isSelectionEmpty(): boolean {
        return this.multiselectService.getSelectedListLength() <= 0;
    }

    /**
     * retourne true si tous les élts de la sélection sont désactivés
     */
    isSelectionDisabled(): boolean {
        return this.multiselectService.isSelectionDisabled();
    }

    /**
     * retourne true si l'ensemble des éléments de la sélection sont activés
     */
    isAllSelectionEnabled(): boolean {
        return this.multiselectService.isSelectionEnabled();
    }

    /****************MULTISELECT ACTIONS****************/

    enableSelection(): void {
        this.subscriptions.add(
            this.multiselectService.enableSelection().subscribe(() => {
                this.multiselectService.getSelectedList().map((content: Content) => {
                    this.libraryService.emitContentEnabled(content);
                });
                this.flashMessageService.flash('Les contenus ont été activés');
            })
        );
    }

    disableSelection(): void {
        this.subscriptions.add(
            this.multiselectService.disableSelection().subscribe(() => {
                this.multiselectService.getSelectedList().map((content: Content) => {
                    this.libraryService.emitContentDisabled(content);
                });
                this.flashMessageService.flash('Les contenus ont été désactivés');
            })
        );
    }

    archiveSelection(): void {
        this.subscriptions.add(
            this.multiselectService.archiveSelection(true).subscribe(() => {
                this.multiselectService.getSelectedList().map((content: Content) => {
                    this.libraryService.emitContentArchived(content);
                });
                this.flashMessageService.flash('Les contenus ont été archivés');
                this.multiselectService.resetSelection();
            })
        );
    }

    unarchiveSelection(): void {
        this.subscriptions.add(
            this.multiselectService.archiveSelection(false).subscribe(() => {
                this.multiselectService.getSelectedList().map((content: Content) => {
                    this.libraryService.emitContentUnarchived(content);
                });
                this.flashMessageService.flash('Les contenus ont été désarchivés');
                this.multiselectService.resetSelection();
            })
        );
    }

    deleteSelection(): void {
        this.subscriptions.add(
            this.multiselectService.deleteSelection().subscribe(() => {
                this.multiselectService.getSelectedList().map((content: Content) => {
                    this.libraryService.emitContentDeleted(content);
                });
                this.flashMessageService.flash('Les contenus ont été supprimés');
                this.multiselectService.resetSelection();
            })
        );
    }

    assignSelection(): void {
        this.multiselectService.assignSelection();
    }

    editSelection(): void {
        this.subscriptions.add(
            this.multiselectService.editSelection().subscribe((data: Array<Content>) => {
                data.map((content: Content) => {
                    this.libraryService.emitAddedDraft(content);
                });
                this.flashMessageService.flash(
                    "Les contenus ont bien été copiés dans l'espace de création"
                );
            })
        );
    }
}
