// Internal dependencies
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from '@/modules/shared.module';
// Components

// Services

// Interfaces

// Pipes

// Interface
interface AdminTab {
    key: string;
    label: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.scss'],
    imports: [CommonModule, RouterModule, SharedModule]
})
export class AdministrationComponent implements OnInit {
    adminTabs: Array<AdminTab>;

    constructor(private router: Router) {}

    ngOnInit() {
        this.adminTabs = [
            {
                key: 'domains',
                label: 'Domaines'
            },
            {
                key: 'providers',
                label: 'Fournisseurs'
            }
        ];
        if (this.router.url === '/admin') {
            this.router.navigate(['/admin/domains']);
        }
    }
}
