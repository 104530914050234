// Internal dependencies
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components

// Services
import { AdminService } from '@/services/admin.service';
import { LoadingService } from '@/services/loading.service';
import { FlashMessageService } from '@/services/flash-message.service';

// Interfaces

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-create-provider',
    templateUrl: './create-provider.component.html',
    styleUrls: ['./create-provider.component.scss'],
    imports: [
        SharedModule,
        MatFormFieldModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule
    ]
})
export class CreateProviderComponent implements OnInit {
    provider: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<CreateProviderComponent>,
        private adminService: AdminService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        if (this.data) {
            this.provider = this.data.name;
        }
    }

    // #region Getters

    isLoading() {
        return this.loadingService.isLoading('CreateProviderComponent', 'createProvider');
    }

    // #endregion Getters

    // #region Handlers

    handleClickProvider() {
        this.loadingService.startLoading('CreateProviderComponent', 'createProvider');
        this.adminService.createProvider(this.provider).subscribe(
            () => {
                this.loadingService.stopLoading('CreateProviderComponent', 'createProvider');
                this.closeDialog(this.provider);
            },
            () => {
                this.loadingService.stopLoading('CreateProviderComponent', 'createProvider');
            }
        );
    }

    handleCloseDialog() {
        this.closeDialog();
    }

    // #endregion Handlers

    // #region Internals

    private closeDialog(success?: string) {
        this.dialogRef.close(success);
    }

    // #endregion Internals
}
