<mat-expansion-panel
    class="draft-container"
    *ngIf="draft"
    [expanded]="opened || openedBySearch"
    [hideToggle]="true"
    (click)="toggleIcon($event)"
    [ngClass]="{ 'not-assignable': !canDropContent(), hidden: !displayElement(draft) }"
    dndDropzone
    (dndDrop)="dropContent(undefined)"
    dndDragover
>
    <mat-expansion-panel-header
        class="header"
        [collapsedHeight]="'50px'"
        [expandedHeight]="'50px'"
        [ngClass]="{ 'match-search': matchSearch(draft) }"
        [dndDraggable]="draft"
        [dndDisableIf]="parent && parent.status === 'approved'"
        (dndStart)="startDrag()"
        (dndEnd)="endDrag()"
    >
        <div class="dragover-message" *ngIf="getForbiddenDropTooltipMessage()">
            <span class="icon-Warning"></span>{{ getForbiddenDropTooltipMessage() }}
        </div>
        <div class="order-container" *ngIf="editOrderChildren">
            <div class="icon-down up" (click)="increaseOrder($event)"></div>
            <div class="order-number" *ngIf="draft.orderNumber">{{ draft.orderNumber }}</div>
            <div class="icon-down down" (click)="decreaseOrder($event)"></div>
        </div>
        <div class="order-container" *ngIf="!editOrderChildren">
            <div class="order-number with-margin" *ngIf="draft.orderNumber">
                {{ draft.orderNumber }}
            </div>
        </div>
        <div class="icon" [ngClass]="getIcon()"></div>
        <div
            class="title"
            [tooltip]="getDraftTooltip()"
            tooltipPosition="below-right"
            [ngClass]="{ locked: isLocked() }"
        >
            <div class="name">{{ draft.title }}</div>
            <div class="type" *ngIf="draft.type">({{ getTypeLabel() }})</div>
        </div>
        <div class="icons">
            <div class="hover-icons">
                <div
                    class="icon-Fiche"
                    (click)="viewFiche($event)"
                    [tooltip]="getFicheTooltip()"
                ></div>
                <div
                    class="icon-guide"
                    *ngIf="draft.guide"
                    (click)="viewGuide($event)"
                    [tooltip]="'Voir le guide'"
                ></div>

                <div
                    class="icon-OrdoOn"
                    *ngIf="showOrdoOnIcon()"
                    (click)="toggleOrdoOn($event)"
                    [tooltip]="getOrdoTooltip()"
                ></div>
                <div
                    class="icon-OrdoOff"
                    *ngIf="showOrdoOffIcon()"
                    (click)="toggleOrdoOff($event)"
                    [tooltip]="'Désordonner le contenu'"
                ></div>
                <div
                    class="icon-Cloner"
                    *ngIf="!isLocked()"
                    (click)="cloneAssembly($event)"
                    [tooltip]="'Dupliquer le brouillon'"
                ></div>
                <div
                    class="icon-bin"
                    *ngIf="!isLocked()"
                    (click)="deleteAssembly($event)"
                    [tooltip]="'Supprimer d\'easi training'"
                ></div>
                <div
                    class="icon-Publier"
                    *ngIf="!isLocked()"
                    (click)="publishAssembly($event)"
                    [tooltip]="'Publier'"
                ></div>
                <div
                    class="icon-plus-cercle"
                    *ngIf="canAddChild()"
                    (click)="addChild($event)"
                    [tooltip]="'Créer un assemblage enfant'"
                ></div>

                <div
                    class="icon-close"
                    *ngIf="canRemoveChild()"
                    (click)="removeChild($event)"
                    [tooltip]="getRemoveChildIcon()"
                ></div>

                <div
                    class="icon-Favoris"
                    *ngIf="!draft.favorite"
                    (click)="addToFavorites($event)"
                    [tooltip]="'Ajouter aux favoris'"
                ></div>
                <div
                    class="icon-Retirerdesfavoris"
                    *ngIf="draft.favorite"
                    (click)="removeFromFavorites($event)"
                    [tooltip]="'Retirer des favoris'"
                ></div>
                <div
                    class="icon-voir"
                    *ngIf="isActivity()"
                    (click)="openActivity($event)"
                    [tooltip]="'Voir l\'activité'"
                ></div>
            </div>
            <div class="default-icons">
                <div class="icon-OrdoOn" *ngIf="draft.ordered" [tooltip]="'Contenu ordonné'"></div>
                <div class="icon-Favoris" *ngIf="draft.favorite" [tooltip]="'Contenu favori'"></div>
                <div
                    class="icon-cadenas"
                    *ngIf="isLocked()"
                    [tooltip]="
                        'Contenu publié, issu de la bibliothèque, non éditable ici.<br>Pour transformer ce contenu, passez-le en modification'
                    "
                ></div>
                <div class="icon-down" *ngIf="!isActivity()" [ngClass]="{ open: opened }"></div>
                <div class="blank" *ngIf="isActivity()"></div>
            </div>
        </div>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent *ngIf="draft.level && draft.level !== 'activity'">
        <ng-container *ngFor="let child of getSortedChildren(); index as i">
            <div
                class="separator-container"
                *ngIf="isFirstElement(i) && displayElement(child)"
                dndDropzone
                [dndDisableIf]="!canDropContent()"
                (dndDrop)="dropContent(0)"
            >
                <div class="separator"></div>
            </div>
            <app-assembly-creation
                class="expansion-panel-child"
                [draft]="child"
                [parent]="draft"
                [index]="i"
                [editOrderChildren]="editOrder"
                [search]="search"
                [locked]="locked"
                (draftChange)="updateChild($event)"
            ></app-assembly-creation>
            <div
                class="separator-container"
                *ngIf="displayElement(child)"
                dndDropzone
                [dndDisableIf]="!canDropContent()"
                (dndDrop)="dropContent(i + 1)"
            >
                <div class="separator"></div>
            </div>
        </ng-container>
        <div class="validate-order-container" *ngIf="editOrder && !isLocked()">
            <div class="confirm button" (click)="confirmOrdo($event)">Valider</div>
            <div class="cancel button" (click)="cancelOrdo($event)">Annuler</div>
        </div>
    </ng-template>
</mat-expansion-panel>
