// Internal dependencies
import { Component, input, model } from '@angular/core';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { ConfigService } from '@/services/config.service';
import { LoginService } from '@/services/login.service';

// Interfaces
import { Content, ContentSelection } from '@/structures/content-selection';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-left-content',
    templateUrl: './left-content.component.html',
    styleUrls: ['./left-content.component.scss'],
    imports: [SharedModule]
})
export class LeftContentComponent {
    constructor(
        private loginService: LoginService,
        private configService: ConfigService
    ) {}

    activeTab = input<string>();
    activeKey = model<string>();
    contentsList = input<ContentSelection>();

    // #region Getters

    isSelectedTab(tab: string): boolean {
        return this.activeTab() === tab;
    }

    isSelectedKey(key: string): boolean {
        return this.activeKey() === key;
    }

    getContentIcon(content: Content) {
        return `${this.configService.getConfig().endpoint.cdn}/assets/img/content-selection/icon/${content.icon}`;
    }

    displayCreationSection(key: string) {
        if (key === 'admin') {
            return (
                this.loginService.getUser().roles.localAdmin ||
                this.loginService.getUser().roles.nationalAdmin
            );
        }
        if (key === 'national_admin') {
            return this.loginService.getUser().roles.nationalAdmin;
        }
        if (key === 'national_teacher') {
            return this.loginService.getUser().roles.nationalTeacher;
        }
        return true;
    }

    // #endregion Getters

    // #region Handlers

    handleClickSelectKey(key: string): void {
        this.activeKey.set(key);
    }

    // #endregion Handlers
}
