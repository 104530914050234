// Internal dependencies
import { Component, OnInit } from '@angular/core';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedModule } from 'src/app/modules/shared.module';

// Components
import { CreationSpaceEntryComponent } from './creation-space-entry/creation-space-entry.component';
import { ValidationSpaceEntryComponent } from './validation-space-entry/validation-space-entry.component';

// Services
import { ConfigService } from '@/services/config.service';
import { LibraryService } from '@/services/library.service';
import { LoginService } from '@/services/login.service';
import { LoadingService } from '@/services/loading.service';
import { DialogService } from '@/services/dialog.service';

// Interfaces
import { Content } from '@/structures/content';
import { PartialContent } from '@/structures/content-selection';

// Pipes
import { CreationSpaceSearchPipe } from '@/pipes/creation-space-search.pipe';

// Interface
interface Drafts {
    activities: Array<Content>;
    assemblies: Array<Content>;
    update: Array<Content>;
    sentToValidation: Array<Content>;
    waitingForApproval: Array<Content>;
    waitingForAdminApproval: Array<Content>;
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-creation-space',
    templateUrl: './creation-space.component.html',
    styleUrls: ['./creation-space.component.scss'],
    imports: [
        SharedModule,
        MatExpansionModule,
        CreationSpaceEntryComponent,
        ValidationSpaceEntryComponent
    ]
})
export class CreationSpaceComponent implements OnInit {
    drafts: Drafts;
    search: string;

    constructor(
        private configService: ConfigService,
        private loginService: LoginService,
        private libraryService: LibraryService,
        private dialogService: DialogService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.loadingService.startLoading('creationSpace', 'getDrafts');
        this.libraryService.getDrafts().subscribe(
            (data: Drafts) => {
                this.drafts = data;
                this.loadingService.stopLoading('creationSpace', 'getDrafts');
                if (this.showSentToValidation()) {
                    this.loadingService.startLoading('creationSpace', 'getSentToValidation');
                    this.libraryService.getSentToValidation().subscribe(
                        (data: Array<Content>) => {
                            this.drafts.sentToValidation = data;
                            this.loadingService.stopLoading('creationSpace', 'getSentToValidation');
                        },
                        () => {
                            this.loadingService.stopLoading('creationSpace', 'getSentToValidation');
                        }
                    );
                }
                if (this.showWaitingApproval()) {
                    this.loadingService.startLoading('creationSpace', 'getWaitingForApproval');
                    this.libraryService.getWaitingForApproval().subscribe(
                        (data: Array<Content>) => {
                            this.drafts.waitingForApproval = data.filter(
                                (content: Content) => content.waitingForMyApproval
                            );
                            this.drafts.waitingForAdminApproval = data.filter(
                                (content: Content) => !content.waitingForMyApproval
                            );
                            this.loadingService.stopLoading(
                                'creationSpace',
                                'getWaitingForApproval'
                            );
                        },
                        () => {
                            this.loadingService.stopLoading(
                                'creationSpace',
                                'getWaitingForApproval'
                            );
                        }
                    );
                }
            },
            () => {
                this.loadingService.stopLoading('creationSpace', 'getDrafts');
            }
        );
        this.libraryService.addedDraft.subscribe((content: Content) => {
            if (content.desiredPublicationMode === 'new' && !content.level) {
                this.addDraft({ draft: content, type: 'activities' });
            } else if (content.desiredPublicationMode === 'new' && content.level) {
                this.addDraft({ draft: content, type: 'assemblies' });
            } else if (content.desiredPublicationMode === 'update') {
                this.addDraft({ draft: content, type: 'update' });
            }
        });
    }

    // #region Getters

    showWaitingApproval() {
        return (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().additionalRoles.validator
        );
    }

    showSentToValidation() {
        return (
            this.loginService.getUser().roles.internalTeacher ||
            this.loginService.getUser().roles.externalTeacher ||
            this.loginService.getUser().roles.siteTeacher
        );
    }

    getContents(type: string): Array<Content> {
        return new CreationSpaceSearchPipe().transform(this.drafts[type], this.search);
    }

    isLoading() {
        return this.loadingService.isLoading('creationSpace');
    }

    // #endregion Getters

    // #region Handlers

    cancelSearch() {
        this.search = '';
    }

    moveContentToDraft(data: { draft: Content; type: string }) {
        const target = data.draft.desiredPublicationMode === 'new' ? 'activities' : 'update';
        this.drafts[target].push(
            this.drafts[data.type].splice(
                this.drafts[data.type].findIndex(
                    (content: Content) => content.id === data.draft.id
                ),
                1
            )[0]
        );
    }

    addDraft(data: { draft: Content; type: string }) {
        this.drafts[data.type].push(data.draft);
    }

    updateDraft(data: { draft: Content; type: string }) {
        const index = this.drafts[data.type].findIndex(
            (draft: Content) => draft.id === data.draft.id
        );
        if (index !== -1) {
            this.drafts.activities[index] = { ...data.draft };
        }
    }

    deleteDraft(data: { draft: Content; type: string }) {
        const index = this.drafts[data.type].findIndex(
            (draft: Content) => draft.id === data.draft.id
        );
        if (index !== -1) {
            this.drafts[data.type].splice(index, 1);
        }
    }

    moveDraftToSentToValidation(data: { draft: Content; type: string }) {
        this.deleteDraft(data);
        this.addDraft({ draft: data.draft, type: 'sentToValidation' });
    }

    removeChildFromParent(data: { parent: Content; type: string }) {
        const index = this.drafts[data.type].findIndex(
            (draft: Content) => draft.id === data.parent.id
        );

        this.drafts[data.type][index] = { ...data.parent };
    }

    cloneUpdate(data: Content) {
        if (data.level) {
            this.addDraft({ draft: data, type: 'assembly' });
        } else {
            this.addDraft({ draft: data, type: 'activity' });
        }
    }

    emitFoldAll() {
        this.cancelSearch();
        this.libraryService.emitFoldAll();
    }

    openContentSelection() {
        this.dialogService
            .openContentSelection()
            .subscribe((data: { key: string; data?: PartialContent }) => {
                if (data.key) {
                    let defaultContent: Content = {
                        status: 'draft',
                        localStructure: this.loginService.getUser().localStructure
                            ? this.loginService.getUser().localStructure
                            : '',
                        sites: [],
                        enabled: true,
                        hasprice: 0,
                        price: 0,
                        pricetxt: '',
                        hidden: false,
                        forceStatus: true,
                        is_training_of_teachers: false,
                        visible_for_all: true,
                        copyright: this.loginService.getUser().localStructure
                            ? this.loginService.getUser().localStructure
                            : 'UIAF',
                        ucode: '',
                        author: this.loginService.getUser().id,
                        title: '',
                        description: '',
                        duration: '',
                        provider: '',
                        taxonomy: '',
                        domains: [],
                        keywords: [],
                        tableOfContents: false,
                        hasCertificate: false
                    };

                    if (
                        data.key === 'import-en-masse-activite' ||
                        data.key === 'import-en-masse-assemblage'
                    ) {
                        this.dialogService.openMassImport(data.key);
                    } else if (data.key === 'mass-content-update') {
                        this.dialogService.openMassUpdate();
                    } else if (data.data.h5p_type || data.data.type === 'quiz') {
                        defaultContent = { ...defaultContent, ...data.data };
                        this.dialogService
                            .openCreateH5P(defaultContent)
                            .subscribe((content: Content) => {
                                if (content) {
                                    this.dialogService.openContentCreation(content);
                                }
                            });
                    } else {
                        defaultContent = { ...defaultContent, ...data.data };
                        this.dialogService.openContentCreation(defaultContent);
                    }
                }
            });
    }

    // #endregion Handlers
}
