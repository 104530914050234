<div class="header">
    <div class="title">
        @if (data.anonymous) {
            Révéler l'identité des apprenants et afficher les notes
        } @else {
            Afficher les notes
        }
    </div>
</div>
<mat-dialog-content class="content">
    @if (currentStep === 1) {
        Vous allez révéler l’identité des apprenants.<br />
        Vous ne pourrez plus changer les notes, ni proposer de nouvelles tentatives.<br />
        Aucun devoir ne pourra être rendu suite à cette action.<br />
        Les notes, les devoirs corrigés et le corrigé général seront affichés aux apprenants.<br />
        Les notes seront affichées dans la vue formateur et dans le tracking.<br /><br />
        Etes-vous sûr de vouloir révéler l’identité des apprenants ?
    }
    @if (currentStep === 2) {
        Certains apprenants n’ont pas rendu de devoir ou n’ont pas de note.<br />
        Que voulez-vous faire ?<br /><br />
        Attention, ce choix est définitif et ne pourra pas être modifié à postériori
    }
</mat-dialog-content>
<div class="footer">
    @if (currentStep === 1) {
        <div class="confirm button" (click)="handleClickEndFirstStep()">OUI</div>
        <div class="cancel button" (click)="handleCloseDialog(false)">Annuler</div>
    }
    @if (currentStep === 2) {
        <div class="button zero" (click)="handleCloseDialog('zero')">Noter à 0</div>
        <div class="button no-note" (click)="handleCloseDialog('noNote')">Ne pas noter</div>
        <div class="cancel full-width button" (click)="handleCloseDialog(false)">Annuler</div>
    }
</div>
