import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { User } from '../structures/user';
import { Assignment } from '../structures/assignment';
import { log } from './decorators/log.decorator';
import { Content } from '../structures/content';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient) {}

    ws = 'old';

    setWS(value: string) {
        this.ws = value;
    }

    @log() getUser(userId: number): Observable<User> {
        return this.http.get<User>(`/users/${userId}`);
    }

    /**
     * @deprecated
     */
    @log() getUsersDeprecated(filters: any): Observable<User[]> {
        const params: any = {
            offset: filters.offset ? filters.offset : 0,
            limit: filters.limit ? filters.limit : 30,
            ...filters
        };

        return this.http.get<User[]>(`/users`, { params });
    }

    /**
     * récupère une liste d'utilisateurs en passant par shared
     * @param filters
     */
    getUsers(filters: any): Observable<User[]> {
        const users$: Subject<User[]> = new Subject();
        const params: any = {
            events: true,
            from: 'training',
            offset: filters.offset ? filters.offset : 0,
            limit: filters.limit ? filters.limit : 30,
            enabled: true,
            ...filters
        };
        const timeStamp = Date.now();

        (<HTMLIFrameElement>document.getElementById('header-container')).contentWindow.postMessage(
            { getUsers: true, params, timeStamp },
            '*'
        );
        window.addEventListener('message', (event: MessageEvent) => {
            if (event.data.timeStamp === timeStamp) {
                users$.next(event.data.users);
            }
        });
        return users$.asObservable();
    }

    getUsersLight(filters: any): Observable<User[]> {
        const users$: Subject<User[]> = new Subject();
        const params: any = {
            events: true,
            from: 'training',
            offset: filters.offset ? filters.offset : 0,
            enabled: true,
            ...filters
        };
        const timeStamp = Date.now();

        (<HTMLIFrameElement>document.getElementById('header-container')).contentWindow.postMessage(
            { getUsersLight: true, params, timeStamp },
            '*'
        );
        window.addEventListener('message', (event: MessageEvent) => {
            if (event.data.timeStamp === timeStamp) {
                users$.next(event.data.users);
            }
        });
        return users$.asObservable();
    }

    @log() getUsersCount(filters: any): Observable<{ count: number }> {
        const users$: Subject<any> = new Subject();
        const params: any = {
            count: true,
            events: true,
            from: 'training',
            enabled: true,
            ...filters
        };
        const timeStamp = Date.now() + 10;

        (<HTMLIFrameElement>document.getElementById('header-container')).contentWindow.postMessage(
            { getUsers: true, params, timeStamp },
            '*'
        );
        window.addEventListener('message', (event: MessageEvent) => {
            if (event.data.timeStamp === timeStamp) {
                users$.next(event.data.users);
            }
        });
        return users$.asObservable();
    }

    @log() getUsersCompletion(filters: any): Observable<any> {
        const params = {
            id: filters.join('|')
        };

        return this.http.get<any>(`/users/completion`, { params });
    }

    @log() getUserAssignments(userId: any, filters?: any): Observable<any> {
        const params: any = {};
        if (filters) {
            params.referent = filters.referents
                .filter((referent) => referent.selected)
                .map((referent) => referent.key)
                .join('|');
            params.domain = filters.domains
                .filter((domain) => domain.selected)
                .map((domain) => domain.key)
                .join('|');
            params.tracking = filters.status
                .filter((tracking) => tracking.selected)
                .map((tracking) => tracking.key)
                .join('|');
            params.origin = filters.origin
                .filter((origin) => origin.selected)
                .map((origin) => origin.key)
                .join('|');
            params.level = filters.categories
                .filter(
                    (level) =>
                        level.selected &&
                        [
                            'bloc',
                            'parcours',
                            'competence',
                            'module',
                            'sequence',
                            'activity'
                        ].indexOf(level.key) > -1
                )
                .map((level) => level.key)
                .join('|');
            params.category = filters.categories
                .filter(
                    (level) =>
                        level.selected &&
                        [
                            'bloc',
                            'parcours',
                            'competence',
                            'module',
                            'sequence',
                            'activity'
                        ].indexOf(level.key) === -1
                )
                .map((level) => level.key)
                .join('|');
            if (filters.search) {
                params.search = filters.search;
            }
            if (filters.withLog) {
                params.log = true;
            }
        }

        return this.http.get<{ referents: User[]; tree: Assignment[] }>(
            `/users/${userId}/assignments`,
            { params }
        );
    }

    @log() deleteUserAssignments(assignmentId: number): Observable<boolean> {
        return this.http.delete<boolean>(`/assignments/${assignmentId}?individual`);
    }

    @log() hideUserAssignment(assignmentId: number) {
        const params: any = { mask: true };

        return this.http.post(`/assignments/${assignmentId}`, {}, { params });
    }

    @log() showUserAssignment(assignmentId: number) {
        const params: any = { mask: false };

        return this.http.post(`/assignments/${assignmentId}`, {}, { params });
    }

    @log() markAsReferent(assignmentId: number) {
        return this.http.post(`/assignments/${assignmentId}/user/become_referent`, {});
    }

    @log() unmarkAsReferent(assignmentId: number) {
        return this.http.post(`/assignments/${assignmentId}/user/unbecome_referent`, '');
    }

    @log() setDisponibilityToUserAssignment(
        assignmentId: number,
        timestart: string,
        timeend: string,
        lock_after_enddate: boolean = false,
        enddate_reminders: boolean = false
    ) {
        const body: any = {
            timestart,
            timeend,
            lock_after_enddate,
            enddate_reminders
        };

        if (!timeend) {
            body.timeend = '';
        }

        return this.http.post(`/assignments/${assignmentId}/user/set_period`, body);
    }

    @log() validateContentForUser(userId: number, contentId: number) {
        return this.http.post(`/users/${userId}/assignments/${contentId}/tracking`, {
            status: undefined,
            forcedStatus: 'completed'
        });
    }

    @log() unvalidateContentForUser(userId: number, contentId: number) {
        return this.http.post(`/users/${userId}/assignments/${contentId}/tracking`, {
            status: undefined,
            forcedStatus: 'cancel'
        });
    }

    @log() removeTracking(userId: number, content: Content) {
        if (content.type === 'n4s') {
            return this.http.get(`/n4s/getnewattempt/${content.id}/${userId}`);
        } else {
            return this.http.post(`/users/${userId}/assignments/${content.id}/tracking`, {
                status: undefined,
                forcedStatus: 'not attempted'
            });
        }
    }

    @log() noteContent(userId: number, contentId: number, score: number, passed: boolean) {
        const body = {
            status: passed ? 'passed' : 'failed',
            score
        };

        return this.http.post(`/users/${userId}/assignments/${contentId}/tracking`, body);
    }

    /**
     * Assigne une note définitive (i.e. NON modifiable) à un étudiant pour un devoir
     * @param userId
     * @param contentId
     * @param score / 100
     */
    @log() noteDevoir(
        userId: number | string,
        contentId: number,
        score: number
    ): Observable<boolean> {
        return this.http.post<boolean>(`/users/${userId}/assignments/${contentId}/tracking`, {
            score
        });
    }

    /**
     * Assigne une note temporaire (i.e. modifiable) à un étudiant pour un devoir
     * @param userId
     * @param contentId
     * @param score /100
     */
    @log() noteDevoirTemporary(
        userId: number | string,
        contentId: number,
        score: number,
        graded: boolean
    ): Observable<boolean> {
        const body: { temporaryScore?: number; score?: number } = {};
        if (!graded) {
            body.temporaryScore = score;
        } else {
            body.score = score;
        }
        return this.http.post<boolean>(`/users/${userId}/assignments/${contentId}/tracking`, body);
    }

    @log() saveUserDevoirCorrection({
        assignment,
        userId,
        correctionFile
    }: {
        assignment: Assignment;
        userId: number;
        correctionFile: File;
    }): Observable<any> {
        const body = new FormData();
        body.append('correction', correctionFile, correctionFile.name);

        return this.http.post<any>(`/contents/${assignment.id}/submit_correction/${userId}`, body);
    }

    @log() getUserDevoirTracking(assignmentId: number) {
        return this.http.get(`/assignments/${assignmentId}/user/get_assignment_tracking`);
    }
}
