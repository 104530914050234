import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule,
    RouteReuseStrategy,
    ActivatedRouteSnapshot,
    DetachedRouteHandle
} from '@angular/router';

import { DefaultGuard } from '../guards/default.guard';

import { TeacherComponent } from '../components/teacher/teacher.component';
import { TutorComponent } from '../components/tutor/tutor.component';

import { StudentPanelComponent } from '../components/teacher/student-panel/student-panel.component';
import { CreationSpaceComponent } from '../components/teacher/creation-space/creation-space.component';
import { ValidationSpaceComponent } from '../components/teacher/validation-space/validation-space.component';
import { UserAssignmentsComponent } from '../components/teacher/user-assignments/user-assignments.component';
import { GroupAssignmentsComponent } from '../components/teacher/group-assignments/group-assignments.component';

import { VideoPlayerComponent } from '../components/video-player/video-player.component';
import { H5PPlayerComponent } from '../components/h5p-player/h5p-player.component';

import { AdministrationComponent } from '../components/administration/administration.component';
import { TeacherGuard } from '../guards/teacher.guard';
import { LibraryComponent } from '../components/teacher/library/library.component';

const routes: Routes = [
    {
        path: 'teacher',
        component: TeacherComponent,
        canActivate: [TeacherGuard, DefaultGuard],
        canActivateChild: [TeacherGuard],
        children: [
            {
                path: 'panel',
                component: StudentPanelComponent
            },
            {
                path: 'creation',
                component: CreationSpaceComponent
            },
            {
                path: 'validation',
                component: ValidationSpaceComponent
            },
            {
                path: 'student/:userId',
                component: UserAssignmentsComponent
            },
            {
                path: 'group/:groupId',
                component: GroupAssignmentsComponent
            }
        ]
    },
    {
        path: 'tutor',
        component: TutorComponent,
        canActivate: [DefaultGuard],
        children: [
            {
                path: 'panel',
                component: StudentPanelComponent
            },
            {
                path: 'student/:userId',
                component: UserAssignmentsComponent
            },
            {
                path: 'group/:groupId',
                component: GroupAssignmentsComponent
            }
        ]
    },
    {
        path: 'videoPlayer/:activityId',
        component: VideoPlayerComponent,
        canActivate: [DefaultGuard]
    },
    {
        path: 'h5pPlayer/:activityId',
        component: H5PPlayerComponent,
        canActivate: [DefaultGuard]
    },
    {
        path: 'admin',
        component: AdministrationComponent,
        canActivate: [DefaultGuard]
    },
    {
        path: 'publishMedia',
        canActivate: [DefaultGuard],
        component: TeacherComponent
    },
    { path: '', redirectTo: '/teacher/panel', pathMatch: 'full' },
    { path: '**', redirectTo: '/teacher/panel', pathMatch: 'full' }
];

export class EasiRouteReuseStrategy implements RouteReuseStrategy {
    private storedRoutes = new Map<string, DetachedRouteHandle>();

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.routeConfig.path === 'panel';
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.storedRoutes.set(route.routeConfig.path, handle);
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!route.routeConfig && !!this.storedRoutes.get(route.routeConfig.path);
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return this.storedRoutes.get(route.routeConfig.path);
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }
}

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: EasiRouteReuseStrategy
        }
    ]
})
export class RoutingModule {}
