@if (showExtraContainer()) {
    <div class="extra-container">
        @if (showFileContainer()) {
            <div class="element">
                <div class="label">{{ content().type === 'devoir' ? 'Énoncé' : 'Fichier' }}</div>
                <div class="legend">Import limité à <b>200 Mo</b></div>
                <div
                    class="value"
                    [ngClass]="{ 'no-file': !hasFile() }"
                    appFileDropzone
                    (fileDropped)="uploadFile($event)"
                >
                    {{ getCurrentFileLabel() }}
                </div>
                <div
                    class="icon-Importer"
                    (click)="openSelectFile()"
                    [tooltip]="getFileTooltip()"
                ></div>
                <input
                    class="hidden"
                    type="file"
                    #fileInput
                    (change)="uploadFile($event.target.files)"
                />
                @if (!content().path && !file()) {
                    <div class="sublegend">
                        Vous pouvez importer n'importe quel fichier, toutefois, nous préconisons les
                        types de contenus suivants : <br />
                        <b>scorm</b> : paquet formaté suivant cette norme de contenus de
                        formation<br />
                        <b>pdf</b> : fichier passif lisible sur quasiment tous les supports, et
                        prévu pour être aisément imprimable<br />
                        <b>office</b> ou <b>open office, articulate</b>
                    </div>
                }
                @if (content().type === 'scorm') {
                    <div class="table-of-contents">
                        <div
                            class="icon"
                            [ngClass]="{
                                'icon-select': !content().tableOfContents,
                                'icon-selected': content().tableOfContents
                            }"
                            (click)="toggleScormMenu()"
                        ></div>
                        <div class="text">Afficher le menu du SCORM aux apprenants</div>
                    </div>
                }
            </div>
        }
        @if (showURLContainer()) {
            <div class="element">
                <div class="label">Adresse du lien web</div>
                <div class="legend">Assurez-vous d'avoir les droits d'utilisation du lien web</div>
                <input
                    type="text"
                    placeholder="Adresse du lien web (250 caractères maximum)"
                    [(ngModel)]="content().url"
                />
            </div>
        }
        @if (showLTIContainer()) {
            <div class="element">
                <div class="label">Adresse de l'activité LTI (URL)</div>
                <input
                    type="text"
                    placeholder="Adresse du lien web (250 caractères maximum)"
                    [(ngModel)]="content().lti_url"
                />
            </div>
            <div class="element">
                <div class="label">Clé de la ressource LTI</div>
                <input
                    type="text"
                    placeholder="Clé de la ressource LTI"
                    [(ngModel)]="content().lti_resourcekey"
                />
            </div>
            <div class="element">
                <div class="label">Clé de l'activité LTI</div>
                <input type="text" placeholder="Clé secrète" [(ngModel)]="content().lti_key" />
            </div>
            <div class="element">
                <div class="label">Paramètres de l'activité LTI</div>
                <textarea
                    type="text"
                    placeholder="Paramètres de l'activité LTI"
                    [(ngModel)]="content().lti_params"
                ></textarea>
            </div>
        }
        @if (showN4SContainer()) {
            <div class="element">
                <div class="label">Code Num4Skills</div>
                <input type="text" placeholder="Code Num4Skills" [(ngModel)]="content().data" />
            </div>
        }
        @if (showH5PContainer()) {
            @if (!content().id && !content().h5p_type) {
                <div class="element">
                    <div class="label">Fichier H5P</div>
                    <div class="value" appFileDropzone (fileDropped)="uploadH5PContent($event)">
                        {{ getCurrentH5PLabel() }}
                    </div>
                    @if (!h5pFile()) {
                        <div
                            class="icon-Importer"
                            (click)="openSelectH5PContent()"
                            [tooltip]="'Ajouter un fichier H5P'"
                        ></div>
                    }
                    @if (h5pFile()) {
                        <div
                            class="icon-bin"
                            (click)="deleteH5PContent()"
                            [tooltip]="'Supprimer le fichier H5P'"
                        ></div>
                    }
                    <input
                        class="hidden"
                        type="file"
                        #h5pContentInput
                        (change)="uploadH5PContent($event.target.files)"
                    />
                </div>
            }
            @if (content().id) {
                <div class="element">
                    <div class="label">Type de contenu formatif</div>
                    <input type="text" [(ngModel)]="content().h5p_type" disabled />
                </div>
            }
        }
        @if (showManuelContainer()) {
            @if (showManuelType()) {
                <div class="element">
                    <div class="label">Type de manuel</div>
                    <lib-uimm-dropdown
                        [(items)]="manuelTypes"
                        (itemsChange)="handleManuelTypeChange()"
                        [label]="'Type'"
                        [labelWidth]="'100%'"
                        [labelDefaultColor]="'#ff7200'"
                        [uniformWidth]="true"
                    ></lib-uimm-dropdown>
                </div>
            }
            @if (content().type === 'hachette') {
                <div class="element">
                    <div class="label">Code ressource</div>
                    <input
                        type="text"
                        placeholder="Code ressource"
                        [(ngModel)]="content().code_ressource"
                    />
                </div>
            }
            @if (content().structureid && content().type === 'hachette') {
                <div class="element">
                    <div class="label">Code établissement UAI</div>
                    <input
                        type="text"
                        placeholder="Code établissement UAI"
                        [(ngModel)]="content().code_uai"
                    />
                </div>
            }
            @if (content().type === 'nathan') {
                <div class="element">
                    <div class="label">URL du manuel Nathan</div>
                    <input
                        type="text"
                        placeholder="URL du manuel Nathan"
                        [(ngModel)]="content().url"
                    />
                </div>
            }
        }
        <div class="separator"></div>
    </div>
}
