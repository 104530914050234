@if (!isLoading('FacultativeFieldComponent')) {
    <mat-expansion-panel class="facultative-container">
        <mat-expansion-panel-header
            class="legend"
            [collapsedHeight]="'30px'"
            [expandedHeight]="'30px'"
            >Champs facultatifs</mat-expansion-panel-header
        >
        <div class="element">
            <div class="label">Guide</div>
            <div class="value" appFileDropzone (fileDropped)="uploadGuide($event)">
                {{ getCurrentGuideLabel() }}
            </div>
            @if (!content().guide && !guide()) {
                <div
                    class="icon-Importer"
                    (click)="openSelectGuide()"
                    [tooltip]="'Ajouter un guide'"
                ></div>
            }
            @if (content().guide || guide()) {
                <div
                    class="icon-bin"
                    (click)="deleteGuide()"
                    [tooltip]="'Supprimer un guide'"
                ></div>
            }
            <input
                class="hidden"
                type="file"
                #guideInput
                (change)="uploadGuide($event.target.files)"
            />
        </div>
        <div class="element">
            <div class="label">Code</div>
            <input
                type="text"
                placeholder="Choisir un code pour ce contenu"
                [(ngModel)]="content().ucode"
            />
        </div>
        <div class="element">
            <div class="label">Taxonomie</div>
            <lib-uimm-dropdown
                [label]="'Taxonomies'"
                [(items)]="taxonomies"
                (itemsChange)="handleTaxonomyChange()"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element">
            <div class="label">Fournisseur</div>
            <lib-uimm-dropdown
                [label]="'Fournisseurs'"
                [(items)]="providers"
                (itemsChange)="handleProvidersChange()"
                [labelWidth]="'100%'"
                [maxHeight]="200"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element">
            <div class="label">Copyright</div>
            <input
                type="text"
                placeholder="Choisir un copyright pour ce contenu"
                [(ngModel)]="content().copyright"
            />
        </div>
        @if (isNationalAdmin()) {
            <div class="element">
                <div class="label">Payant</div>
                <lib-uimm-dropdown
                    [(items)]="hasprice"
                    (itemsChange)="handleHasPriceChange()"
                    [labelWidth]="'100%'"
                    [uniformWidth]="true"
                ></lib-uimm-dropdown>
            </div>
        }
        @if (showPriceInput()) {
            <div class="element">
                <div class="label">Prix *</div>
                @if (showPriceInfo()) {
                    <input type="text" [(ngModel)]="content().pricetxt" />
                }
                @if (showPriceNumber()) {
                    <input type="number" [(ngModel)]="content().price" />
                }
            </div>
        }
        <div class="element">
            <div class="label">Peut être forcé</div>
            <lib-uimm-dropdown
                [(items)]="forceStatus"
                (itemsChange)="handleForceStatusChange()"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        @if (isAdmin()) {
            <div class="element">
                <div class="label">Formation de formateur</div>
                <lib-uimm-dropdown
                    [(items)]="trainingOfTeachers"
                    (itemsChange)="handleTrainingOfTeachersChange()"
                    [labelWidth]="'100%'"
                    [uniformWidth]="true"
                ></lib-uimm-dropdown>
            </div>
        }
        @if (showCertificate() && isAssembly()) {
            <div class="element">
                <div class="label">Avec attestation <sup>*Champ CODE obligatoire</sup></div>
                <lib-uimm-dropdown
                    [(items)]="hasCertificate"
                    (itemsChange)="handleHasCertificateChange()"
                    [disabled]="!content().ucode"
                    [labelWidth]="'100%'"
                    [uniformWidth]="true"
                ></lib-uimm-dropdown>
            </div>
        }
    </mat-expansion-panel>
} @else {
    <app-loading></app-loading>
}

<div class="separator"></div>
