import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription, Subject } from 'rxjs';

import { LibraryService } from '../../services/library.service';
import { LoadingService } from '../../services/loading.service';

import { Content } from '../../structures/content';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-select-publication',
    templateUrl: './select-publication.component.html',
    styleUrls: ['./select-publication.component.scss']
})
export class SelectPublicationComponent implements OnInit {
    subscriptions = new Subscription();

    comment: string;

    constructor(
        public dialogRef: MatDialogRef<SelectPublicationComponent>,
        @Inject(MAT_DIALOG_DATA) public content: Content,
        private libraryService: LibraryService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.comment = '';
    }

    isNewContent() {
        return !this.content.basedOn;
    }

    getDialogTitle() {
        if (this.isNewContent()) {
            return "Publication d'un nouveau contenu";
        } else if (this.content.desiredPublicationMode === 'new') {
            return "Publication d'un nouveau contenu (à partir d'une copie)";
        } else {
            return "Publication d'un contenu modifié";
        }
    }

    getIcon() {
        return this.libraryService.getIcon(this.content);
    }

    getTitle() {
        return this.content.title;
    }

    getPublishNewText() {
        if (this.isNewContent()) {
            return `Le contenu sera accessible en tant que nouveau contenu dans la bibliothèque.`;
        } else {
            return `Le contenu sera accessible en tant que nouveau contenu et n'aura aucun lien avec le contenu dont il est issu.`;
        }
    }

    canPublishNew() {
        switch (this.content.status) {
            case 'draft': {
                return this.content.desiredPublicationMode === 'new';
            }
            case 'waitingForApproval': {
                return this.content.publicationMode === 'new';
            }
            case 'approved': {
                return true;
            }
            default: {
                return false;
            }
        }
    }

    canPublishMinorUpdate() {
        switch (this.content.status) {
            case 'draft': {
                return (
                    this.content.desiredPublicationMode === 'update' &&
                    this.content.type !== 'quiz' &&
                    this.content.type !== 'h5pactivity'
                );
            }
            case 'waitingForApproval': {
                return this.content.publicationMode === 'minor-update';
            }
            default: {
                return false;
            }
        }
    }

    canPublishMajorUpdate() {
        switch (this.content.status) {
            case 'draft': {
                return this.content.desiredPublicationMode === 'update';
            }
            case 'waitingForApproval': {
                return this.content.publicationMode === 'major-update';
            }
            default: {
                return false;
            }
        }
    }

    setPublicationMode(publicationMode: string) {
        this.dialogRef.close({ publicationMode, comment: this.comment });
    }

    isLoading() {
        return this.loadingService.isLoading('selectPublication');
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
