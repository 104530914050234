<div class="header">
    <div [class]="getHeaderIcon()"></div>
    <div class="label">{{ getHeaderTitle() }}</div>
    <button mat-icon-button (click)="handleCloseDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="content">
    @if (!isLoading('TrackingComponent')) {
        <div class="content-container">
            <div class="entry">
                <div [ngClass]="getContentIcon()"></div>
                <div class="entry-title">{{ data.assignment.title }}</div>
                @if (data.assignment.timeend) {
                    <div class="entry-dates">
                        Devoir à rendre du
                        {{ getDevoirDates(data.assignment.timestart) | date: 'dd/MM/yyyy' }} au
                        {{ getDevoirDates(data.assignment.timeend) | date: 'dd/MM/yyyy' }}
                    </div>
                }
            </div>
        </div>
        <div class="separator"></div>
        @if (groups && groups.length) {
            <div class="groups-container">
                <mat-form-field>
                    <mat-label>Groupes</mat-label>
                    <input type="text" matInput [(ngModel)]="search" [matAutocomplete]="auto" />
                    <mat-autocomplete
                        autoActiveFirstOption
                        #auto="matAutocomplete"
                        (optionSelected)="getTracking($event)"
                    >
                        @for (group of groups | groupSearch: search; track group.id) {
                            <mat-option [value]="group.id">{{ group.name }}</mat-option>
                        }
                    </mat-autocomplete>
                </mat-form-field>
                <div>
                    Trop d'utilisateurs dans le groupe sélectionné. Sélectionnez un groupe
                    d'utilisateurs pour affiner la recherche.
                </div>
            </div>
        } @else {
            @if (!isUserAssignment()) {
                <div class="search">
                    <div class="blank"></div>
                    <div class="icon-container">
                        @if (displayIcon('HideAssignments')) {
                            <div
                                class="icon-desassigne"
                                tooltip="Désassigner"
                                (click)="handleClickHideAssignments()"
                            ></div>
                        }
                        @if (displayIcon('ShowAssignments')) {
                            <div
                                class="icon-assigner"
                                tooltip="Réassigner"
                                (click)="handleClickShowAssignments()"
                            ></div>
                        }
                        @if (displayIcon('SendReminder')) {
                            <div
                                class="icon-message"
                                tooltip="Envoyer un rappel au groupe"
                                (click)="handleClickSendReminder()"
                            ></div>
                        }
                        @if (displayIcon('DownloadCSVReport')) {
                            <div
                                class="icon-download"
                                tooltip="Télécharger le rapport au format .CSV"
                                (click)="handleClickDownloadCSVReport()"
                            ></div>
                        }
                        @if (displayIcon('ViewGroupTestsReporting')) {
                            <div
                                class="icon-radar"
                                tooltip="Voir le rapport des tests du groupe"
                                (click)="handleClickViewGroupTestsReporting()"
                            ></div>
                        }
                        @if (displayIcon('download')) {
                            <a [href]="getDownloadAllDevoirsLink()">
                                <div
                                    class="icon-download"
                                    tooltip="Télécharger tous les devoirs"
                                ></div>
                            </a>
                        }
                        @if (displayIcon('UploadDevoirCorrectionGroup')) {
                            <div
                                class="icon-Miseenapplcation"
                                [tooltip]="getTooltipUploadDevoir()"
                                (click)="handleClickUploadDevoirCorrectionGroup()"
                            ></div>
                        }

                        @if (displayIcon('ViewGroupTestReporting')) {
                            <div
                                class="icon-radar"
                                tooltip="Voir le rapport du test"
                                (click)="handleClickViewGroupTestReporting()"
                            ></div>
                        }
                        @if (displayIcon('ResetGroupTracking')) {
                            <div
                                class="icon-rafraichir"
                                tooltip="Proposer une nouvelle tentative au groupe"
                                (click)="handleClickResetGroupTracking()"
                            ></div>
                        }
                    </div>

                    <mat-form-field class="search-container">
                        <mat-label>Recherche</mat-label>
                        <input matInput type="text" [(ngModel)]="search" />
                        @if (search) {
                            <button
                                matSuffix
                                mat-icon-button
                                aria-label="Clear"
                                (click)="search = ''"
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </div>
            }
        }

        <div class="learner-container">
            @for (userTracking of tracking; track userTracking.userid; let i = $index) {
                <app-user-tracking
                    [(userTracking)]="tracking[$index]"
                    [assignment]="data.assignment"
                ></app-user-tracking>
            }
        </div>
    } @else {
        <app-loading></app-loading>
    }
</mat-dialog-content>
@if (showFooter()) {
    <div class="footer">
        @if (isAnyTemporaryScore()) {
            <button
                mat-button
                [disabled]="isRevealButtonDisabled()"
                (click)="handleClickGradeGroup()"
            >
                @if (isAnonymous()) {
                    Révéler l'identité des apprenants
                } @else {
                    @if (isUserAssignment()) {
                        Enregistrer la note
                        <mat-icon
                            tooltip="Cela affichera la note et le devoir corrigé à l'apprenant.<br/> Cela affichera également les notes dans la vue formateur et dans le tracking."
                        >
                            info
                        </mat-icon>
                    } @else {
                        Afficher les notes
                        <mat-icon
                            tooltip="Cela affichera les notes, les devoirs corrigés et le corrigé général aux apprenants.<br/>Cela affichera également les notes dans la vue formateur et dans le tracking."
                        >
                            info
                        </mat-icon>
                    }
                }
            </button>
        }
    </div>
}
