import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

import { PanelService } from '@/services/panel.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-teacher',
    templateUrl: './teacher.component.html',
    styleUrls: ['./teacher.component.scss'],
    standalone: false
})
export class TeacherComponent implements OnInit {
    closedLeft: boolean;
    closedRight: boolean;

    constructor(
        private router: Router,
        private panelService: PanelService
    ) {}

    ngOnInit() {
        this.closedLeft = false;
        this.closedRight = false;

        this.panelService.clickLeftPanel.subscribe(() => {
            if (this.closedRight) {
                this.closedRight = false;
            }
            this.closedLeft = !this.closedLeft;
        });

        this.panelService.clickRightPanel.subscribe(() => {
            if (this.closedLeft) {
                this.closedLeft = false;
            }
            this.closedRight = !this.closedRight;
        });
    }

    isCurrentState(state) {
        return this.router.url.includes(state);
    }
}
