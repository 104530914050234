<div class="content-body-line">
    <div class="content-body-cell default-column">{{ content().ucode }}</div>
    <div class="content-body-cell default-column">
        {{ content().title }}
    </div>
    @if (isActivityImport()) {
        <div class="content-body-cell small-column">
            {{ content().type }}
        </div>
    } @else {
        <div class="content-body-cell small-column">
            {{ content().level }}
        </div>
    }

    <div
        class="content-body-cell small-column"
        [ngClass]="{
            'icon-select': content().created === undefined,
            'icon-selected': content().created === true,
            'icon-Supprimerdugroupe': content().created === false,
            'icon-Warning': content().invalid === true
        }"
    ></div>
    <div
        class="content-body-cell small-column"
        [ngClass]="{
            'icon-select': content().published === undefined,
            'icon-selected': content().published === true,
            'icon-Supprimerdugroupe': content().published === false,
            'icon-Warning': content().invalid === true
        }"
    ></div>
    <div class="content-body-cell large-column">{{ content().comment }}</div>
</div>
