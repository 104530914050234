// Internal dependencies
import { Component, OnInit, OnChanges, SimpleChanges, model } from '@angular/core';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LoadingService } from '@/services/loading.service';
import { LoginService } from '@/services/login.service';
import { LibraryService } from '@/services/library.service';
import { SiteService } from '@/services/site.service';
import { UserService } from '@/services/user.service';

// Interfaces
import { User } from '@/structures/user';
import { Content } from '@/structures/content';
import { Domain } from '@/structures/domain';
import { Structure } from '@/structures/structure';
import { Site } from '@/structures/site';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-mandatory-field',
    templateUrl: './mandatory-field.component.html',
    styleUrls: ['./mandatory-field.component.scss'],
    imports: [SharedModule]
})
export class MandatoryFieldComponent implements OnInit, OnChanges {
    content = model<Content>();

    domains: Array<Domain>;
    structures: Array<Structure>;
    sites: Array<Site>;

    restriction: Array<any>;
    categories: Array<any>;

    author: User;

    constructor(
        private loadingService: LoadingService,
        private loginService: LoginService,
        private libraryService: LibraryService,
        private siteService: SiteService,
        private userService: UserService
    ) {}

    ngOnInit() {
        if (Array.isArray(this.content().keywords)) {
            this.content().keywords = this.content().keywords.join(';');
        }
        if (this.isDisabledCategory(this.content().category)) {
            this.content.set({ ...this.content(), category: '' });
        }
        this.loadingService.startLoading('MandatoryFieldComponent', 'getStructures');
        this.loginService.getStructures().subscribe(
            (data: Array<Structure>) => {
                if (this.isNationalAdmin()) {
                    this.structures = data.map((structure: Structure) => {
                        return {
                            key: structure.id,
                            title: structure.name,
                            icon: 'icon-local',
                            selected: this.content().localStructure === structure.name
                        };
                    });
                } else {
                    this.structures = [
                        {
                            key: this.loginService.getUser().structureid,
                            title: this.loginService.getUser().localStructure,
                            icon: 'icon-local',
                            selected: true
                        }
                    ];
                }
                this.handleStructureChange();
                this.loadingService.stopLoading('MandatoryFieldComponent', 'getStructures');
            },
            () => {
                this.loadingService.stopLoading('MandatoryFieldComponent', 'getStructures');
            }
        );
        this.loadingService.startLoading('MandatoryFieldComponent', 'getDomains');
        this.libraryService.getDomains().subscribe(
            (data: Array<Domain>) => {
                this.domains = data.map((domain: Domain) => {
                    return {
                        key: domain.name,
                        title: domain.name,
                        selected: this.content().domains.indexOf(domain.name) > -1
                    };
                });
                this.loadingService.stopLoading('MandatoryFieldComponent', 'getDomains');
            },
            () => {
                this.loadingService.stopLoading('MandatoryFieldComponent', 'getDomains');
            }
        );
        if (this.content().author) {
            this.userService.getUser(this.content().author).subscribe((author: User) => {
                this.author = author;
            });
        }
        this.restriction = [
            {
                key: 'national',
                icon: 'icon-national',
                title: 'Contenu national',
                selected: !this.content().localStructure && this.isNationalAdmin(),
                disabled: !this.isNationalAdmin()
            },
            {
                key: 'local',
                icon: 'icon-local',
                title: 'Contenu local',
                selected:
                    (this.content().localStructure && this.content().sites.length === 0) ||
                    (!this.isNationalAdmin() &&
                        !(this.content().localStructure && this.content().sites.length !== 0)),
                disabled:
                    this.loginService.getUser().roles.corporationTeacher ||
                    this.loginService.getUser().roles.siteTeacher
            },
            {
                key: 'site',
                icon: 'icon-site',
                title: 'Contenu restreint à un site',
                selected:
                    (this.content().localStructure && this.content().sites.length !== 0) ||
                    this.loginService.getUser().roles.corporationTeacher ||
                    this.loginService.getUser().roles.siteTeacher
            }
        ];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.content &&
            (changes.content.firstChange ||
                changes.content.previousValue.type !== changes.content.currentValue.type)
        ) {
            this.handleTypeChange();
        }
    }

    // #region Getters

    isValidDuration() {
        if (this.content().duration) {
            return (
                this.content().duration.match(/^[0-9]?[0-9]?[0-9]?[0-9]:[0-5][0-9]$/g) &&
                this.content().duration !== '00:00'
            );
        }
    }

    isNationalAdmin() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    isAssembly() {
        return !this.content().type;
    }

    isDisabledCategory(category) {
        switch (this.content().type) {
            case 'h5pactivity': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp': {
                        return true;
                    }
                    case 'evaluation':
                    case 'positionnement':
                    case 'ressource':
                    case 'travail': {
                        return false;
                    }
                }
                break;
            }
            case 'quiz': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'preparation':
                    case 'presentiel':
                    case 'ressource':
                    case 'travail':
                    case 'tp': {
                        return true;
                    }
                    case 'evaluation':
                    case 'positionnement': {
                        return false;
                    }
                }
                break;
            }
            case 'pdf': {
                switch (category) {
                    case 'devoir':
                    case 'presentiel': {
                        return true;
                    }
                    case 'positionnement':
                    case 'corrige':
                    case 'evaluation':
                    case 'preparation':
                    case 'ressource':
                    case 'tp':
                    case 'travail': {
                        return false;
                    }
                }
                break;
            }
            case 'html':
            case 'fichier': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp': {
                        return true;
                    }
                    case 'ressource':
                    case 'travail': {
                        return false;
                    }
                }
                break;
            }
            case 'scorm': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp': {
                        return true;
                    }
                    case 'evaluation':
                    case 'positionnement':
                    case 'ressource':
                    case 'travail': {
                        return false;
                    }
                }
                break;
            }
            case 'video':
            case 'hachette':
            case 'nathan': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp':
                    case 'travail': {
                        return true;
                    }
                    case 'ressource': {
                        return false;
                    }
                }
                break;
            }
            case 'office': {
                switch (category) {
                    case 'devoir':
                    case 'positionnement':
                    case 'presentiel': {
                        return true;
                    }
                    case 'corrige':
                    case 'evaluation':
                    case 'preparation':
                    case 'tp':
                    case 'travail':
                    case 'ressource': {
                        return false;
                    }
                }
                break;
            }
            case '3D': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp': {
                        return true;
                    }
                    case 'travail':
                    case 'ressource': {
                        return false;
                    }
                }
                break;
            }
            case 'url':
            case 'partenariat': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp':
                    case 'travail': {
                        return true;
                    }
                    case 'ressource': {
                        return false;
                    }
                }
                break;
            }
            case 'lti': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp':
                    case 'travail': {
                        return true;
                    }
                    case 'ressource': {
                        return false;
                    }
                }
                break;
            }
            case 'presentiel': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'ressource':
                    case 'travail': {
                        return true;
                    }
                    case 'presentiel':
                    case 'tp': {
                        return false;
                    }
                }
                break;
            }
            case 'devoir': {
                switch (category) {
                    case 'corrige':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'ressource':
                    case 'tp':
                    case 'travail': {
                        return true;
                    }
                    case 'devoir': {
                        return false;
                    }
                }
                break;
            }
            case 'n4s': {
                switch (category) {
                    case 'corrige':
                    case 'evaluation':
                    case 'devoir':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp':
                    case 'ressource':
                    case 'travail': {
                        return true;
                    }
                    case 'positionnement': {
                        return false;
                    }
                }
                break;
            }
        }
    }

    getDomainsDropDownLabel() {
        if (this.domains.filter((e) => e.selected).length === 1) {
            return this.domains.find((e) => e.selected).title;
        } else {
            return 'Domaines';
        }
    }

    showVisibleForAll() {
        if (this.author && this.author.roles) {
            return (
                (this.author.roles.externalTeacher &&
                    this.loginService.getUser() &&
                    (this.loginService.getUser().roles.nationalAdmin ||
                        this.loginService.getUser().roles.localAdmin)) ||
                (this.loginService.getUser().roles.externalTeacher &&
                    !this.loginService.getUser().additionalRoles.contentLocal)
            );
        }
        return false;
    }

    showLocalDropdown() {
        if (this.restriction) {
            return (
                this.restriction.filter((element) => element.key === 'local')[0].selected ||
                this.restriction.filter((element) => element.key === 'site')[0].selected
            );
        }
    }

    showSiteDropdown() {
        if (this.restriction) {
            return (
                this.restriction.filter((element) => element.key === 'site')[0].selected &&
                this.structures.filter((structure) => structure.selected).length > 0
            );
        }
    }

    getContentIcon() {
        return this.libraryService.getIcon(this.content());
    }

    getContentLevel() {
        switch (this.content().level) {
            case 'parcours': {
                return 'Parcours';
            }
            case 'bloc': {
                return 'Bloc';
            }
            case 'competence': {
                return 'Compétence';
            }
            case 'module': {
                return 'Module';
            }
            case 'sequence': {
                return 'Séquence';
            }
        }
    }

    isLoading(view: string): boolean {
        return this.loadingService.isLoading(view);
    }

    // #endregion Getters

    // #region Handlers

    handleDomainsChange(): void {
        const selectedDomains: Array<Domain> = this.domains.filter(
            (domain: Domain) => domain.selected
        );
        this.content.set({
            ...this.content(),
            domains: selectedDomains.map((selectedDomain: Domain) => selectedDomain.key)
        });
    }

    handleCategoriesChange(): void {
        const selectedCategory = this.categories.find((category: any) => category.selected);
        this.content.set({
            ...this.content(),
            category: selectedCategory ? selectedCategory.key : ''
        });
    }

    handleRestrictionChange() {
        const restriction = this.restriction.find((r) => r.selected);
        if (restriction && restriction.key === 'national') {
            this.structures.forEach((structure) => {
                structure.selected = false;
            });
            this.content().localStructure = null;
        }
        if (!this.restriction.filter((element) => element.key === 'site')[0].selected) {
            this.sites = [];
        } else {
            this.handleStructureChange();
        }
    }

    handleStructureChange() {
        this.sites = [];
        if (this.structures.filter((structure) => structure.selected).length > 0) {
            const filter: any = {
                structureid: this.structures.filter((structure) => structure.selected)[0].key,
                offset: 0,
                limit: 2000
            };
            this.siteService.getSites(filter).subscribe((data: Array<Site>) => {
                this.sites = data.map((site: Site) => {
                    return {
                        key: site.id,
                        title: site.name,
                        icon: site.isCorporation ? 'icon-corporation' : 'icon-site',
                        selected:
                            this.content().sites.findIndex((site2) => site2.name === site.name) > -1
                    };
                });
                const selectedStructure: Structure = this.structures.find(
                    (structure) => structure.selected
                );
                this.content.set({
                    ...this.content(),
                    localStructure: selectedStructure ? selectedStructure.title : null
                });
            });
        }
    }

    handleSiteChange(): void {
        const selectedSite: Array<Site> = this.sites.filter((site: Site) => site.selected);
        this.content.set({
            ...this.content(),
            sites: selectedSite.map((selectedSite: Site) => selectedSite.key)
        });
    }

    handleTypeChange() {
        this.categories = [
            {
                key: 'positionnement',
                icon: 'icon-positionnement-cercle',
                title: 'Positionnement',
                color: '#007a73',
                selected: this.content().category === 'positionnement',
                disabled: this.isDisabledCategory('positionnement')
            },
            {
                key: 'evaluation',
                icon: 'icon-evaluation-cercle',
                title: 'Évaluation',
                color: '#007a73',
                selected: this.content().category === 'evaluation',
                disabled: this.isDisabledCategory('evaluation')
            },
            {
                key: 'preparation',
                icon: 'icon-Preparation-cercle',
                title: 'Préparation',
                color: '#199fe2',
                selected: this.content().category === 'preparation',
                disabled: this.isDisabledCategory('preparation')
            },
            {
                key: 'ressource',
                icon: 'icon-ressource-cercle',
                title: 'Ressource',
                color: '#199fe2',
                selected: this.content().category === 'ressource',
                disabled: this.isDisabledCategory('ressource')
            },
            {
                key: 'devoir',
                icon: 'icon-remise-devoir',
                title: 'Remise de devoir',
                color: '#199fe2',
                selected: this.content().category === 'devoir',
                disabled: this.isDisabledCategory('devoir')
            },
            {
                key: 'corrige',
                icon: 'icon-Corrige-cercle',
                title: 'Corrigé',
                color: '#1f94ce',
                selected: this.content().category === 'corrige',
                disabled: this.isDisabledCategory('corrige')
            },
            {
                key: 'travail',
                icon: 'icon-Miseenapplcation-cercle',
                title: 'Mise en application',
                color: '#19bae2',
                selected: this.content().category === 'travail',
                disabled: this.isDisabledCategory('travail')
            },
            {
                key: 'tp',
                icon: 'icon-TP-cercle',
                title: 'Travaux Pratiques',
                color: '#01a59b',
                selected: this.content().category === 'tp',
                disabled: this.isDisabledCategory('tp')
            },
            {
                key: 'presentiel',
                icon: 'icon-activite-presentielle-cercle',
                title: 'Activité Présentielle',
                color: '#01a59b',
                selected: this.content().category === 'presentiel',
                disabled: this.isDisabledCategory('presentiel')
            }
        ];
    }

    toggleVisibleForAll() {
        this.content().visible_for_all = !this.content().visible_for_all;
    }

    toggleMarkAsCompletedOnOpen() {
        this.content().mark_as_completed_on_open = !this.content().mark_as_completed_on_open;
    }

    // #endregion Handlers

    // #region Internals

    // #region Internals
}
