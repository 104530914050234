// Internal dependencies
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components

// Services

// Interfaces

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-reveal-devoir-confirmation',
    templateUrl: './reveal-devoir-confirmation.component.html',
    styleUrls: ['./reveal-devoir-confirmation.component.scss'],
    imports: [SharedModule, MatDialogModule]
})
export class RevealDevoirConfirmationComponent {
    currentStep = this.data.anonymous ? 1 : 2;

    constructor(
        public dialogRef: MatDialogRef<RevealDevoirConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: { allDevoirsReady: boolean; anonymous: boolean }
    ) {}

    //#region Getters

    //#endregion Getters

    //#region Handlers

    handleClickEndFirstStep(): void {
        if (this.data.allDevoirsReady) {
            this.dialogRef.close(true);
        } else {
            this.currentStep = 2;
        }
    }

    handleCloseDialog(data: boolean | string) {
        this.dialogRef.close(data);
    }

    //#endregion Handlers

    //#region Internals

    //#endregion Internals
}
