// Internal dependencies
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components

// Services
import { LibraryService } from '@/services/library.service';
import { DialogService } from '@/services/dialog.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { LoadingService } from '@/services/loading.service';

// Interfaces
import { Provider } from '@/structures/provider';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-providers',
    templateUrl: './providers.component.html',
    styleUrls: ['./providers.component.scss'],
    imports: [CommonModule, SharedModule, MatIconModule, MatButtonModule]
})
export class ProvidersComponent implements OnInit {
    providers: Array<Provider>;

    constructor(
        private dialogService: DialogService,
        private libraryService: LibraryService,
        private flashMessageService: FlashMessageService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.providers = [];
        this.loadingService.startLoading('ProvidersComponent', 'getProviders');
        this.libraryService.getProviders().subscribe((data: Array<Provider>) => {
            this.providers = data;
            this.loadingService.stopLoading('ProvidersComponent', 'getProviders');
        });
    }

    // #region Getters

    isLoading() {
        return this.loadingService.isLoading('ProvidersComponent');
    }

    // #endregion Getters

    // #region Handlers

    openProviderDialog() {
        this.dialogService.openCreateProvider().subscribe((data: string) => {
            if (data) {
                this.flashMessageService.flash(`Le fournisseur <b>${data}</b> a été créé`);
            }
        });
    }

    // #endregion Handlers
}
