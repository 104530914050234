// Internal dependencies
import {
    Component,
    OnInit,
    Inject,
    ElementRef,
    ViewChild,
    ViewChildren,
    QueryList
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { forkJoin } from 'rxjs';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';
import * as FileSaver from 'file-saver';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components
import { MassImportEntryComponent } from './mass-import-entry/mass-import-entry.component';

// Services
import { AdminService } from '@/services/admin.service';
import { LibraryService } from '@/services/library.service';
import { LoadingService } from '@/services/loading.service';
import { LoginService } from '@/services/login.service';
import { DialogService } from '@/services/dialog.service';

// Interfaces
import { Domain } from '@/structures/domain';
import { Provider } from '@/structures/provider';
import { Taxonomy } from '@/structures/taxonomy';
import { ContentForImport } from '@/structures/content';

// Pipes
import { FileDropzoneDirective } from 'src/app/directives/file-dropdown.directive';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-mass-import',
    templateUrl: './mass-import.component.html',
    styleUrls: ['./mass-import.component.scss'],
    imports: [
        SharedModule,
        MatDialogModule,
        FileDropzoneDirective,
        MatButtonModule,
        MatIconModule,
        MassImportEntryComponent
    ]
})
export class MassImportComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<MassImportComponent>,
        private loginService: LoginService,
        private adminService: AdminService,
        private dialogService: DialogService,
        private libraryService: LibraryService,
        private loadingService: LoadingService
    ) {}

    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    @ViewChildren(MassImportEntryComponent) entryComponents: QueryList<MassImportEntryComponent>;

    file: File;

    headers: { [key: string]: boolean } = {};
    contents: Array<ContentForImport> = [];

    domainsToCreate = [];
    providersToCreate = [];

    domains: Array<string> = [];
    providers: Array<string> = [];
    taxonomies: Array<string> = [];

    ucodes: { [key: string]: number } = {};
    ucodesChildren: { [key: string]: number } = {};

    importStarted: boolean;
    importFinished = false;

    ngOnInit() {
        this.loadingService.startLoading('MassImportComponent', 'Init');

        this.importStarted = false;
        const arrayObservable = [];

        arrayObservable.push(this.libraryService.getDomains());
        arrayObservable.push(this.libraryService.getProviders());
        arrayObservable.push(this.libraryService.getTaxonomies());

        forkJoin(arrayObservable).subscribe((success: Array<any>) => {
            this.domains = success[0].map((domain: Domain) => domain.name);
            this.providers = success[1].map((provider: Provider) => provider.name);
            this.taxonomies = success[2].map((taxonomy: Taxonomy) => taxonomy.name);
            this.loadingService.stopLoading('MassImportComponent', 'Init');
        });
    }

    // #region Getters

    getTitle() {
        if (this.data.importType === 'import-en-masse-activite') {
            return "Import d'activités";
        } else {
            return "Import d'assemblage";
        }
    }

    getCurrentFileLabel() {
        if (this.file) {
            return this.file.name;
        } else {
            return 'Glisser-déposer votre fichier ici';
        }
    }

    isActivityImport() {
        return this.data.importType === 'import-en-masse-activite';
    }

    isImportStarted() {
        return this.importStarted;
    }

    isImportFinished(): boolean {
        return this.importFinished;
    }

    isLoading(view: string) {
        return this.loadingService.isLoading('MassImportComponent', view);
    }

    // #endregion Getters

    // #region Handlers

    handleClickSelectFile() {
        this.fileInput.nativeElement.click();
    }

    handleUploadFile(files: FileList) {
        this.loadingService.startLoading('MassImportComponent', 'CheckImport');
        this.file = files.item(0);
        this.parseFile();
    }

    handleStartImport() {
        this.importStarted = true;

        const arrayObservable = [];
        this.domainsToCreate.map((domain) => {
            arrayObservable.push(this.adminService.createDomain(domain));
        });
        this.providersToCreate.map((provider) => {
            arrayObservable.push(this.adminService.createProvider(provider));
        });
        this.loadingService.startLoading('MassImportComponent', 'StartImport');
        if (arrayObservable.length === 0) {
            this.importContent(0);
        } else {
            forkJoin(arrayObservable).subscribe(() => {
                this.importContent(0);
            });
        }
    }

    handleContentImported($event: { index: number; status: boolean }) {
        if ($event.index < this.contents.length - 1) {
            this.importContent($event.index + 1);
        } else {
            this.loadingService.stopLoading('MassImportComponent', 'StartImport');
            this.exportAsCSV();
        }
    }

    handleCloseDialog() {
        this.dialogRef.close();
    }

    // #endregion Handlers

    // #region Internals

    private parseFile() {
        const reader = new FileReader();

        reader.onload = (e: any) => {
            if (this.isUTF8Encoded(e.target.result)) {
                this.convertFileToJSON(e.target.result);
            } else {
                this.dialogService.openError(
                    "L'encodage du fichier n'est pas correct. Veuillez encoder votre fichier en UTF-8 puis réessayer"
                );
            }
        };

        reader.readAsText(this.file, 'UTF-8');
    }

    private isUTF8Encoded(content: string): boolean {
        try {
            new TextDecoder('utf-8').decode(new TextEncoder().encode(content));
            return true;
        } catch (e) {
            return false;
        }
    }

    private convertFileToJSON(csvContent: string) {
        const lines = csvContent.trim().split('\n');
        const headers = lines[0].split(';');

        for (let j = 0; j < headers.length; j++) {
            this.headers[headers[j]] = true;
        }

        for (let i = 1; i < lines.length; i++) {
            const obj: Partial<ContentForImport> = {};
            const currentLine = lines[i].trim().split(';');

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j].trim()] = currentLine[j];
            }

            this.contents.push(obj as ContentForImport);
        }

        if (!this.isActivityImport()) {
            this.sortArray();
        }
        this.addIndex();
        this.getUcodes();
        this.checkHeaders();
        this.checkDomains();
        this.checkProviders();
        this.checkTaxonomies();
    }

    private addIndex() {
        this.contents = this.contents.map((content: ContentForImport, index: number) => ({
            ...content,
            index
        }));
    }

    private getUcodes() {
        const contentUcode = this.contents
            .map((content: ContentForImport) => content.ucode)
            .filter(String);
        this.libraryService
            .getContentByUcode(contentUcode)
            .subscribe((ucodeList: { [key: string]: number }) => {
                this.ucodes = ucodeList;
                if (!this.isActivityImport()) {
                    this.getUcodesChildren();
                } else {
                    this.loadingService.stopLoading('MassImportComponent', 'CheckImport');
                }
            });
    }

    private getUcodesChildren() {
        const contentUcode = this.contents
            .map((content: ContentForImport) => content.ucodes_children.split('|'))
            .flat()
            .filter(String);
        this.libraryService
            .getContentByUcode(contentUcode)
            .subscribe((ucodeList: { [key: string]: number }) => {
                this.ucodesChildren = ucodeList;
                this.loadingService.stopLoading('MassImportComponent', 'CheckImport');
            });
    }

    private sortArray() {
        const sortOrder = ['sequence', 'module', 'competence', 'bloc', 'parcours'];
        this.contents.sort((a: ContentForImport, b: ContentForImport) => {
            return sortOrder.indexOf(a.level) - sortOrder.indexOf(b.level);
        });
    }

    private checkHeaders() {
        if (this.isActivityImport()) {
            if (!this.headers.category) {
                this.dialogService.openError(
                    "Le format du fichier n'est pas correct pour un import d'activités. Veuillez vérifier le contenu de votre fichier."
                );
                throw new Error();
            }
        } else {
            if (!this.headers.level) {
                this.dialogService.openError(
                    "Le format du fichier n'est pas correct pour un import d'activités. Veuillez vérifier le contenu de votre fichier."
                );
                throw new Error();
            }
        }
    }

    private importContent(index: number) {
        const entryComponent: MassImportEntryComponent = this.entryComponents.toArray()[index];
        if (entryComponent) {
            entryComponent.handleImportContent();
        }
    }

    private exportAsCSV() {
        let csv = '';
        csv = Object.keys(this.headers).join(';') + ';créé;publié;commentaire\n';
        this.contents.map((content: ContentForImport) => {
            csv += `${Object.keys(this.headers)
                .map((key) => content[key])
                .join(';')};${!!content.created};${!!content.published};${content.comment}\n`;
        });
        const blob = new Blob(['\ufeff', csv], {
            type: 'text/plain;charset=UTF8;'
        });
        FileSaver.saveAs(blob, 'import_de_masse.csv');
        this.importFinished = true;
    }

    private checkDomains() {
        this.contents.map((content: ContentForImport) => {
            if (content.domains) {
                const missingDomains = (content.domains as string)
                    .split('|')
                    .filter((domain) => !this.domains.includes(domain));
                if (missingDomains.length) {
                    this.domainsToCreate = this.domainsToCreate.concat(missingDomains);
                    content.comment = 'Le domaine va être créé';
                }
            }
        });
        this.domainsToCreate = [...new Set(this.domainsToCreate)]; // Remove duplicates
    }

    private checkProviders() {
        this.contents.map((content: ContentForImport) => {
            if (content.provider && !this.providers.includes(content.provider)) {
                this.providersToCreate.push(content.provider);
                content.comment = 'Le fournisseur va être créé';
            }
        });
        this.providersToCreate = [...new Set(this.providersToCreate)]; // Remove duplicates
    }

    private checkTaxonomies() {
        this.contents.map((content: ContentForImport) => {
            if (content.taxonomy && !this.taxonomies.includes(content.taxonomy)) {
                content.comment = "La taxonomie n'existe pas";
                content.invalid = true;
            }
        });
    }

    // #endregion Internals
}
