<div class="creation-space-container">
    @if (!isLoading() && drafts) {
        <div class="creation-space">
            <div class="creation-space-header">
                <div class="search-container">
                    <input
                        class="library-search-field"
                        [(ngModel)]="search"
                        placeholder="Chercher..."
                    />
                    <span class="icon-search"></span>
                    @if (search) {
                        <span class="icon-Fermerdetails" (click)="cancelSearch()"></span>
                    }
                </div>
                <div class="blank"></div>
                <div
                    class="fold-all"
                    (click)="emitFoldAll()"
                    [tooltip]="'Revenir à la vue par défaut'"
                >
                    Vue par défaut
                </div>
            </div>
            <mat-expansion-panel class="content-container" [expanded]="true">
                <mat-expansion-panel-header
                    class="legend"
                    [collapsedHeight]="'30px'"
                    [expandedHeight]="'30px'"
                    >Activités en cours de création ({{
                        getContents('activities').length
                    }})</mat-expansion-panel-header
                >
                @for (activity of getContents('activities'); track activity.id) {
                    <app-creation-space-entry
                        [(draft)]="getContents('activities')[$index]"
                        [type]="'activities'"
                        (clonedDraft)="addDraft($event)"
                        (deletedDraft)="deleteDraft($event)"
                        (movedToSentToValidation)="moveDraftToSentToValidation($event)"
                    ></app-creation-space-entry>
                }
            </mat-expansion-panel>

            <mat-expansion-panel class="content-container" [expanded]="true">
                <mat-expansion-panel-header
                    class="legend"
                    [collapsedHeight]="'30px'"
                    [expandedHeight]="'30px'"
                    >Assemblages en cours de création ({{
                        getContents('assemblies').length
                    }})</mat-expansion-panel-header
                >
                @for (assembly of getContents('assemblies'); track assembly.id) {
                    <app-creation-space-entry
                        [(draft)]="getContents('assemblies')[$index]"
                        [type]="'assemblies'"
                        (clonedDraft)="addDraft($event)"
                        (deletedDraft)="deleteDraft($event)"
                        (removeChildFromParent)="removeChildFromParent($event)"
                        (movedToSentToValidation)="moveDraftToSentToValidation($event)"
                    ></app-creation-space-entry>
                }
            </mat-expansion-panel>

            <mat-expansion-panel class="content-container" [expanded]="true">
                <mat-expansion-panel-header
                    class="legend"
                    [collapsedHeight]="'30px'"
                    [expandedHeight]="'30px'"
                    >Contenus en cours de modification ({{
                        getContents('update').length
                    }})</mat-expansion-panel-header
                >
                @for (draft of getContents('update'); track draft.id) {
                    <app-creation-space-entry
                        [(draft)]="getContents('update')[$index]"
                        [type]="'update'"
                        (clonedDraft)="addDraft($event)"
                        (deletedDraft)="deleteDraft($event)"
                        (removeChildFromParent)="removeChildFromParent($event)"
                        (movedToSentToValidation)="moveDraftToSentToValidation($event)"
                    ></app-creation-space-entry>
                }
            </mat-expansion-panel>

            @if (showSentToValidation()) {
                <mat-expansion-panel class="content-container" [expanded]="true">
                    <mat-expansion-panel-header
                        class="legend"
                        [collapsedHeight]="'30px'"
                        [expandedHeight]="'30px'"
                        >Mes contenus en attente de validation ({{
                            getContents('sentToValidation').length
                        }})</mat-expansion-panel-header
                    >
                    @for (content of getContents('sentToValidation'); track content.id) {
                        <app-validation-space-entry
                            [(draft)]="getContents('sentToValidation')[$index]"
                            [type]="'sentToValidation'"
                            (contentMovedToDraft)="moveContentToDraft($event)"
                            (deletedDraft)="deleteDraft($event)"
                        ></app-validation-space-entry>
                    }
                </mat-expansion-panel>
            }

            @if (showWaitingApproval()) {
                <mat-expansion-panel class="content-container" [expanded]="true">
                    <mat-expansion-panel-header
                        class="legend"
                        [collapsedHeight]="'30px'"
                        [expandedHeight]="'30px'"
                        >Contenus à valider ({{
                            getContents('waitingForApproval').length
                        }})</mat-expansion-panel-header
                    >
                    @for (content of getContents('waitingForApproval'); track content.id) {
                        <app-validation-space-entry
                            [(draft)]="getContents('waitingForApproval')[$index]"
                            [type]="'waitingForApproval'"
                            (contentMovedToDraft)="moveContentToDraft($event)"
                            (deletedDraft)="deleteDraft($event)"
                        ></app-validation-space-entry>
                    }
                </mat-expansion-panel>
                @if (getContents('waitingForAdminApproval').length) {
                    <mat-expansion-panel class="content-container" [expanded]="true">
                        <mat-expansion-panel-header
                            class="legend"
                            [collapsedHeight]="'30px'"
                            [expandedHeight]="'30px'"
                            >Contenus à valider en tant qu'administrateur local ({{
                                getContents('waitingForAdminApproval').length
                            }})</mat-expansion-panel-header
                        >
                        @for (content of getContents('waitingForAdminApproval'); track content.id) {
                            <app-validation-space-entry
                                [(draft)]="getContents('waitingForAdminApproval')[$index]"
                                [type]="'waitingForAdminApproval'"
                                (contentMovedToDraft)="moveContentToDraft($event)"
                                (deletedDraft)="deleteDraft($event)"
                            ></app-validation-space-entry>
                        }
                    </mat-expansion-panel>
                }
            }

            <div
                class="create-content"
                (click)="openContentSelection()"
                [tooltip]="'Créer un contenu'"
            >
                <div class="icon-plus"></div>
            </div>
        </div>
    } @else {
        <div class="creation-space">
            <app-loading></app-loading>
        </div>
    }
</div>
