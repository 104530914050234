@if (!isLoading()) {
    <div class="domains-container">
        <div>
            <b>{{ domains.length }}</b> domaines
        </div>
        <div class="scroll-container">
            @for (domain of domains; track domain.name) {
                <div class="domain-entry">
                    <div class="icon-JustificationGauche"></div>
                    <div class="label">{{ domain.name }}</div>
                    <div class="hover-icons">
                        <div
                            class="icon-Editer"
                            [tooltip]="'Éditer le domaine'"
                            (click)="openDomainDialog(domain)"
                        ></div>
                    </div>
                </div>
            }
        </div>
    </div>

    <button mat-fab [tooltip]="'Créer un nouveau domaine'" (click)="openDomainDialog()">
        <mat-icon>add</mat-icon>
    </button>
} @else {
    <app-loading></app-loading>
}
