import {
    Component,
    OnInit,
    OnDestroy,
    Inject,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ConfigService } from '../../services/config.service';
import { FlashMessageService } from '../../services/flash-message.service';
import { LoadingService } from '../../services/loading.service';
import { LoginService } from '../../services/login.service';
import { LibraryService } from '../../services/library.service';
import { SiteService } from '../../services/site.service';
import { UserService } from '../../services/user.service';

import { User } from '../../structures/user';
import { Content } from '../../structures/content';
import { Domain } from '../../structures/domain';
import { Provider } from '../../structures/provider';
import { Taxonomy } from '../../structures/taxonomy';
import { Structure } from '../../structures/structure';
import { Site } from '../../structures/site';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-content-creation',
    templateUrl: './content-creation.component.html',
    styleUrls: ['./content-creation.component.scss']
})
export class ContentCreationComponent implements OnInit, OnDestroy {
    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    subscriptions = new Subscription();

    @ViewChild('guideInput', { static: false }) guideInput;
    @ViewChild('fileInput', { static: false }) fileInput;
    @ViewChild('visuelInput', { static: false }) visuelInput;
    @ViewChild('h5pContentInput', { static: false }) h5pContentInput;

    domains: Array<Domain>;
    taxonomies: Array<Taxonomy>;
    providers: Array<Provider>;
    structures: Array<Structure>;
    sites: Array<Site>;

    hasprice: Array<any>;
    forceStatus: Array<any>;
    trainingOfTeachers: Array<any>;
    hasCertificate: Array<any>;
    newCatalog: Array<any>;
    visibleCatalog: Array<any>;
    forcedStripe: Array<any>;
    stripeColors: Array<any>;
    manuelTypes: Array<any>;

    restriction: Array<any>;
    levels: Array<any>;
    categories: Array<any>;

    h5pTypes: Array<any>;

    file: File;
    guide: File;
    visuel: File;
    h5pContent: File;

    author: User;

    initialContent: Content;
    exitWithoutSaving: boolean;
    facultativeContainerOpened = false;
    catalogContainerOpened = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public content: Content,
        private dialogRef: MatDialogRef<ContentCreationComponent>,
        private configService: ConfigService,
        private flashMessageService: FlashMessageService,
        private loadingService: LoadingService,
        private loginService: LoginService,
        private libraryService: LibraryService,
        private siteService: SiteService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.exitWithoutSaving = false;
        this.initialContent = JSON.parse(JSON.stringify(this.content));
        if (Array.isArray(this.content.keywords)) {
            this.content.keywords = this.content.keywords.join(';');
        }
        this.loadingService.startLoading('contentCreationBody', 'getStructures');
        this.subscriptions.add(
            this.loginService.getStructures().subscribe(
                (data: Array<Structure>) => {
                    if (this.isNationalAdmin()) {
                        this.structures = data.map((structure: Structure) => {
                            return {
                                key: structure.id,
                                title: structure.name,
                                icon: 'icon-local',
                                selected: this.content.localStructure === structure.name
                            };
                        });
                    } else {
                        this.structures = [
                            {
                                key: this.loginService.getUser().structureid,
                                title: this.loginService.getUser().localStructure,
                                icon: 'icon-local',
                                selected: true
                            }
                        ];
                    }
                    this.updateSite();
                    this.loadingService.stopLoading('contentCreationBody', 'getStructures');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('contentCreationBody', 'getStructures');
                }
            )
        );

        this.loadingService.startLoading('contentCreationBody', 'getDomains');
        this.subscriptions.add(
            this.libraryService.getDomains().subscribe(
                (data: Array<Domain>) => {
                    this.domains = data.map((domain: Domain) => {
                        return {
                            key: domain.name,
                            title: domain.name,
                            selected: this.content.domains.indexOf(domain.name) > -1
                        };
                    });
                    this.loadingService.stopLoading('contentCreationBody', 'getDomains');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('contentCreationBody', 'getDomains');
                }
            )
        );

        this.loadingService.startLoading('contentCreationBody', 'getTaxonomies');
        this.subscriptions.add(
            this.libraryService.getTaxonomies().subscribe(
                (data: Array<Taxonomy>) => {
                    this.taxonomies = data.map((taxonomy: Taxonomy) => {
                        return {
                            key: taxonomy.name,
                            title: taxonomy.name,
                            selected: this.content.taxonomy === taxonomy.name
                        };
                    });
                    this.loadingService.stopLoading('contentCreationBody', 'getTaxonomies');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('contentCreationBody', 'getTaxonomies');
                }
            )
        );

        this.loadingService.startLoading('contentCreationBody', 'getProviders');
        this.subscriptions.add(
            this.libraryService.getProviders().subscribe(
                (data: Array<Provider>) => {
                    this.providers = data.map((provider: any) => {
                        return {
                            key: provider.name,
                            title: provider.name,
                            selected: this.content.provider === provider.name
                        };
                    });
                    this.loadingService.stopLoading('contentCreationBody', 'getProviders');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('contentCreationBody', 'getProviders');
                }
            )
        );

        if (this.initialContent.author) {
            this.subscriptions.add(
                this.userService.getUser(this.content.author).subscribe((author: User) => {
                    this.author = author;
                })
            );
        }

        if (this.content.type === 'h5pactivity' && !this.content.id) {
            this.libraryService.getH5PTypes().subscribe((data: Array<any>) => {
                this.h5pTypes = data.map((h5ptype) => ({
                    title: h5ptype.title,
                    key: `${h5ptype.machinename} ${h5ptype.majorversion}.${h5ptype.minorversion}`
                }));
            });
        }

        this.restriction = [
            {
                key: 'national',
                icon: 'icon-national',
                title: 'Contenu national',
                selected: !this.content.localStructure && this.isNationalAdmin(),
                disabled: !this.isNationalAdmin()
            },
            {
                key: 'local',
                icon: 'icon-local',
                title: 'Contenu local',
                selected:
                    (this.content.localStructure && this.content.sites.length === 0) ||
                    (!this.isNationalAdmin() &&
                        !(this.content.localStructure && this.content.sites.length !== 0)),
                disabled:
                    this.loginService.getUser().roles.corporationTeacher ||
                    this.loginService.getUser().roles.siteTeacher
            },
            {
                key: 'site',
                icon: 'icon-site',
                title: 'Contenu restreint à un site',
                selected:
                    (this.content.localStructure && this.content.sites.length !== 0) ||
                    this.loginService.getUser().roles.corporationTeacher ||
                    this.loginService.getUser().roles.siteTeacher
            }
        ];

        this.hasprice = [
            {
                key: 0,
                title: 'Non',
                selected: this.content.hasprice === 0
            },
            {
                key: 1,
                title: 'Oui (Prix)',
                selected: this.content.hasprice === 1
            },
            {
                key: 2,
                title: 'Oui (A assigner via le contenu parent)',
                selected: this.content.hasprice === 2
            },
            {
                key: 3,
                title: 'Oui (infos)',
                selected: this.content.hasprice === 3
            }
        ];

        this.forceStatus = [
            {
                key: true,
                title: 'Oui',
                selected: this.content.forceStatus === true
            },
            {
                key: false,
                title: 'Non',
                selected: this.content.forceStatus === false
            }
        ];

        this.trainingOfTeachers = [
            {
                key: true,
                title: 'Oui',
                selected: this.content.is_training_of_teachers === true
            },
            {
                key: false,
                title: 'Non',
                selected: this.content.is_training_of_teachers === false
            }
        ];

        this.hasCertificate = [
            {
                key: true,
                title: 'Oui',
                selected: this.content.hasCertificate === true
            },
            {
                key: false,
                title: 'Non',
                selected: this.content.hasCertificate === false
            }
        ];

        this.newCatalog = [
            {
                key: true,
                title: 'Oui',
                selected: this.content.new !== false
            },
            {
                key: false,
                title: 'Non',
                selected: this.content.new === false
            }
        ];

        this.visibleCatalog = [
            {
                key: true,
                title: 'Oui',
                selected: this.content.visible === true
            },
            {
                key: false,
                title: 'Non',
                selected: this.content.visible !== true
            }
        ];

        this.forcedStripe = [
            {
                key: true,
                title: 'Oui',
                selected: this.content.forced_stripe === true
            },
            {
                key: false,
                title: 'Non',
                selected: this.content.forced_stripe !== true
            }
        ];

        this.manuelTypes = [
            {
                key: 'nathan',
                title: 'Nathan',
                selected: this.content.type === 'nathan'
            },
            {
                key: 'hachette',
                title: 'Hachette',
                selected: this.content.type === 'hachette'
            }
        ];

        this.stripeColors = ['#007fad', '#035a77', '#955099', '#e8b600', '#f36', '#01a59b'];
        if (!this.content.forced_stripe_color) {
            this.selectStripeColor(this.stripeColors[0]);
        }

        this.levels = [
            {
                key: 'parcours',
                icon: 'icon-parcours-cercle',
                title: 'Parcours',
                color: '#01a59b',
                selected: this.content.level === 'parcours',
                disabled:
                    this.content.disabledLevel &&
                    this.content.disabledLevel &&
                    this.content.disabledLevel.indexOf('parcours') !== -1
            },
            {
                key: 'bloc',
                icon: 'icon-bloc-cercle',
                title: 'Bloc',
                color: '#005980',
                selected: this.content.level === 'bloc',
                disabled:
                    this.content.disabledLevel && this.content.disabledLevel.indexOf('bloc') !== -1
            },
            {
                key: 'competence',
                icon: 'icon-competence-cercle',
                title: 'Compétence',
                color: '#ff5a00',
                selected: this.content.level === 'competence',
                disabled:
                    this.content.disabledLevel &&
                    this.content.disabledLevel.indexOf('competence') !== -1
            },
            {
                key: 'module',
                icon: 'icon-module-cercle',
                title: 'Module',
                color: '#ea63d2',
                selected: this.content.level === 'module',
                disabled:
                    this.content.disabledLevel &&
                    this.content.disabledLevel.indexOf('module') !== -1
            },
            {
                key: 'sequence',
                icon: 'icon-sequence-cercle',
                title: 'Séquence',
                color: '#ab80fb',
                selected: this.content.level === 'sequence',
                disabled:
                    this.content.disabledLevel &&
                    this.content.disabledLevel.indexOf('sequence') !== -1
            }
        ];

        this.updateCategory();

        if (this.content.original_url) {
            this.content.url = this.content.original_url;
        }
        if (!this.content.forced_stripe_text) {
            this.content.forced_stripe_text = 'Nouveau';
        }
    }

    ngOnDestroy() {
        if (this.exitWithoutSaving) {
            Object.assign(this.content, this.initialContent);
        }
    }

    getContentIcon() {
        return this.libraryService.getIcon(this.content);
    }

    getContentLabel() {
        return this.content.contentlevel;
    }

    showExtraContainer() {
        return (
            this.showFileContainer() ||
            this.showLTIContainer() ||
            this.showURLContainer() ||
            this.showManuelContainer() ||
            this.showN4SContainer() ||
            this.showH5PContainer()
        );
    }

    showFileContainer() {
        return (
            ['devoir', 'pdf', 'html', 'fichier', 'scorm', 'video', 'office', '3D'].indexOf(
                this.content.type
            ) > -1
        );
    }

    showLTIContainer() {
        return this.content.type === 'lti';
    }

    showURLContainer() {
        return this.content.type === 'url' || this.content.type === 'partenariat';
    }

    showManuelContainer() {
        return this.content.type === 'hachette' || this.content.type === 'nathan';
    }

    showManuelType() {
        return this.configService.getConfig().nathanNationalCreation;
    }

    showH5PContainer() {
        return this.content.type === 'h5pactivity';
    }

    showN4SContainer() {
        return this.content.type === 'n4s';
    }

    updateRestriction() {
        const restriction = this.restriction.find((r) => r.selected);
        if (restriction && restriction.key === 'national') {
            this.structures.forEach((structure) => {
                structure.selected = false;
            });
        }
        if (!this.restriction.filter((element) => element.key === 'site')[0].selected) {
            this.sites = [];
        } else {
            this.updateSite();
        }
    }

    updateType() {
        this.content.type = this.manuelTypes.filter((type) => type.selected)[0].key;
    }

    updateH5PType() {
        this.content.h5p_type = this.h5pTypes.filter((h5ptype) => h5ptype.selected)[0].key;
        this.h5pContent = undefined;
    }

    updateSite() {
        this.sites = [];
        if (this.structures.filter((structure) => structure.selected).length > 0) {
            const filter: any = {
                structureid: this.structures.filter((structure) => structure.selected)[0].key,
                offset: 0,
                limit: 2000
            };
            this.subscriptions.add(
                this.siteService.getSites(filter).subscribe((data: Array<Site>) => {
                    this.sites = data.map((site: Site) => {
                        return {
                            key: site.id,
                            title: site.name,
                            icon: site.isCorporation ? 'icon-corporation' : 'icon-site',
                            selected:
                                this.content.sites.findIndex((site2) => site2.name === site.name) >
                                -1
                        };
                    });
                })
            );
        }
    }

    updateCategory() {
        this.categories = [
            {
                key: 'positionnement',
                icon: 'icon-positionnement-cercle',
                title: 'Positionnement',
                color: '#007a73',
                selected: this.content.category === 'positionnement',
                disabled: this.isDisabledCategory('positionnement')
            },
            {
                key: 'evaluation',
                icon: 'icon-evaluation-cercle',
                title: 'Évaluation',
                color: '#007a73',
                selected: this.content.category === 'evaluation',
                disabled: this.isDisabledCategory('evaluation')
            },
            {
                key: 'preparation',
                icon: 'icon-Preparation-cercle',
                title: 'Préparation',
                color: '#199fe2',
                selected: this.content.category === 'preparation',
                disabled: this.isDisabledCategory('preparation')
            },
            {
                key: 'ressource',
                icon: 'icon-ressource-cercle',
                title: 'Ressource',
                color: '#199fe2',
                selected: this.content.category === 'ressource',
                disabled: this.isDisabledCategory('ressource')
            },
            {
                key: 'devoir',
                icon: 'icon-remise-devoir',
                title: 'Remise de devoir',
                color: '#199fe2',
                selected: this.content.category === 'devoir',
                disabled: this.isDisabledCategory('devoir')
            },
            {
                key: 'corrige',
                icon: 'icon-Corrige-cercle',
                title: 'Corrigé',
                color: '#1f94ce',
                selected: this.content.category === 'corrige',
                disabled: this.isDisabledCategory('corrige')
            },
            {
                key: 'travail',
                icon: 'icon-Miseenapplcation-cercle',
                title: 'Mise en application',
                color: '#19bae2',
                selected: this.content.category === 'travail',
                disabled: this.isDisabledCategory('travail')
            },
            {
                key: 'tp',
                icon: 'icon-TP-cercle',
                title: 'Travaux Pratiques',
                color: '#01a59b',
                selected: this.content.category === 'tp',
                disabled: this.isDisabledCategory('tp')
            },
            {
                key: 'presentiel',
                icon: 'icon-activite-presentielle-cercle',
                title: 'Activité Présentielle',
                color: '#01a59b',
                selected: this.content.category === 'presentiel',
                disabled: this.isDisabledCategory('presentiel')
            }
        ];
    }

    getTitle() {
        if (!this.content.type) {
            return "Fiche d'assemblage";
        } else if (this.content.type === 'presentiel') {
            return "Fiche d'activité (Sans contenu)";
        } else if (this.content.type === 'url') {
            return "Fiche d'activité (Lien web)";
        } else if (this.content.type === 'partenariat') {
            return "Fiche d'activité (Partenariat)";
        } else if (this.content.type === 'lti') {
            return "Fiche d'activité (LTI)";
        } else if (this.content.type === 'hachette' || this.content.type === 'nathan') {
            return "Fiche d'activité (Manuel numérique)";
        } else if (this.content.type === 'devoir') {
            return "Fiche d'activité (Remise de devoir)";
        } else if (this.content.type === 'n4s') {
            return "Fiche d'activité (Positionnement Num4Skills)";
        } else if (this.content.type === 'quiz') {
            return "Fiche d'activité (Test)";
        } else if (this.content.type === 'h5pactivity') {
            return "Fiche d'activité (H5P)";
        } else {
            return "Fiche d'activité (Import)";
        }
    }

    isDisabledCategory(category) {
        switch (this.content.type) {
            case 'h5pactivity': {
                switch (category) {
                    case 'corrige':
                    case 'evaluation':
                    case 'devoir':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp': {
                        return true;
                    }
                    case 'ressource':
                    case 'travail': {
                        return false;
                    }
                }
                break;
            }
            case 'quiz': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp': {
                        return true;
                    }
                    case 'evaluation':
                    case 'positionnement':
                    case 'ressource':
                    case 'travail': {
                        return false;
                    }
                }
                break;
            }
            case 'pdf': {
                switch (category) {
                    case 'devoir':
                    case 'presentiel': {
                        return true;
                    }
                    case 'positionnement':
                    case 'corrige':
                    case 'evaluation':
                    case 'preparation':
                    case 'ressource':
                    case 'tp':
                    case 'travail': {
                        return false;
                    }
                }
                break;
            }
            case 'html':
            case 'fichier': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp': {
                        return true;
                    }
                    case 'ressource':
                    case 'travail': {
                        return false;
                    }
                }
                break;
            }
            case 'scorm': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp': {
                        return true;
                    }
                    case 'evaluation':
                    case 'positionnement':
                    case 'ressource':
                    case 'travail': {
                        return false;
                    }
                }
                break;
            }
            case 'video':
            case 'hachette':
            case 'nathan': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp':
                    case 'travail': {
                        return true;
                    }
                    case 'ressource': {
                        return false;
                    }
                }
                break;
            }
            case 'office': {
                switch (category) {
                    case 'devoir':
                    case 'positionnement':
                    case 'presentiel': {
                        return true;
                    }
                    case 'corrige':
                    case 'evaluation':
                    case 'preparation':
                    case 'tp':
                    case 'travail':
                    case 'ressource': {
                        return false;
                    }
                }
                break;
            }
            case '3D': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp': {
                        return true;
                    }
                    case 'travail':
                    case 'ressource': {
                        return false;
                    }
                }
                break;
            }
            case 'url':
            case 'partenariat': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp':
                    case 'travail': {
                        return true;
                    }
                    case 'ressource': {
                        return false;
                    }
                }
                break;
            }
            case 'lti': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp':
                    case 'travail': {
                        return true;
                    }
                    case 'ressource': {
                        return false;
                    }
                }
                break;
            }
            case 'presentiel': {
                switch (category) {
                    case 'corrige':
                    case 'devoir':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'ressource':
                    case 'travail': {
                        return true;
                    }
                    case 'presentiel':
                    case 'tp': {
                        return false;
                    }
                }
                break;
            }
            case 'devoir': {
                switch (category) {
                    case 'corrige':
                    case 'evaluation':
                    case 'positionnement':
                    case 'preparation':
                    case 'presentiel':
                    case 'ressource':
                    case 'tp':
                    case 'travail': {
                        return true;
                    }
                    case 'devoir': {
                        return false;
                    }
                }
                break;
            }
            case 'n4s': {
                switch (category) {
                    case 'corrige':
                    case 'evaluation':
                    case 'devoir':
                    case 'preparation':
                    case 'presentiel':
                    case 'tp':
                    case 'ressource':
                    case 'travail': {
                        return true;
                    }
                    case 'positionnement': {
                        return false;
                    }
                }
                break;
            }
        }
    }

    isValidDuration() {
        if (this.content.duration) {
            return this.content.duration.match(/^[0-9]?[0-9]?[0-9]?[0-9]:[0-5][0-9]$/g);
        }
    }

    getContentType(file) {
        const extension = /[^.]+$/.exec(file.name)[0].toLowerCase();

        switch (extension) {
            case 'pdf':
                return 'pdf';
            case 'mp4':
            case 'f4v':
            case 'swf':
            case 'flv':
                return 'video';
            case 'html':
                return 'html';
            case 'zip':
                return 'scorm';
            case 'odt':
            case 'ott':
            case 'odm':
            case 'oth':
            case 'ods':
            case 'ots':
            case 'odg':
            case 'otg':
            case 'odp':
            case 'otp':
            case 'odf':
            case 'oxt':
                return 'office';
            case 'sldprt':
            case 'sldasm':
            case 'slddrw':
            case 'dxf':
            case 'iges':
            case 'step':
            case 'toppkg':
            case 'psimsch':
            case 'smv':
            case 'amf':
            case 'stl':
            case '3mf':
            case 'obj':
            case 'ply':
            case 'gcode':
            case 'stl':
            case '3mf':
            case 'obj':
            case 'ply':
            case 'gcode':
            case 'dft':
            case 'mpp':
            case 'step':
            case 'stp':
            case 'top':
            case 'x_t':
            case 'bmp':
            case 'dwg':
            case 'dxf':
            case 'db':
            case 'rpt':
            case 'igs':
                return '3D';
            default:
                if (
                    extension.indexOf('xl') === 0 ||
                    extension.indexOf('do') === 0 ||
                    extension.indexOf('pp') === 0
                ) {
                    return 'office';
                } else {
                    return 'fichier';
                }
        }
    }

    isNationalAdmin() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    isAdmin() {
        return (
            this.loginService.getUser().roles.nationalAdmin ||
            this.loginService.getUser().roles.localAdmin
        );
    }

    showVisibleForAll() {
        if (this.author && this.author.roles) {
            return (
                (this.author.roles.externalTeacher &&
                    this.loginService.getUser() &&
                    (this.loginService.getUser().roles.nationalAdmin ||
                        this.loginService.getUser().roles.localAdmin)) ||
                (this.loginService.getUser().roles.externalTeacher &&
                    !this.loginService.getUser().additionalRoles.contentLocal)
            );
        }
        return false;
    }

    isAssembly() {
        return !this.content.type;
    }

    showLocalDropdown() {
        if (this.restriction) {
            return (
                this.restriction.filter((element) => element.key === 'local')[0].selected ||
                this.restriction.filter((element) => element.key === 'site')[0].selected
            );
        }
    }

    showSiteDropdown() {
        if (this.restriction) {
            return (
                this.restriction.filter((element) => element.key === 'site')[0].selected &&
                this.structures.filter((structure) => structure.selected).length > 0
            );
        }
    }

    hasFile() {
        return this.content.path || this.file;
    }

    getCurrentFileLabel() {
        if (this.content.path || this.file) {
            let tmp: string;
            if (this.file) {
                tmp = this.file.name;
            } else {
                if (this.content.type === 'scorm') {
                    return 'Fichier SCORM';
                }
                tmp = this.content.path.split('/')[this.content.path.split('/').length - 1];
            }
            if (tmp.length < 32) {
                return decodeURIComponent(tmp) + '';
            } else {
                const hash = tmp.substring(0, 32);
                const regex = RegExp('^[a-z0-9]+$', 'g');
                if (!regex.test(hash)) {
                    return `${decodeURIComponent(tmp)}`;
                } else {
                    return `${decodeURIComponent(tmp.substring(32))}`;
                }
            }
        } else {
            return 'Glisser-déposer votre fichier ici';
        }
    }

    getFileTooltip() {
        if (this.content.path || this.file) {
            if (this.content.type === 'devoir') {
                return "Modifier l'énoncé";
            } else {
                return 'Modifier le fichier';
            }
        } else {
            if (this.content.type === 'devoir') {
                return 'Ajouter un énoncé';
            } else {
                return 'Ajouter un fichier';
            }
        }
    }

    openSelectFile() {
        this.fileInput.nativeElement.click();
    }

    uploadFile(files: FileList) {
        if (
            this.content.id &&
            this.content.type !== 'scorm' &&
            this.getContentType(files.item(0)) === 'scorm'
        ) {
            this.openErrorDialog.next(
                'Vous ne pouvez pas modifier ce type de document par un SCORM'
            );
            return;
        }

        if (
            this.content.id &&
            this.content.type === 'scorm' &&
            this.getContentType(files.item(0)) !== 'scorm'
        ) {
            this.openErrorDialog.next("Vous ne pouvez pas changer le type d'une activité SCORM");
            return;
        }

        if (files.item(0).size > this.getMaxFileSize()) {
            this.openErrorDialog.next('Votre fichier doit être inférieur à 200 Mo');
            return;
        }

        this.file = files.item(0);
        if (this.content.type !== 'devoir') {
            this.content.type = this.getContentType(this.file);
            this.updateCategory();
        }
    }

    openSelectH5PContent() {
        this.h5pContentInput.nativeElement.click();
    }

    uploadH5PContent(files: FileList) {
        if (files.item(0).size > this.getMaxFileSize()) {
            this.openErrorDialog.next('Votre fichier doit être inférieur à 200 Mo');
            return;
        }
        this.h5pContent = files.item(0);
        this.h5pTypes = this.h5pTypes.map((h5pType) => ({ ...h5pType, selected: false }));
        this.content.h5p_type = undefined;
    }

    deleteH5PContent() {
        this.h5pContent = undefined;
    }

    toggleScormMenu() {
        this.content.tableOfContents = !this.content.tableOfContents;
    }

    getCurrentH5PLabel() {
        if (this.h5pContent) {
            return this.h5pContent.name;
        } else {
            return 'Glisser-déposer votre fichier H5P ici';
        }
    }

    toggleMarkAsCompletedOnOpen() {
        this.content.mark_as_completed_on_open = !this.content.mark_as_completed_on_open;
    }

    toggleVisibleForAll() {
        this.content.visible_for_all = !this.content.visible_for_all;
    }

    openSelectGuide() {
        this.guideInput.nativeElement.click();
    }

    uploadGuide(files: FileList) {
        if (files.item(0).size > this.getMaxFileSize()) {
            this.openErrorDialog.next('Votre fichier doit être inférieur à 200 Mo');
            return;
        }
        this.guide = files.item(0);
        this.content.removeguide = false;
    }

    deleteGuide() {
        this.content.guide = undefined;
        this.guide = undefined;
        this.content.removeguide = true;
    }

    getCurrentGuideLabel() {
        if (this.content.guide || this.guide) {
            let tmp: string;
            if (this.content.guide) {
                tmp = this.content.guide.split('/')[this.content.guide.split('/').length - 1];
            } else {
                tmp = this.guide.name;
            }
            if (tmp.length < 32) {
                return decodeURIComponent(tmp) + '';
            } else {
                const hash = tmp.substring(0, 32);
                const regex = RegExp('^[a-z0-9]+$', 'g');
                if (!regex.test(hash)) {
                    return `${decodeURIComponent(tmp)}`;
                } else {
                    return `${decodeURIComponent(tmp.substring(32))}`;
                }
            }
        } else {
            return 'Glisser-déposer votre guide ici';
        }
    }

    showPriceNumber() {
        if (this.hasprice) {
            return this.hasprice.filter((element) => element.key === 1)[0].selected;
        }
    }

    showPriceInfo() {
        if (this.hasprice) {
            return this.hasprice.filter((element) => element.key === 3)[0].selected;
        }
    }

    showPriceInput() {
        return this.showPriceNumber() || this.showPriceInfo();
    }

    openSelectVisuel() {
        this.visuelInput.nativeElement.click();
    }

    uploadVisuel(files: FileList) {
        if (files.item(0).size > this.getMaxFileSize()) {
            this.openErrorDialog.next('Votre fichier doit être inférieur à 200 Mo');
            return;
        }
        this.visuel = files.item(0);
        this.content.removevisuel = false;
    }

    deleteVisuel() {
        this.content.visuel = undefined;
        this.visuel = undefined;
        this.content.removevisuel = true;
    }

    isForcedStripe() {
        return this.forcedStripe.filter((status) => status.selected)[0].key === true;
    }

    selectStripeColor(color) {
        this.content.forced_stripe_color = color;
    }

    isSelectedStripeColor(color) {
        return this.content.forced_stripe_color === color;
    }

    getCurrentVisuelLabel() {
        if (this.content.visuel || this.visuel) {
            let tmp: string;
            if (this.content.visuel) {
                tmp = this.content.visuel.split('/')[this.content.visuel.split('/').length - 1];
            } else {
                tmp = this.visuel.name;
            }
            if (tmp.length < 32) {
                return decodeURIComponent(tmp) + '';
            } else {
                const hash = tmp.substring(0, 32);
                const regex = RegExp('^[a-z0-9]+$', 'g');
                if (!regex.test(hash)) {
                    return `${decodeURIComponent(tmp)}`;
                } else {
                    return `${decodeURIComponent(tmp.substring(32))}`;
                }
            }
        } else {
            return 'Glisser-déposer votre image ici (.png .jpg .gif)';
        }
    }

    canSaveContent() {
        if (
            this.levels &&
            this.levels.filter((level) => level.selected).length === 0 &&
            this.categories &&
            this.categories.filter((category) => category.selected).length === 0
        ) {
            return false;
        }
        if (
            !this.content.id &&
            this.content.type === 'h5pactivity' &&
            !(this.content.h5p_type || this.h5pContent)
        ) {
            return false;
        }
        if (this.content.title.trim() === '') {
            return false;
        }
        return true;
    }

    canPublishContent() {
        if (
            this.levels &&
            this.levels.filter((level) => level.selected).length === 0 &&
            this.categories &&
            this.categories.filter((category) => category.selected).length === 0
        ) {
            return false;
        }
        if (this.content.title.trim() === '') {
            return false;
        }
        if (this.content.description.trim() === '') {
            return false;
        }
        if (this.content.keywords.trim() === '') {
            return false;
        }
        if (this.content.copyright.trim() === '') {
            return false;
        }
        if (this.domains && this.domains.filter((domain) => domain.selected).length === 0) {
            return false;
        }
        if (
            this.hasprice.filter((hasprice) => hasprice.selected)[0].key === 1 &&
            (this.content.price === undefined || this.content.price === undefined)
        ) {
            return false;
        }
        if (
            this.hasprice.filter((hasprice) => hasprice.selected)[0].key === 3 &&
            !this.content.pricetxt
        ) {
            return false;
        }
        if (
            this.restriction.filter((restriction) => restriction.selected)[0].key === 'local' &&
            this.structures &&
            this.structures.filter((structure) => structure.selected).length === 0
        ) {
            return false;
        }
        if (
            this.restriction.filter((restriction) => restriction.selected)[0].key === 'site' &&
            this.sites &&
            this.sites.filter((site) => site.selected).length === 0
        ) {
            return false;
        }
        if (!this.levels.filter((level) => level.selected)[0] && !this.isValidDuration()) {
            return false;
        }
        if (this.showFileContainer()) {
            return this.file || this.content.path;
        }
        return true;
    }

    saveContent() {
        if (!this.canSaveContent()) {
            return;
        }

        const body: any = {
            ...this.content,
            level: this.levels.filter((level) => level.selected)[0]
                ? this.levels.filter((level) => level.selected)[0].key
                : null,
            category: this.categories.filter((category) => category.selected)[0]
                ? this.categories.filter((category) => category.selected)[0].key
                : null,
            localStructure: this.structures.filter((structure) => structure.selected)[0]
                ? this.structures.filter((structure) => structure.selected)[0].title
                : null,
            sites: this.sites
                ? this.sites
                      .filter((site) => site.selected)
                      .map((site) => ({ id: site.key, name: site.title }))
                : [],
            domains: this.domains.filter((domain) => domain.selected).map((domain) => domain.title),
            keywords: this.content.keywords
                .split(';')
                .map((keyword) => keyword.trim())
                .filter((keyword) => !!keyword),
            taxonomy: this.taxonomies.filter((taxonomy) => taxonomy.selected)[0]
                ? this.taxonomies.filter((taxonomy) => taxonomy.selected)[0].key
                : null,
            provider: this.providers.filter((provider) => provider.selected)[0]
                ? this.providers.filter((provider) => provider.selected)[0].key
                : null,
            forceStatus: this.forceStatus.filter((status) => status.selected)[0].key,
            is_training_of_teachers: this.trainingOfTeachers.filter(
                (training) => training.selected
            )[0].key,

            hasCertificate: this.hasCertificate.filter((status) => status.selected).length
                ? this.hasCertificate.filter((status) => status.selected)[0].key
                : null,
            hasprice: this.hasprice.filter((hasprice) => hasprice.selected)[0].key,
            new: this.newCatalog.filter((status) => status.selected).length
                ? this.newCatalog.filter((status) => status.selected)[0].key
                : null,
            visible: this.visibleCatalog.filter((status) => status.selected).length
                ? this.visibleCatalog.filter((status) => status.selected)[0].key
                : null,
            forced_stripe: this.forcedStripe.filter((status) => status.selected).length
                ? this.forcedStripe.filter((status) => status.selected)[0].key
                : null
        };

        if (!this.content.hasCertificate && body.hasCertificate) {
            body.newCertificate = true;
        }

        this.loadingService.startLoading('contentCreationFooter', 'saveContent');
        if (body.id) {
            if (body.level) {
                this.subscriptions.add(
                    this.libraryService.updateAssembly(body, this.guide, this.visuel).subscribe(
                        (success: Content) => {
                            this.libraryService.emitUpdatedDraft(body);
                            this.flashMessageService.flash(`L'assemblage a été mis à jour`);

                            this.loadingService.stopLoading('contentCreationFooter', 'saveContent');
                            this.dialogRef.close(body);
                        },
                        (error: HttpErrorResponse) => {
                            this.loadingService.stopLoading('contentCreationFooter', 'saveContent');
                        }
                    )
                );
            } else {
                this.subscriptions.add(
                    this.libraryService
                        .updateActivity(body, this.file, this.guide, this.visuel)
                        .subscribe(
                            (success) => {
                                this.libraryService.emitUpdatedDraft(body);
                                /**
                                 * Hack pas très propre mais qui corrige rapidement le pb #282498
                                 * Trouver une solution plus propre.
                                 */
                                if (this.file || this.guide) {
                                    this.libraryService
                                        .getContent(body.id, false)
                                        .subscribe((resp: any) => {
                                            this.libraryService.emitUpdatedDraft(resp);
                                        });
                                }
                                this.flashMessageService.flash(`L'activité a été mise à jour`);

                                this.loadingService.stopLoading(
                                    'contentCreationFooter',
                                    'saveContent'
                                );
                                this.closeDialog(true);
                            },
                            (error: HttpErrorResponse) => {
                                this.loadingService.stopLoading(
                                    'contentCreationFooter',
                                    'saveContent'
                                );
                            }
                        )
                );
            }
        } else {
            if (body.level) {
                this.subscriptions.add(
                    this.libraryService.createAssembly(body, this.guide, this.visuel).subscribe(
                        (success: any) => {
                            this.flashMessageService.flash(`L'assemblage a été créé`);
                            if (body.parentid) {
                                success.parentid = body.parentid;
                            }
                            this.libraryService.emitAddedDraft(success);
                            this.loadingService.stopLoading('contentCreationFooter', 'saveContent');
                            this.dialogRef.close(success);
                        },
                        (error: HttpErrorResponse) => {
                            this.loadingService.stopLoading('contentCreationFooter', 'saveContent');
                        }
                    )
                );
            } else {
                this.subscriptions.add(
                    this.libraryService
                        .createActivity(body, this.file, this.guide, this.visuel, this.h5pContent)
                        .subscribe(
                            (success) => {
                                this.flashMessageService.flash(`L'activité a été créée`);
                                this.libraryService.emitAddedDraft(success);
                                this.loadingService.stopLoading(
                                    'contentCreationFooter',
                                    'saveContent'
                                );
                                this.closeDialog();
                            },
                            (error: HttpErrorResponse) => {
                                this.loadingService.stopLoading(
                                    'contentCreationFooter',
                                    'saveContent'
                                );
                            }
                        )
                );
            }
        }
    }

    isLoading(view: string): boolean {
        return this.loadingService.isLoading(view);
    }

    canShowCertificate() {
        return (
            this.loginService.getUser().roles.nationalAdmin ||
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.internalTeacher
        );
    }

    getDomainsDropDownLabel() {
        if (this.domains.filter((e) => e.selected).length === 1) {
            return this.domains.find((e) => e.selected).title;
        } else {
            return 'Domaines';
        }
    }

    getMaxFileSize() {
        if (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.nationalAdmin
        ) {
            return this.configService.getConfig().maxFileSizeAdmin;
        } else {
            return this.configService.getConfig().maxFileSize;
        }
    }

    closeDialogWithoutUpdate() {
        this.exitWithoutSaving = true;
        this.closeDialog();
    }

    closeDialog(value?: boolean) {
        this.dialogRef.close(value);
    }
}
