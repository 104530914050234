// Internal dependencies
import { Component, input, model, OnInit } from '@angular/core';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components

// Services
import { ConfigService } from '@/services/config.service';
import { UserService } from '@/services/user.service';
import { DialogService } from '@/services/dialog.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { AssignmentService } from '@/services/assignment.service';

// Interfaces
import { Assignment } from '@/structures/assignment';
import { UserTracking } from '@/structures/user-tracking';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-user-tracking',
    templateUrl: './user-tracking.component.html',
    styleUrls: ['./user-tracking.component.scss'],
    imports: [SharedModule]
})
export class UserTrackingComponent implements OnInit {
    userTracking = model<UserTracking>();
    assignment = input<Assignment>();
    isBeingNoted = false;
    score: number;

    constructor(
        private userService: UserService,
        private configService: ConfigService,
        private dialogService: DialogService,
        private assignmentService: AssignmentService,
        private flashMessageService: FlashMessageService
    ) {}

    ngOnInit() {
        if (this.userTracking().score) {
            this.score = this.userTracking().score;
        }
    }

    // #region Getters

    displayIcon(key: string) {
        if (key === 'HideAssignment') {
            return !this.userTracking().hidden && !this.isUserAssignment();
        } else if (key === 'ShowAssignment') {
            return this.userTracking().hidden && !this.isUserAssignment();
        } else if (key === 'SendReminder') {
            return !this.userTracking().submitted_file;
        } else if (key === 'ValidateAssignment') {
            return this.assignment().forceStatus && !this.userTracking().forcedStatus;
        } else if (key === 'InvalidateAssignment') {
            return this.assignment().forceStatus && this.userTracking().forcedStatus;
        } else if (key === 'ViewUserTestsReporting') {
            return !this.assignment().type;
        } else if (key === 'ResetTracking') {
            return (
                !this.userTracking().forcedStatus &&
                this.userTracking().status !== 'not attempted' &&
                (this.assignment().type === 'quiz' ||
                    this.assignment().type === 'scorm' ||
                    (this.assignment().type === 'devoir' &&
                        !(this.isAnonymous() && this.userTracking().score)))
            );
        } else if (key === 'UploadDevoirCorrectionUser') {
            return (
                this.userTracking().submitted_file &&
                (this.userTracking().score || this.userTracking().temporaryScore)
            );
        } else if (key === 'StartReview') {
            return (
                this.assignment().type === 'quiz' &&
                ((this.userTracking().attempt === 1 && this.userTracking().status !== 'opened') ||
                    this.userTracking().attempt > 1)
            );
        } else if (key === 'GradeUser') {
            return (
                this.assignment().type === 'presentiel' ||
                (this.assignment().type === 'devoir' &&
                    this.userTracking().submitted_file &&
                    !(this.isAnonymous() && this.userTracking().score))
            );
        } else if (key === 'ViewDevoir') {
            return (
                this.assignment().type === 'devoir' &&
                this.userTracking().status === 'completed' &&
                this.userTracking().submitted_file
            );
        }
    }

    getTooltipCorrectionUser(): string {
        if (this.userTracking().correction_file) {
            return 'Voir le devoir corrigé';
        } else {
            return 'Déposer le devoir corrigé';
        }
    }

    isAnonymous(): boolean {
        return this.assignment().is_anonymous;
    }

    isUserAssignment(): boolean {
        return !this.assignment().users;
    }

    getDateToShow(): string {
        switch (this.assignment().type) {
            case undefined: {
                if (this.userTracking().lastAccess) {
                    return (
                        'Dernier accès le ' +
                        new Date(this.userTracking().lastAccess).toLocaleDateString()
                    );
                }
                break;
            }
            case 'presentiel': {
                if (this.userTracking().notation_date) {
                    return (
                        'Noté le ' +
                        new Date(this.userTracking().notation_date).toLocaleDateString()
                    );
                }
                break;
            }
            case 'devoir': {
                if (this.userTracking().submission_time) {
                    return (
                        'Rendu le ' +
                        new Date(this.userTracking().submission_time).toLocaleDateString()
                    );
                }
                break;
            }
            case 'quiz': {
                if (this.userTracking().lastAccess) {
                    return (
                        'Dernière tentative le ' +
                        new Date(this.userTracking().lastAccess).toLocaleDateString()
                    );
                }
                break;
            }
            default: {
                if (this.userTracking().lastAccess) {
                    return new Date(this.userTracking().lastAccess).toLocaleDateString();
                }
                break;
            }
        }
    }

    getTooltipTracking(): string {
        const status = this.userTracking().forcedStatus
            ? this.userTracking().forcedStatus
            : this.userTracking().status;

        let tooltip = '';

        switch (status) {
            case 'not attempted':
                tooltip += 'Nouveau';
                break;
            case 'opened':
                tooltip += 'En cours';
                break;
            case 'completed':
                tooltip += 'Terminé';
                break;
            case 'failed':
                tooltip += 'Non réussi';
                break;
            case 'passed':
                tooltip += 'Réussi';
                break;
            default:
                tooltip += 'Nouveau';
                break;
        }
        if (this.userTracking().forcedStatus) {
            tooltip += ' (forcé par le formateur)';
        }
        if (this.userTracking().attempt) {
            tooltip += '<br>' + this.userTracking().attempt + ' tentative(s)';
        }
        return tooltip;
    }

    getIconTracking(): Array<string> {
        const status = this.userTracking().forcedStatus
            ? this.userTracking().forcedStatus
            : this.userTracking().status;

        let classList = [];

        switch (status) {
            case 'not attempted':
                classList = ['icon-select'];
                break;
            case 'opened':
                classList = ['icon-Progression40'];
                break;
            case 'completed':
                classList = ['icon-online'];
                break;
            case 'failed':
                classList = ['icon-Fermerdetails'];
                break;
            case 'passed':
                classList = ['icon-Valide'];
                break;
            default:
                classList = ['icon-select'];
                break;
        }
        if (this.userTracking().forcedStatus) {
            classList.push('orange');
        }
        return classList;
    }

    getDateTooltip(): string | null {
        if (
            this.assignment().type !== 'presentiel' &&
            this.assignment().type !== 'devoir' &&
            this.assignment().type !== 'quiz'
        ) {
            return `Nombre totale de consultations : ${this.userTracking().consultations}`;
        } else {
            return null;
        }
    }

    getQuizTooltip() {
        let tooltip = '';
        tooltip += `Nombre totale de tentative : ${this.userTracking().notes.length}<br><br>Historique des notes<br>`;
        for (const note of this.userTracking().notes) {
            tooltip += `${new Date(note.date).toLocaleDateString()} - ${note.score}/100<br>`;
        }
        return tooltip;
    }

    // #endregion Getters

    // #region Handlers

    handleClickShowAssignment(): void {
        this.userService.showUserAssignment(this.userTracking().assignmentUserId).subscribe(() => {
            this.userTracking.set({ ...this.userTracking(), hidden: false });
            this.flashMessageService.flash("L'assignation a été réassignée.");
        });
    }

    handleClickHideAssignment(): void {
        this.userService.hideUserAssignment(this.userTracking().assignmentUserId).subscribe(() => {
            this.userTracking.set({ ...this.userTracking(), hidden: true });
            this.flashMessageService.flash("L'assignation a été désassignée.");
        });
    }

    handleClickSendReminder(): void {
        this.assignmentService
            .remindAssignment({
                enrolmentSource: 'individual',
                assignmentId: this.userTracking().assignmentUserId
            })
            .subscribe(() => {
                this.flashMessageService.flash('Email envoyé');
            });
    }

    handleClickValidateAssignment() {
        if (this.assignment().level && !this.userTracking().forcedStatus) {
            const dialog = {
                title: 'Forcer la validation',
                body: "Par défaut le calcul du statut des assemblages est automatique.<br />Si vous décidez de forcer la validation ou l'invalidation, l'automatisme sera définitivement désactivé pour cet assemblage.<br />Êtes-vous sûr de vouloir forcer le changement de statut de cet élément ?"
            };

            this.dialogService
                .openConfirmationDialog(dialog.title, dialog.body)
                .subscribe((valid: boolean) => {
                    if (valid) {
                        this.userService
                            .validateContentForUser(
                                this.userTracking().userid,
                                this.assignment().id
                            )
                            .subscribe(() => {
                                this.userTracking.set({
                                    ...this.userTracking(),
                                    forcedStatus: 'completed'
                                });
                                this.flashMessageService.flash(
                                    'Le contenu <b>' + this.assignment().title + '</b> a été validé'
                                );
                            });
                    }
                });
        } else {
            this.userService
                .validateContentForUser(this.userTracking().userid, this.assignment().id)
                .subscribe(() => {
                    this.userTracking.set({ ...this.userTracking(), forcedStatus: 'completed' });
                    this.flashMessageService.flash(
                        'Le contenu <b>' + this.assignment().title + '</b> a été validé'
                    );
                });
        }
    }

    handleClickInvalidateAssignment() {
        this.userService
            .unvalidateContentForUser(this.userTracking().userid, this.assignment().id)
            .subscribe(() => {
                this.userTracking.set({
                    ...this.userTracking(),
                    forcedStatus: null
                });

                this.flashMessageService.flash(
                    'Le contenu <b>' + this.assignment().title + '</b> a été invalidé'
                );
            });
    }

    handleClickViewUserTestsReporting(): void {
        window.open(
            this.configService.getReportingFrontEndpoint() +
                '#/?studentId=' +
                this.userTracking().userid +
                '&select=test&id=' +
                this.getQuizsContentFromAssignments(this.assignment().children).join('|') +
                '&name=' +
                this.assignment().title,
            '_blank'
        );
    }

    handleClickViewUserTestReporting(): void {
        window.open(
            this.configService.getReportingFrontEndpoint() +
                '#/?studentId=' +
                this.userTracking().userid +
                '&select=test&id=' +
                this.assignment().quizId +
                '&name=' +
                this.assignment().title,
            '_blank'
        );
    }

    handleClickViewDevoir(): void {
        window.open(this.userTracking().submitted_file, '_blank');
    }

    handleClickResetTracking(): void {
        const dialog = {
            title:
                this.assignment().type === 'devoir'
                    ? 'Proposer un nouveau dépot'
                    : 'Proposer une nouvelle tentative',
            body:
                this.assignment().type === 'devoir'
                    ? "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité sera réinitialisé<br>Le devoir rendu précédemment ainsi que la note seront supprimés définitivement<br><br><b>Continuer</b> ?"
                    : this.assignment().type === 'quiz'
                      ? "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité et la copie de l'apprenant seront réinitialisés.<br>Les résultats des anciennes tentatives seront conservés et visibles dans le rapport de test.<br><br><b>Continuer</b>"
                      : this.assignment().type === 'scorm'
                        ? "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité sera réinitialisé.<br>Le score de la tentative précédente ne sera pas conservée.<br>Seul le nombre de tentatives sera mis à jour dans le rapport de l'apprenant (.csv)<br><br><b>Continuer</b> ?"
                        : "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité sera réinitialisé.<br><br><b>Continuer</b> ?"
        };

        this.dialogService
            .openConfirmationDialog(dialog.title, dialog.body)
            .subscribe((valid: boolean) => {
                if (valid) {
                    this.userService
                        .removeTracking(this.userTracking().userid, this.assignment())
                        .subscribe(() => {
                            this.flashMessageService.flash(
                                'Le contenu <b>' +
                                    this.assignment().title +
                                    '</b> a été réinitialisé'
                            );
                            this.userTracking.set({
                                ...this.userTracking(),
                                status: 'not attempted',
                                score: null,
                                temporaryScore: null,
                                submitted_file: null
                            });
                        });
                }
            });
    }

    handleClickUploadDevoirCorrectionUser() {
        if (this.userTracking().correction_file) {
            window.open(this.userTracking().correction_file, '_blank');
        } else {
            this.dialogService
                .openUploadDevoirCorrectionDialog({
                    assignment: this.assignment(),
                    user: this.userTracking()
                })
                .subscribe((uploadResponse: any) => {
                    if (uploadResponse.correction_file) {
                        this.userTracking.set({
                            ...this.userTracking(),
                            correction_file: uploadResponse.correction_file
                        });
                    }
                });
        }
    }

    handleClickStartReview() {
        window.open(
            `${this.configService.getFrontEndPoint()}/quizPlayer/#/${
                this.assignment().id
            }?teacher=true&studentId=${this.userTracking().userid}`,
            '_blank'
        );
    }

    handleClickGradeUser() {
        this.isBeingNoted = true;
    }

    postNoteDevoir() {
        this.userService
            .noteDevoirTemporary(
                this.userTracking().userid,
                this.assignment().id,
                this.score,
                this.userTracking().graded
            )
            .subscribe(() => {
                this.isBeingNoted = false;
                this.userTracking.set({
                    ...this.userTracking(),
                    temporaryScore: this.score,
                    score: this.userTracking().graded ? this.score : undefined
                });
            });
    }

    postNote(passed: boolean): void {
        this.userService
            .noteContent(
                this.userTracking().userid,
                this.assignment().id,
                this.userTracking().score,
                passed
            )
            .subscribe(() => {
                this.flashMessageService.flash(
                    'Le contenu <strong>' + this.assignment().title + '</strong> a été noté'
                );
                this.userTracking.set({
                    ...this.userTracking(),
                    status: passed ? 'passed' : 'failed',
                    score: this.score
                });
                this.isBeingNoted = false;
            });
    }

    // #endregion Handlers

    // #region Internals

    private getQuizsContentFromAssignments(assignments): string[] {
        let quizList: string[] = [];
        let found: boolean;
        for (const i in assignments) {
            if (assignments[i].type === 'quiz') {
                found = false;
                for (const j in quizList) {
                    if (quizList[j] === assignments[i].quizId) {
                        found = true;
                    }
                }
                if (!found) {
                    quizList.push(assignments[i].quizId);
                }
            } else if (assignments[i].children) {
                quizList = quizList.concat(
                    this.getQuizsContentFromAssignments(assignments[i].children)
                );
            }
        }
        return quizList;
    }

    // #endregion Internals
}
