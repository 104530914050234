// Internal dependencies
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components

// Services
import { AdminService } from '@/services/admin.service';
import { LoadingService } from '@/services/loading.service';

// Interfaces

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-create-domain',
    templateUrl: './create-domain.component.html',
    styleUrls: ['./create-domain.component.scss'],
    imports: [
        SharedModule,
        MatFormFieldModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule
    ]
})
export class CreateDomainComponent implements OnInit {
    domain: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<CreateDomainComponent>,
        private adminService: AdminService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        if (this.data) {
            this.domain = this.data.name;
        }
    }

    // #region Getters

    getTitle() {
        if (!this.data) {
            return 'Créer un domaine';
        } else {
            return 'Modifier un domaine';
        }
    }

    getFooter() {
        if (!this.data) {
            return 'Créer';
        } else {
            return 'Modifier';
        }
    }

    isLoading() {
        return this.loadingService.isLoading('CreateDomainComponent', 'createDomain');
    }

    // #endregion Getters

    // #region Handlers

    handleClickDomain() {
        this.loadingService.startLoading('CreateDomainComponent', 'createDomain');
        let action = this.data
            ? this.adminService.updateDomain(this.data.name, this.domain)
            : this.adminService.createDomain(this.domain);
        action.subscribe(
            () => {
                this.loadingService.stopLoading('CreateDomainComponent', 'createDomain');
                this.closeDialog(this.domain);
            },
            () => {
                this.loadingService.stopLoading('CreateDomainComponent', 'createDomain');
            }
        );
    }

    handleCloseDialog() {
        this.closeDialog();
    }

    // #endregion Handlers

    // #region Internals

    private closeDialog(success?: string) {
        this.dialogRef.close(success);
    }

    // #endregion Internals
}
