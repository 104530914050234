/**
 * Interface décrivant un utilisateur
 */
export interface Content {
    id?: number;
    timemodified?: string;
    timecreated?: string;
    favorite?: boolean;
    level?: string;
    disabledLevel?: Array<string>;
    contentlevel?: string;
    category?: string;
    hasprice?: number;
    path?: string;
    localStructure?: any;
    author?: number;
    type?: string;
    media_type?: string;
    copyright?: string;
    enabled?: boolean;
    hidden?: boolean;
    status?: string;
    basedOn?: number;
    title?: string;
    children?: Array<any>;
    child?: any;
    downloadUrl?: string;
    assignable?: true;
    file?: any;
    ucode?: string;
    description?: string;
    ordered?: boolean;
    orderNumber?: number;
    version?: number;
    quizId?: number;
    guide?: string;
    is_copy?: boolean;
    duration?: string;
    content_duration?: string;
    structureid?: number;
    domains?: Array<any>;
    keywords?: any;
    provider?: string;
    price?: number;
    pricetxt?: string;
    taxonomy?: string;
    sites?: Array<any>;
    incomplete_duration?: boolean;
    position?: number;
    parentid?: number;
    isQuizPublishable?: boolean;
    rejected?: boolean;
    publicationMode?: string;
    url?: string;
    lti_url?: string;
    lti_key?: string;
    lti_params?: string;
    lti_resourcekey?: string;
    code_ressource?: string;
    code_uai?: string;
    forceStatus?: boolean;
    is_training_of_teachers?: boolean;
    tableOfContents?: boolean;
    mark_as_completed_on_open?: boolean;
    removeguide?: boolean;
    waitingForMyApproval?: boolean;
    desiredPublicationMode?: 'new' | 'update';
    moved?: boolean;
    urgent?: boolean;
    report?: ReportData;
    note_avg?: string;
    note_count?: number;
    my_note?: 1 | 2 | 3 | 4 | 5;
    my_note_timemodified?: Date;
    original_url?: string;
    comment?: string;
    comment_date?: any;
    visible_for_all?: boolean;
    newCertificate?: boolean;

    archived?: boolean;
    has_new_version?: boolean;
    hasCertificate?: boolean;

    visuel?: string;
    forced_title?: string;
    forced_duration?: string;
    information?: string;
    new?: boolean;
    visible?: boolean;
    removevisuel?: boolean;
    forced_stripe?: boolean;
    forced_stripe_text?: string;
    forced_stripe_color?: string;

    data?: string;
    publishable?: boolean;
    with_publish_option?: boolean;

    h5p_type?: string;
    h5p_name?: string;
    fileid?: number;
    filename?: string;

    locked?: boolean;

    edit_path?: string;
    editingOrder?: boolean;
}

export interface ContentForImport extends Omit<Content, 'domains' | 'sites'> {
    index: number;
    typeImport: string;
    created: boolean;
    published: boolean;
    invalid: boolean;
    validator: Array<{ id: number }>;
    errorMessage: string;
    domains: string | Array<string>;
    sites: string | Array<string>;
    ucodes_children: string;
}

export interface ReportData {
    id: number;
    status: ReportStatus;
    timecreated: Date;
    author: {
        id: number;
        lastname: string;
        firstname: string;
    };
    message?: string;
    fileURL: string;
    unseen_new_status: boolean;
}

export enum ReportStatus {
    created = 'created',
    canceled = 'canceled',
    closed = 'closed',
    inprogress = 'inprogress'
}
