<div class="header">
    <div class="icon-easi-media-line"></div>
    <div class="label">{{ getTitle() }}</div>
    <button mat-icon-button (click)="handleCloseDialog(false)">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="content">
    <mat-form-field>
        <mat-label>{{ getLabel() }}</mat-label>
        <input fullWidth="true" matInput type="text" [(ngModel)]="activityName" name="activity-name" />
    </mat-form-field>
</mat-dialog-content>
<div class="footer">
    <button class="button" mat-button (click)="handleCloseDialog(true)">
        @if (isLoading()) {
            <app-loading></app-loading>
        } @else {
            Créer
        }
    </button>
</div>
