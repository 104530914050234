<div class="header">
    <div class="icon-creer-assemblage"></div>
    <div class="label">{{ getTitle() }}</div>
    <button mat-icon-button (click)="handleCloseDialog()">
        @if (!isImportStarted()) {
            <mat-icon>close</mat-icon>
        }
    </button>
</div>
<mat-dialog-content class="content" *ngIf="!isLoading('massImportMainView')">
    @if (!isLoading('Init')) {
        <div class="import">
            <div class="label">Fichier CSV</div>
            <div class="value" appFileDropzone (fileDropped)="handleUploadFile($event)">
                {{ getCurrentFileLabel() }}
            </div>
            <div
                class="icon-Importer"
                (click)="handleClickSelectFile()"
                [tooltip]="'Sélectionner un fichier CSV'"
            ></div>
            <input
                class="hidden"
                type="file"
                #fileInput
                (change)="handleUploadFile($event.target.files)"
            />
        </div>
        @if (file) {
            <div class="content-header">
                <span class="content-header-element default-column">Code</span>
                <span class="content-header-element default-column">Nom</span>
                @if (isActivityImport()) {
                    <span class="content-header-element small-column">Type</span>
                } @else {
                    <span class="content-header-element small-column">Niveau</span>
                }
                <span class="content-header-element small-column">Créé</span>
                <span class="content-header-element small-column">Publié</span>
                <span class="content-header-element large-column">Commentaire</span>
            </div>
            <div class="content-body">
                @if (!isLoading('CheckImport')) {
                    @for (content of contents; track content.title; let i = $index) {
                        <app-mass-import-entry
                            [(content)]="contents[i]"
                            [importType]="data.importType"
                            [ucodes]="ucodes"
                            [(ucodesChildren)]="ucodesChildren"
                            (contentImported)="handleContentImported($event)"
                        ></app-mass-import-entry>
                    }
                } @else {
                    <app-loading></app-loading>
                }
            </div>
        }
    } @else {
        <app-loading></app-loading>
    }
</mat-dialog-content>
<div class="footer">
    @if (!isImportFinished()) {
        <button mat-button [disabled]="!file" (click)="handleStartImport()">
            @if (!isImportStarted()) {
                Lancer l'import
            } @else if (isLoading('StartImport')) {
                <app-loading></app-loading>
            }
        </button>
    } @else {
        <button mat-button [disabled]="!file" (click)="handleCloseDialog()">Fermer</button>
    }
</div>
