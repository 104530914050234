@if (!isLoading()) {
    <div class="providers-container">
        <div>
            <b>{{ providers.length }}</b> fournisseurs
        </div>
        <div class="scroll-container">
            @for (provider of providers; track provider.name) {
                <div class="provider-entry">
                    <div class="icon-JustificationGauche"></div>
                    <div class="label">{{ provider.name }}</div>
                    <div class="count">
                        ( <b>{{ provider.count.parcours }}</b> parcours,
                        <b>{{ provider.count.bloc }}</b> blocs,
                        <b>{{ provider.count.competence }}</b> compétences,
                        <b>{{ provider.count.module }}</b> modules,
                        <b>{{ provider.count.sequence }}</b> séquences et
                        <b>{{ provider.count.activity }}</b> activités)
                    </div>
                </div>
            }
        </div>
    </div>

    <button mat-fab [tooltip]="'Créer un nouveau fournisseur'" (click)="openProviderDialog()">
        <mat-icon>add</mat-icon>
    </button>
} @else {
    <app-loading></app-loading>
}
