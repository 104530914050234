// Internal dependencies
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

// External dependencies

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components

// Services
import { LibraryService } from '@/services/library.service';
import { LoadingService } from '@/services/loading.service';
import { FlashMessageService } from '@/services/flash-message.service';

// Interfaces
import { Content } from '@/structures/content';

// Pipes

@Component({
    selector: 'app-create-h5p',
    templateUrl: './create-h5p.component.html',
    styleUrls: ['./create-h5p.component.scss'],
    imports: [
        SharedModule,
        MatFormFieldModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule
    ]
})
export class CreateH5PComponent {
    activityName: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: Content,
        private dialogRef: MatDialogRef<CreateH5PComponent>,
        private libraryService: LibraryService,
        private loadingService: LoadingService,
        private flashMessageService: FlashMessageService
    ) {}

    // #region Getters

    getTitle(): string {
        if (this.data.type === 'quiz') {
            return 'Créer une activité Test';
        } else {
            return 'Créer une activité formative';
        }
    }

    getLabel(): string {
        if (this.data.type === 'quiz') {
            return `Nom de l'activité Test`;
        } else {
            return `Nom de l'activité formative`;
        }
    }

    isLoading() {
        return this.loadingService.isLoading('CreateH5PComponent');
    }

    // #endregion Getters

    // #region Handlers

    handleCloseDialog(confirmed: boolean) {
        if (confirmed) {
            this.loadingService.startLoading('CreateH5PComponent', 'handleCloseDialog');

            this.libraryService
                .createActivity({
                    ...this.data,
                    title: this.activityName,
                    category: this.data.type === 'quiz' ? 'evaluation' : 'ressource'
                })
                .subscribe(
                    (success: Content) => {
                        this.flashMessageService.flash(`L'activité a été créée`);
                        this.libraryService.emitAddedDraft(success);
                        this.libraryService.openActivity(success, true);
                        this.loadingService.stopLoading('CreateH5PComponent', 'handleCloseDialog');
                        this.closeDialog(success);
                    },
                    () => {
                        this.loadingService.stopLoading('CreateH5PComponent', 'handleCloseDialog');
                    }
                );
        } else {
            this.closeDialog();
        }
    }

    // #endregion Handlers

    // #region Internals

    private closeDialog(success?: Content) {
        this.dialogRef.close(success);
    }

    // #endregion Internals
}
