<div class="header">
    <span class="icon-ajouter-contenus"></span>
    <div class="title">{{ getTitle() }}</div>
    <span class="icon-close" (click)="closeDialogWithoutUpdate()"></span>
</div>
<div class="content">
    @if (!isLoading('contentCreationBody')) {
        <app-extra-field
            [(content)]="content"
            [(file)]="file"
            [(h5pFile)]="h5pContent"
            (showError)="displayError($event)"
        ></app-extra-field>
        <app-mandatory-field [(content)]="content"></app-mandatory-field>
        <app-facultative-field
            [(content)]="content"
            [(guide)]="guide"
            (showError)="displayError($event)"
        ></app-facultative-field>
        <app-catalog-field
            [(content)]="content"
            [(visuel)]="visuel"
            (showError)="displayError($event)"
        ></app-catalog-field>
    } @else {
        <app-loading></app-loading>
    }
</div>
<div class="footer">
    @if (isLoading('contentCreationFooter')) {
        <div class="button">
            <app-loading></app-loading>
        </div>
    } @else {
        <div
            class="button"
            [ngClass]="{
                disabled:
                    (content.with_publish_option && !canPublishContent()) ||
                    (!content.with_publish_option && !canSaveContent())
            }"
            (click)="saveContent()"
        >
            {{ getFooterLabel() }}
        </div>
    }
</div>
