// Internal dependencies
import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { FlashMessageService } from '@/services/flash-message.service';
import { LoadingService } from '@/services/loading.service';
import { LoginService } from '@/services/login.service';
import { LibraryService } from '@/services/library.service';

// Interfaces
import { Content } from '@/structures/content';

// Pipes
import { ExtraFieldComponent } from './extra-field/extra-field.component';
import { CatalogFieldComponent } from './catalog-field/catalog-field.component';
import { MandatoryFieldComponent } from './mandatory-field/mandatory-field.component';
import { FacultativeFieldComponent } from './facultative-field/facultative-field.component';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-content-creation',
    templateUrl: './content-creation.component.html',
    styleUrls: ['./content-creation.component.scss'],
    imports: [
        SharedModule,
        MatDialogModule,
        MatExpansionModule,
        ExtraFieldComponent,
        CatalogFieldComponent,
        MandatoryFieldComponent,
        FacultativeFieldComponent
    ]
})
export class ContentCreationComponent implements OnInit {
    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    initialContent: Content;

    file: File;
    guide: File;
    visuel: File;
    h5pContent: File;

    constructor(
        @Inject(MAT_DIALOG_DATA) public content: Content,
        private dialogRef: MatDialogRef<ContentCreationComponent>,
        private flashMessageService: FlashMessageService,
        private loadingService: LoadingService,
        private loginService: LoginService,
        private libraryService: LibraryService
    ) {}

    ngOnInit() {
        this.initialContent = JSON.parse(JSON.stringify(this.content));
    }

    // #region Getters

    getTitle() {
        if (!this.content.type) {
            return "Fiche d'assemblage";
        } else if (this.content.type === 'presentiel') {
            return "Fiche d'activité (Sans contenu)";
        } else if (this.content.type === 'url') {
            return "Fiche d'activité (Lien web)";
        } else if (this.content.type === 'partenariat') {
            return "Fiche d'activité (Partenariat)";
        } else if (this.content.type === 'lti') {
            return "Fiche d'activité (LTI)";
        } else if (this.content.type === 'hachette' || this.content.type === 'nathan') {
            return "Fiche d'activité (Manuel numérique)";
        } else if (this.content.type === 'devoir') {
            return "Fiche d'activité (Remise de devoir)";
        } else if (this.content.type === 'n4s') {
            return "Fiche d'activité (Positionnement Num4Skills)";
        } else if (this.content.type === 'quiz') {
            return "Fiche d'activité (Test)";
        } else if (this.content.type === 'h5pactivity') {
            return `Fiche d'activité (${this.content.h5p_type})`;
        } else {
            return "Fiche d'activité (Import)";
        }
    }

    getFooterLabel() {
        if (this.content.with_publish_option) {
            if (
                this.loginService.getUser().additionalRoles.validator ||
                this.loginService.getUser().roles.localAdmin ||
                this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.nationalTeacher ||
                this.loginService.getUser().roles.siteTeacher
            ) {
                return 'Publier';
            } else {
                return 'Envoyer en validation';
            }
        } else {
            return 'Enregistrer';
        }
    }

    canSaveContent() {
        if (this.content.title.trim() === '') {
            return false;
        }

        if (!this.content.level && this.content.category.trim() === '') {
            return false;
        }

        if (this.content.type === 'h5pactivity' && !this.h5pContent && !this.content.h5p_type) {
            return false;
        }

        return true;
    }

    canPublishContent() {
        if (this.content.title.trim() === '') {
            return false;
        }
        if (!this.content.level && this.content.category.trim() === '') {
            return false;
        }
        if (this.content.description.trim() === '') {
            return false;
        }
        if (this.content.keywords.trim() === '') {
            return false;
        }
        if (this.content.copyright.trim() === '') {
            return false;
        }
        if (this.content.domains.length === 0) {
            return false;
        }
        if (!this.content.level && !this.isValidDuration()) {
            return false;
        }
        if (this.content.hasprice === 1 && this.content.price === 0) {
            return false;
        }
        if (this.content.hasprice === 3 && !this.content.pricetxt) {
            return false;
        }

        if (this.showFileContainer()) {
            return this.file || this.content.path;
        }
        return true;
    }

    isLoading(view: string): boolean {
        return this.loadingService.isLoading(view);
    }

    // #endregion Getters

    // #region Handlers

    displayError($event: string) {
        this.openErrorDialog.next($event);
    }

    saveContent() {
        if (
            (!this.content.with_publish_option && !this.canSaveContent()) ||
            (this.content.with_publish_option && !this.canPublishContent())
        ) {
            return;
        }

        const body: Content = {
            ...this.content,
            keywords: this.content.keywords
                .split(';')
                .map((keyword) => keyword.trim())
                .filter((keyword) => !!keyword),
            newCertificate:
                !this.initialContent.hasCertificate && this.content.hasCertificate ? true : null
        };

        this.loadingService.startLoading('contentCreationFooter', 'saveContent');
        if (body.id) {
            if (body.level) {
                this.libraryService.updateAssembly(body, this.guide, this.visuel).subscribe(
                    (content: Content) => {
                        this.libraryService.emitUpdatedDraft(content);
                        this.flashMessageService.flash(`L'assemblage a été mis à jour`);

                        this.loadingService.stopLoading('contentCreationFooter', 'saveContent');
                        this.dialogRef.close(content);
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('contentCreationFooter', 'saveContent');
                    }
                );
            } else {
                this.libraryService
                    .updateActivity(body, this.file, this.guide, this.visuel)
                    .subscribe(
                        (content: Content) => {
                            this.libraryService.emitUpdatedDraft(content);
                            /**
                             * Hack pas très propre mais qui corrige rapidement le pb #282498
                             * Trouver une solution plus propre.
                             */
                            if (this.file || this.guide) {
                                this.libraryService
                                    .getContent(body.id, false)
                                    .subscribe((resp: any) => {
                                        this.libraryService.emitUpdatedDraft(resp);
                                    });
                            }
                            this.flashMessageService.flash(`L'activité a été mise à jour`);

                            this.loadingService.stopLoading('contentCreationFooter', 'saveContent');
                            this.closeDialog(content);
                        },
                        (error: HttpErrorResponse) => {
                            this.loadingService.stopLoading('contentCreationFooter', 'saveContent');
                        }
                    );
            }
        } else {
            if (body.level) {
                this.libraryService.createAssembly(body, this.guide, this.visuel).subscribe(
                    (success: any) => {
                        this.flashMessageService.flash(`L'assemblage a été créé`);
                        if (body.parentid) {
                            success.parentid = body.parentid;
                        } else {
                            this.libraryService.emitAddedDraft(success);
                        }
                        this.loadingService.stopLoading('contentCreationFooter', 'saveContent');
                        this.dialogRef.close(success);
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('contentCreationFooter', 'saveContent');
                    }
                );
            } else {
                this.libraryService
                    .createActivity(body, this.file, this.guide, this.visuel, this.h5pContent)
                    .subscribe(
                        (success) => {
                            this.flashMessageService.flash(`L'activité a été créée`);
                            this.libraryService.emitAddedDraft(success);
                            this.loadingService.stopLoading('contentCreationFooter', 'saveContent');
                            this.closeDialog();
                        },
                        (error: HttpErrorResponse) => {
                            this.loadingService.stopLoading('contentCreationFooter', 'saveContent');
                        }
                    );
            }
        }
    }

    closeDialogWithoutUpdate() {
        this.closeDialog();
        Object.assign(this.content, this.initialContent);
    }

    closeDialog(value?: Content) {
        this.dialogRef.close(value);
    }

    // #endregion Handlers

    // #region Internals

    showFileContainer() {
        return (
            ['devoir', 'pdf', 'html', 'fichier', 'scorm', 'video', 'office', '3D'].indexOf(
                this.content.type
            ) > -1
        );
    }

    isValidDuration() {
        if (this.content.duration) {
            return (
                this.content.duration.match(/^[0-9]?[0-9]?[0-9]?[0-9]:[0-5][0-9]$/g) &&
                this.content.duration !== '00:00'
            );
        }
    }

    // #endregion Internals
}
