<div class="learner" [ngClass]="{ anonymous: isAnonymous(), hidden: userTracking().hidden }">
    <div class="learner-wrapper">
        <div [ngClass]="isAnonymous() ? 'icon-logAs' : 'icon-apprenant'"></div>
        <div class="learner-name">
            {{ userTracking().lastname.toUpperCase() }} {{ userTracking().firstname }}
        </div>
    </div>

    <div class="upload-date">
        <span [tooltip]="getDateTooltip()">
            {{ getDateToShow() }}
        </span>
    </div>

    <div class="icons">
        @if (displayIcon('HideAssignment')) {
            <div
                class="icon-desassigne"
                tooltip="Désassigner"
                (click)="handleClickHideAssignment()"
            ></div>
        }
        @if (displayIcon('ShowAssignment')) {
            <div
                class="icon-assigner"
                [tooltip]="'Réassigner'"
                (click)="handleClickShowAssignment()"
            ></div>
        }
        @if (displayIcon('SendReminder')) {
            <div
                class="icon-message"
                tooltip="Envoyer un rappel à l'apprenant"
                (click)="handleClickSendReminder()"
            ></div>
        }
        @if (displayIcon('ValidateAssignment')) {
            <div
                class="icon-Valide"
                tooltip="Forcer la validation"
                (click)="handleClickValidateAssignment()"
            ></div>
        }
        @if (displayIcon('InvalidateAssignment')) {
            <div
                class="icon-ForcerInvalidation"
                tooltip="Annuler : Forcer la validation"
                (click)="handleClickInvalidateAssignment()"
            ></div>
        }
        @if (displayIcon('ViewUserTestsReporting')) {
            <div
                class="icon-radar"
                tooltip="Voir le rapport des tests de l'utilisateur"
                (click)="handleClickViewUserTestsReporting()"
            ></div>
        }

        @if (displayIcon('ViewUserTestReporting')) {
            <div
                class="icon-radar"
                tooltip="Voir le rapport du test de l'apprenant"
                (click)="handleClickViewUserTestReporting()"
            ></div>
        }

        @if (displayIcon('ResetTracking')) {
            <div
                class="icon-rafraichir"
                (click)="handleClickResetTracking()"
                tooltip="Proposer une nouvelle tentative"
            ></div>
        }
        @if (displayIcon('UploadDevoirCorrectionUser')) {
            <span
                class="icon-Miseenapplcation"
                [tooltip]="getTooltipCorrectionUser()"
                (click)="handleClickUploadDevoirCorrectionUser()"
            ></span>
        }
        @if (displayIcon('StartReview')) {
            <div
                class="icon-Miseenapplcation"
                (click)="handleClickStartReview()"
                [tooltip]="'Voir la copie de l\'apprenant'"
            ></div>
        }
        @if (displayIcon('GradeUser')) {
            <div
                class="icon-Corrige"
                [tooltip]="
                    assignment().type === 'devoir' ? 'Noter le devoir' : 'Noter le TP (présentiel)'
                "
                (click)="handleClickGradeUser()"
            ></div>
        }
        @if (displayIcon('ViewDevoir')) {
            <div
                class="icon-devoir-actif"
                [tooltip]="'Voir le devoir'"
                (click)="handleClickViewDevoir()"
            ></div>
        }
    </div>

    <div class="tracking">
        @if (assignment().type === undefined) {
            <div class="score">({{ userTracking().completion }}%)</div>
        } @else if (assignment().type === 'scorm') {
            @if (userTracking().score) {
                <div class="score">({{ userTracking().score }}/100)</div>
            }
        } @else if (assignment().type === 'quiz') {
            <div class="score" [tooltip]="getQuizTooltip()">{{ userTracking().score }}/100</div>
        } @else if (assignment().type === 'devoir' || assignment().type === 'presentiel') {
            @if (isBeingNoted) {
                <div class="notation-input">
                    <input type="number" min="0" max="100" step="1" [(ngModel)]="score" />
                </div>
                @if (assignment().type === 'devoir') {
                    <div class="notation-button" (click)="postNoteDevoir()">Noter</div>
                } @else {
                    <div class="notation-button" (click)="postNote(true)">Réussi</div>
                    <div class="notation-button failed" (click)="postNote(false)">Non réussi</div>
                }
            } @else {
                @if (!userTracking().score && userTracking().temporaryScore) {
                    <div class="score temporary">{{ userTracking().temporaryScore }}/100</div>
                } @else if (userTracking().score !== null) {
                    <div class="score">{{ userTracking().score }}/100</div>
                }
            }
        }

        <div class="icon" [ngClass]="getIconTracking()" [tooltip]="getTooltipTracking()"></div>
    </div>
</div>
