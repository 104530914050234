// Internal dependencies
import { Component, OnInit, ViewChild, model, output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LoadingService } from '@/services/loading.service';
import { LoginService } from '@/services/login.service';
import { LibraryService } from '@/services/library.service';
import { ConfigService } from '@/services/config.service';

// Interfaces
import { Content } from '@/structures/content';
import { Provider } from '@/structures/provider';
import { Taxonomy } from '@/structures/taxonomy';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-facultative-field',
    templateUrl: './facultative-field.component.html',
    styleUrls: ['./facultative-field.component.scss'],
    imports: [MatExpansionModule, SharedModule]
})
export class FacultativeFieldComponent implements OnInit {
    content = model<Content>();
    guide = model<File>();
    showError = output<string>();

    @ViewChild('guideInput', { static: false }) guideInput;

    taxonomies: Array<Taxonomy>;
    providers: Array<Provider>;

    hasprice: Array<any>;
    forceStatus: Array<any>;
    trainingOfTeachers: Array<any>;
    hasCertificate: Array<any>;

    constructor(
        private loginService: LoginService,
        private configService: ConfigService,
        private loadingService: LoadingService,
        private libraryService: LibraryService
    ) {}

    ngOnInit() {
        this.loadingService.startLoading('FacultativeFieldComponent', 'getTaxonomies');
        this.libraryService.getTaxonomies().subscribe(
            (data: Array<Taxonomy>) => {
                this.taxonomies = data.map((taxonomy: Taxonomy) => {
                    return {
                        key: taxonomy.name,
                        title: taxonomy.name,
                        selected: this.content().taxonomy === taxonomy.name
                    };
                });
                this.loadingService.stopLoading('FacultativeFieldComponent', 'getTaxonomies');
            },
            () => {
                this.loadingService.stopLoading('FacultativeFieldComponent', 'getTaxonomies');
            }
        );
        this.loadingService.startLoading('FacultativeFieldComponent', 'getProviders');
        this.libraryService.getProviders().subscribe(
            (data: Array<Provider>) => {
                this.providers = data.map((provider: any) => {
                    return {
                        key: provider.name,
                        title: provider.name,
                        selected: this.content().provider === provider.name
                    };
                });
                this.loadingService.stopLoading('FacultativeFieldComponent', 'getProviders');
            },
            () => {
                this.loadingService.stopLoading('FacultativeFieldComponent', 'getProviders');
            }
        );

        this.hasprice = [
            {
                key: 0,
                title: 'Non',
                selected: this.content().hasprice === 0
            },
            {
                key: 1,
                title: 'Oui (Prix)',
                selected: this.content().hasprice === 1
            },
            {
                key: 2,
                title: 'Oui (A assigner via le contenu parent)',
                selected: this.content().hasprice === 2
            },
            {
                key: 3,
                title: 'Oui (infos)',
                selected: this.content().hasprice === 3
            }
        ];

        this.forceStatus = [
            {
                key: true,
                title: 'Oui',
                selected: this.content().forceStatus === true
            },
            {
                key: false,
                title: 'Non',
                selected: this.content().forceStatus === false
            }
        ];

        this.trainingOfTeachers = [
            {
                key: true,
                title: 'Oui',
                selected: this.content().is_training_of_teachers === true
            },
            {
                key: false,
                title: 'Non',
                selected: this.content().is_training_of_teachers === false
            }
        ];

        this.hasCertificate = [
            {
                key: true,
                title: 'Oui',
                selected: this.content().hasCertificate === true
            },
            {
                key: false,
                title: 'Non',
                selected: this.content().hasCertificate === false
            }
        ];
    }

    // #region Getters

    getCurrentGuideLabel() {
        if (this.content().guide || this.guide()) {
            let tmp: string;
            if (this.content().guide) {
                tmp = this.content().guide.split('/')[this.content().guide.split('/').length - 1];
            } else {
                tmp = this.guide().name;
            }
            if (tmp.length < 32) {
                return decodeURIComponent(tmp) + '';
            } else {
                const hash = tmp.substring(0, 32);
                const regex = RegExp('^[a-z0-9]+$', 'g');
                if (!regex.test(hash)) {
                    return `${decodeURIComponent(tmp)}`;
                } else {
                    return `${decodeURIComponent(tmp.substring(32))}`;
                }
            }
        } else {
            return 'Glisser-déposer votre guide ici';
        }
    }

    showPriceNumber() {
        if (this.hasprice) {
            return this.hasprice.filter((element) => element.key === 1)[0].selected;
        }
    }

    showPriceInfo() {
        if (this.hasprice) {
            return this.hasprice.filter((element) => element.key === 3)[0].selected;
        }
    }

    showPriceInput() {
        return this.showPriceNumber() || this.showPriceInfo();
    }

    showCertificate() {
        return (
            this.loginService.getUser().roles.nationalAdmin ||
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.internalTeacher
        );
    }

    isAssembly() {
        return !this.content().type;
    }

    isNationalAdmin() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    isAdmin() {
        return (
            this.loginService.getUser().roles.nationalAdmin ||
            this.loginService.getUser().roles.localAdmin
        );
    }

    isLoading(view: string): boolean {
        return this.loadingService.isLoading(view);
    }

    // #endregion Getters

    // #region Handlers

    openSelectGuide() {
        this.guideInput.nativeElement.click();
    }

    uploadGuide(files: FileList) {
        if (files.item(0).size > this.getMaxFileSize()) {
            this.showError.emit('Votre fichier doit être inférieur à 200 Mo');
            return;
        }
        this.guide.set(files.item(0));
        this.content().removeguide = false;
    }

    deleteGuide() {
        this.content().guide = undefined;
        this.guide.set(undefined);
        this.content().removeguide = true;
    }

    handleTaxonomyChange() {
        const selectedTaxonomy = this.taxonomies.find((taxonomy: Taxonomy) => taxonomy.selected);
        this.content.set({
            ...this.content(),
            taxonomy: selectedTaxonomy ? selectedTaxonomy.key : ''
        });
    }

    handleProvidersChange() {
        const selectedProvider = this.providers.find((provider: Provider) => provider.selected);
        this.content.set({
            ...this.content(),
            provider: selectedProvider ? selectedProvider.key : ''
        });
    }

    handleHasPriceChange(): void {
        const selectedHasPrice = this.hasprice.find((hasprice: any) => hasprice.selected);
        this.content.set({
            ...this.content(),
            hasprice: selectedHasPrice ? selectedHasPrice.key : ''
        });
    }

    handleForceStatusChange() {
        const selectedForceStatus = this.forceStatus.find(
            (forceStatus: any) => forceStatus.selected
        );
        this.content.set({
            ...this.content(),
            forceStatus: selectedForceStatus ? selectedForceStatus.key : ''
        });
    }

    handleTrainingOfTeachersChange(): void {
        const selectedTrainingOfTeachers = this.trainingOfTeachers.find(
            (trainingOfTeachers: any) => trainingOfTeachers.selected
        );
        this.content.set({
            ...this.content(),
            is_training_of_teachers: selectedTrainingOfTeachers
                ? selectedTrainingOfTeachers.key
                : ''
        });
    }

    handleHasCertificateChange(): void {
        const selectedHasCertificate = this.hasCertificate.find(
            (hasCertificate: any) => hasCertificate.selected
        );
        this.content.set({
            ...this.content(),
            hasCertificate: selectedHasCertificate ? selectedHasCertificate.key : ''
        });
    }

    // #endregion Handlers

    // #region Internals

    getMaxFileSize() {
        if (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.nationalAdmin
        ) {
            return this.configService.getConfig().maxFileSizeAdmin;
        } else {
            return this.configService.getConfig().maxFileSize;
        }
    }

    // #region Internals
}
