<div class="teacher-container">
    <div
        id="left-content"
        #leftContent
        [ngClass]="{
            reduced: closedLeft,
            extended: closedRight
        }"
    >
        <app-tabs></app-tabs>
        <router-outlet></router-outlet>
    </div>

    <div
        id="right-content"
        #rightContent
        [ngClass]="{
            reduced: closedRight,
            extended: closedLeft
        }"
    >
        <app-library></app-library>
    </div>
</div>
