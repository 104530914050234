// Internal dependencies
import { Component, OnInit, model, input, output, ViewChild, ElementRef } from '@angular/core';

// External dependencies

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LoginService } from '@/services/login.service';
import { ConfigService } from '@/services/config.service';

// Interfaces
import { Content } from '@/structures/content';

// Pipes
import { FileDropzoneDirective } from 'src/app/directives/file-dropdown.directive';

@Component({
    selector: 'app-extra-field',
    templateUrl: './extra-field.component.html',
    styleUrls: ['./extra-field.component.scss'],
    imports: [SharedModule, FileDropzoneDirective]
})
export class ExtraFieldComponent implements OnInit {
    content = model<Content>();

    file = model<File>();
    h5pFile = model<File>();

    showError = output<string>();

    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    @ViewChild('h5pContentInput', { static: false }) h5pContentInput: ElementRef;

    manuelTypes: Array<any>;

    constructor(
        private configService: ConfigService,
        private loginService: LoginService
    ) {}

    ngOnInit() {
        this.manuelTypes = [
            {
                key: 'nathan',
                title: 'Nathan',
                selected: this.content().type === 'nathan'
            },
            {
                key: 'hachette',
                title: 'Hachette',
                selected: this.content().type === 'hachette'
            }
        ];
    }

    // #region Getters

    showExtraContainer() {
        return (
            this.showFileContainer() ||
            this.showLTIContainer() ||
            this.showURLContainer() ||
            this.showManuelContainer() ||
            this.showN4SContainer() ||
            this.showH5PContainer()
        );
    }

    showFileContainer() {
        return (
            ['devoir', 'pdf', 'html', 'fichier', 'scorm', 'video', 'office', '3D'].indexOf(
                this.content().type
            ) > -1
        );
    }

    showLTIContainer() {
        return this.content().type === 'lti';
    }

    showURLContainer() {
        return this.content().type === 'url' || this.content().type === 'partenariat';
    }

    showManuelContainer() {
        return this.content().type === 'hachette' || this.content().type === 'nathan';
    }

    showManuelType() {
        return this.configService.getConfig().nathanNationalCreation;
    }

    showH5PContainer() {
        return this.content().type === 'h5pactivity';
    }

    showN4SContainer() {
        return this.content().type === 'n4s';
    }

    getCurrentH5PLabel() {
        if (this.h5pFile()) {
            return this.h5pFile().name;
        } else {
            return 'Glisser-déposer votre fichier H5P ici';
        }
    }

    getCurrentFileLabel() {
        if (this.content().path || this.file()) {
            let tmp: string;
            if (this.file()) {
                tmp = this.file().name;
            } else {
                if (this.content().type === 'scorm') {
                    return 'Fichier SCORM';
                }
                tmp = this.content().path.split('/')[this.content().path.split('/').length - 1];
            }
            if (tmp.length < 32) {
                return decodeURIComponent(tmp) + '';
            } else {
                const hash = tmp.substring(0, 32);
                const regex = RegExp('^[a-z0-9]+$', 'g');
                if (!regex.test(hash)) {
                    return `${decodeURIComponent(tmp)}`;
                } else {
                    return `${decodeURIComponent(tmp.substring(32))}`;
                }
            }
        } else {
            return 'Glisser-déposer votre fichier ici';
        }
    }

    getFileTooltip() {
        if (this.content().path || this.file()) {
            if (this.content().type === 'devoir') {
                return "Modifier l'énoncé";
            } else {
                return 'Modifier le fichier';
            }
        } else {
            if (this.content().type === 'devoir') {
                return 'Ajouter un énoncé';
            } else {
                return 'Ajouter un fichier';
            }
        }
    }

    hasFile() {
        return this.content().path || this.file();
    }

    // #endregion Getters

    // #region Handlers

    handleManuelTypeChange() {
        const currentType = this.manuelTypes.filter((type) => type.selected)[0].key;

        if (currentType === 'nathan') {
            this.content().code_uai = '';
            this.content().code_ressource = '';
        }
        if (currentType === 'hachette') {
            this.content().url = '';
        }

        this.content().type = currentType;
    }

    openSelectFile() {
        this.fileInput.nativeElement.click();
    }

    uploadFile(files: FileList) {
        if (
            this.content().id &&
            this.content().type !== 'scorm' &&
            this.getContentType(files.item(0)) === 'scorm'
        ) {
            this.showError.emit('Vous ne pouvez pas modifier ce type de document par un SCORM');
            return;
        }

        if (
            this.content().id &&
            this.content().type === 'scorm' &&
            this.getContentType(files.item(0)) !== 'scorm'
        ) {
            this.showError.emit("Vous ne pouvez pas changer le type d'une activité SCORM");
            return;
        }

        if (files.item(0).size > this.getMaxFileSize()) {
            this.showError.emit('Votre fichier doit être inférieur à 200 Mo');
            return;
        }

        this.file.set(files.item(0));
        if (this.content().type !== 'devoir') {
            this.content().type = this.getContentType(this.file());
        }
    }

    openSelectH5PContent() {
        this.h5pContentInput.nativeElement.click();
    }

    uploadH5PContent(files: FileList) {
        if (files.item(0).size > this.getMaxFileSize()) {
            this.showError.emit('Votre fichier doit être inférieur à 200 Mo');
            return;
        }
        this.h5pFile.set(files.item(0));
        this.content().h5p_type = undefined;
    }

    deleteH5PContent() {
        this.h5pFile.set(undefined);
    }

    toggleScormMenu() {
        this.content().tableOfContents = !this.content().tableOfContents;
    }

    // #endregion Handlers

    // #region Internals

    private getContentType(file) {
        const extension = /[^.]+$/.exec(file.name)[0].toLowerCase();

        switch (extension) {
            case 'pdf':
                return 'pdf';
            case 'mp4':
            case 'f4v':
            case 'swf':
            case 'flv':
                return 'video';
            case 'html':
                return 'html';
            case 'zip':
                return 'scorm';
            case 'odt':
            case 'ott':
            case 'odm':
            case 'oth':
            case 'ods':
            case 'ots':
            case 'odg':
            case 'otg':
            case 'odp':
            case 'otp':
            case 'odf':
            case 'oxt':
                return 'office';
            case 'sldprt':
            case 'sldasm':
            case 'slddrw':
            case 'dxf':
            case 'iges':
            case 'toppkg':
            case 'psimsch':
            case 'smv':
            case 'amf':
            case 'stl':
            case '3mf':
            case 'obj':
            case 'ply':
            case 'gcode':
            case 'dft':
            case 'mpp':
            case 'step':
            case 'stp':
            case 'top':
            case 'x_t':
            case 'bmp':
            case 'dwg':
            case 'db':
            case 'rpt':
            case 'igs':
                return '3D';
            default:
                if (
                    extension.indexOf('xl') === 0 ||
                    extension.indexOf('do') === 0 ||
                    extension.indexOf('pp') === 0
                ) {
                    return 'office';
                } else {
                    return 'fichier';
                }
        }
    }

    private getMaxFileSize() {
        if (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.nationalAdmin
        ) {
            return this.configService.getConfig().maxFileSizeAdmin;
        } else {
            return this.configService.getConfig().maxFileSize;
        }
    }

    // #enregion Internals
}
