// Internal dependencies
import { Component, model, input, output } from '@angular/core';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components

// Services
import { LoginService } from '@/services/login.service';
import { DialogService } from '@/services/dialog.service';
import { LibraryService } from '@/services/library.service';
import { FlashMessageService } from '@/services/flash-message.service';

// Interfaces
import { Content } from '@/structures/content';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-validation-space-entry',
    templateUrl: './validation-space-entry.component.html',
    styleUrls: ['./validation-space-entry.component.scss'],
    imports: [SharedModule]
})
export class ValidationSpaceEntryComponent {
    draft = model<Content>();
    type = input<string>();

    contentMovedToDraft = output<{ draft: Content; type: string }>();
    deletedDraft = output<{ draft: Content; type: string }>();

    constructor(
        private dialogService: DialogService,
        private libraryService: LibraryService,
        private flashMessageService: FlashMessageService,
        private loginService: LoginService
    ) {}

    //#region Getters

    displayIcon(icon: string) {
        switch (icon) {
            case 'fiche':
            case 'view-history':
            case 'open-activity': {
                return true;
            }
            case 'guide': {
                return this.draft().guide;
            }
            case 'edit-validators':
            case 'cancel-validation':
            case 'send-reminder':
            case 'waiting-for-approval': {
                return this.type() === 'sentToValidation';
            }
            case 'approve-activity':
            case 'reject-activity': {
                return (
                    (this.type() === 'waitingForApproval' ||
                        this.type() === 'waitingForAdminApproval') &&
                    this.loginService.getUser().id !== this.draft().author
                );
            }
            case 'add-validator': {
                return (
                    (this.type() === 'waitingForApproval' ||
                        this.type() === 'waitingForAdminApproval') &&
                    !this.loginService.getUser().roles.localAdmin
                );
            }
            case 'delegate-validation': {
                return this.draft().waitingForMyApproval;
            }
            case 'urgent': {
                return this.draft().urgent;
            }
        }
    }

    getIcon(): string {
        return this.libraryService.getIcon(this.draft());
    }

    getDraftTooltip(): string {
        return `<strong>Titre : </strong>${this.draft().title}</br>
        ${this.draft().id ? '<strong>ID : </strong>' : ''}${this.draft().id || ''}${
            this.draft().ucode ? '<strong> Code : </strong>' : ''
        }${this.draft().ucode || ''}</br>
        ${this.draft().description ? '<strong>Description : </strong>' : ''}${
            this.draft().description || ''
        }</br>
        ${this.draft().duration}`;
    }

    // #endregion Getters

    // #region Handlers

    handleClickViewFiche($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openContentDetails(this.draft().id);
    }

    handleClickViewGuide($event: any) {
        $event.stopImmediatePropagation();
        if (this.draft().guide) {
            const popup = window.open(this.draft().guide);
            if (!popup) {
                const warningTitle = "Impossible d'ouvrir la page";
                const warningBody =
                    "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
                this.dialogService.openWarning(warningBody, warningTitle);
            }
        }
    }

    editValidators($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openSelectValidator(this.draft()).subscribe((data: any) => {
            if (data) {
                this.libraryService.updateValidators(this.draft(), data).subscribe(() => {
                    this.flashMessageService.flash('La liste des valideurs a été mise à jour');
                });
            }
        });
    }

    cancelValidation($event: any) {
        $event.stopImmediatePropagation();
        this.libraryService.cancelValidation(this.draft()).subscribe(() => {
            this.contentMovedToDraft.emit({ draft: this.draft(), type: this.type() });
            this.flashMessageService.flash('La demande de validation du contenu a été annulé');
        });
    }

    sendReminder($event: any) {
        $event.stopImmediatePropagation();
        this.libraryService.sendReminder(this.draft()).subscribe(() => {
            this.flashMessageService.flash('Un rappel a été envoyé aux valideurs');
        });
    }

    openHistoryDialog($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openContentHistory(this.draft());
    }

    openActivity($event): void {
        $event.stopImmediatePropagation();
        this.libraryService.openActivity(this.draft());
    }

    rejectContent($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openRejectContent(this.draft()).subscribe((data: string) => {
            if (data) {
                this.libraryService.rejectContent(this.draft(), data).subscribe(() => {
                    this.flashMessageService.flash('Le contenu a été rejeté');
                    this.deletedDraft.emit({ draft: this.draft(), type: this.type() });
                });
            }
        });
    }

    getRejectContentTooltip() {
        if (this.loginService.getUser().roles.localAdmin && !this.draft().waitingForMyApproval) {
            return 'Forcer le refus de la validation';
        } else {
            return 'Refuser la validation';
        }
    }

    approveContent($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openApproveContent(this.draft()).subscribe((data: string) => {
            if (data) {
                this.libraryService.approveContent(this.draft(), data).subscribe(() => {
                    this.flashMessageService.flash('Le contenu a été approuvé');
                    this.deletedDraft.emit({ draft: this.draft(), type: this.type() });
                });
            }
        });
    }

    getValidateContentTooltip() {
        if (this.loginService.getUser().roles.localAdmin && !this.draft().waitingForMyApproval) {
            return 'Forcer la validation du contenu';
        } else {
            return 'Valider le contenu';
        }
    }

    addValidator($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openAddValidator(this.draft()).subscribe((data: any) => {
            if (data) {
                const params = {
                    id: this.draft().id,
                    user_id: data.validators,
                    comment: data.comment
                };

                this.libraryService.addValidator(params).subscribe(() => {
                    this.flashMessageService.flash('Les valideurs ont été ajoutés');
                });
            }
        });
    }

    delegateValidator($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openDelegateValidation(this.draft()).subscribe((data: any) => {
            if (data) {
                const params = {
                    id: this.draft().id,
                    user_id: data.validator,
                    comment: data.comment
                };

                this.libraryService.delegateValidation(params).subscribe(() => {
                    this.flashMessageService.flash('La validation du contenu a été déléguée');
                    if (this.loginService.getUser().roles.localAdmin) {
                        this.draft().waitingForMyApproval = false;
                        this.draft.set({
                            ...this.draft(),
                            waitingForMyApproval: false
                        });
                    } else {
                        this.deletedDraft.emit({ draft: this.draft(), type: this.type() });
                    }
                });
            }
        });
    }

    // #endregion Handlers
}
