@if (isAdmin()) {
    <mat-expansion-panel class="facultative-container">
        <mat-expansion-panel-header
            class="legend"
            [collapsedHeight]="'30px'"
            [expandedHeight]="'30px'"
        >
            Catalogue
        </mat-expansion-panel-header>
        <div class="element">
            <div class="label">Visuel</div>
            <div class="value" appFileDropzone (fileDropped)="uploadVisuel($event)">
                {{ getCurrentVisuelLabel() }}
            </div>
            @if (!content().visuel && !visuel()) {
                <div
                    class="icon-Importer"
                    (click)="openSelectVisuel()"
                    [tooltip]="'Ajouter un visuel'"
                ></div>
            }
            @if (content().visuel || visuel()) {
                <div
                    class="icon-bin"
                    (click)="deleteVisuel()"
                    [tooltip]="'Supprimer un visuel'"
                ></div>
            }
            <input
                class="hidden"
                type="file"
                #visuelInput
                (change)="uploadVisuel($event.target.files)"
            />
        </div>
        <div class="element">
            <div class="label">Titre (forcé)</div>
            <input type="text" placeholder="Titre" [(ngModel)]="content().forced_title" />
        </div>
        <div class="element">
            <div class="label">Durée (forcée)</div>
            <input
                type="text"
                placeholder="Durée (texte libre)"
                [(ngModel)]="content().forced_duration"
            />
        </div>
        <div class="element">
            <div class="label">Information</div>
            <input
                type="text"
                placeholder="Information (650 caractères maximum)"
                [(ngModel)]="content().information"
            />
        </div>
        <div class="element">
            <div class="label">Nouveau</div>
            <lib-uimm-dropdown
                [(items)]="new"
                (itemsChange)="handleNewChange()"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element">
            <div class="label">Visible</div>
            <lib-uimm-dropdown
                [(items)]="visible"
                (itemsChange)="handleVisibleChange()"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        <div class="element">
            <div class="label">Bandeau (forcé)</div>
            <lib-uimm-dropdown
                [(items)]="forcedStripe"
                (itemsChange)="handleForcedStripeChange()"
                [labelWidth]="'100%'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
        @if (isForcedStripe()) {
            <div class="element">
                <div class="label"></div>
                <input
                    type="text"
                    placeholder="Texte du bandeau (20 caractères maximum)"
                    [(ngModel)]="content().forced_stripe_text"
                />
            </div>
        }
        @if (isForcedStripe()) {
            <div class="element">
                <div class="label"></div>
                <div class="radio-container">
                    @for (color of stripeColors; track color) {
                        <div
                            class="radio"
                            (click)="selectStripeColor(color)"
                            [ngClass]="{ selected: isSelectedStripeColor(color) }"
                        >
                            <div [ngStyle]="{ 'background-color': color }"></div>
                        </div>
                    }
                </div>
            </div>
        }
    </mat-expansion-panel>
}
