// Internal dependencies
import { Component, OnInit, input, model, output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LibraryService } from '@/services/library.service';
import { LoginService } from '@/services/login.service';

// Interfaces
import { Content, ContentForImport } from '@/structures/content';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-mass-import-entry',
    templateUrl: './mass-import-entry.component.html',
    styleUrls: ['./mass-import-entry.component.scss'],
    imports: [SharedModule, MatButtonModule, MatIconModule]
})
export class MassImportEntryComponent implements OnInit {
    constructor(
        private libraryService: LibraryService,
        private loginService: LoginService
    ) {}

    content = model<ContentForImport>();
    importType = input<string>();
    ucodes = input<Array<string>>();
    ucodesChildren = model<Array<string>>();

    contentImported = output<{ index: number; status: boolean }>();

    ngOnInit() {
        try {
            this.checkContent();
        } catch (e) {
            console.error(e);
            this.content.set({
                ...this.content(),
                invalid: true,
                comment: e
            });
        }
    }

    // #region Getters

    isActivityImport() {
        return this.importType() === 'import-en-masse-activite';
    }

    getFileType() {
        const extension = /[^.]+$/.exec(this.content().downloadUrl)[0].toLowerCase();

        switch (extension) {
            case 'pdf':
                return 'pdf';
            case 'mp4':
            case 'f4v':
            case 'swf':
            case 'flv':
                return 'video';
            case 'html':
                return 'html';
            case 'zip':
                return 'scorm';
            case 'odt':
            case 'ott':
            case 'odm':
            case 'oth':
            case 'ods':
            case 'ots':
            case 'odg':
            case 'otg':
            case 'odp':
            case 'otp':
            case 'odf':
            case 'oxt':
                return 'office';
            case 'sldprt':
            case 'sldasm':
            case 'slddrw':
            case 'dxf':
            case 'iges':
            case 'toppkg':
            case 'psimsch':
            case 'smv':
            case 'amf':
            case 'stl':
            case '3mf':
            case 'obj':
            case 'ply':
            case 'gcode':
            case 'dft':
            case 'mpp':
            case 'step':
            case 'stp':
            case 'top':
            case 'x_t':
            case 'bmp':
            case 'dwg':
            case 'db':
            case 'rpt':
            case 'igs':
                return '3D';
            default:
                if (
                    extension.indexOf('xl') === 0 ||
                    extension.indexOf('do') === 0 ||
                    extension.indexOf('pp') === 0
                ) {
                    return 'office';
                } else {
                    return 'fichier';
                }
        }
    }

    // #endregion Getters

    // #region Handlers

    handleImportContent() {
        this.initContent();
        if (this.content().invalid) {
            this.contentImported.emit({
                index: this.content().index,
                status: false
            });
        } else {
            let action;
            if (this.isActivityImport()) {
                action = this.libraryService.createActivity(this.content() as Content);
            } else {
                action = this.libraryService.createAssembly(this.content() as Content);
            }
            if (this.content().typeImport !== 'mineur') {
                action.subscribe(
                    (success: ContentForImport) => {
                        if (this.content().ucode) {
                            this.ucodesChildren.set({
                                ...this.ucodesChildren(),
                                [this.content().ucode]: success.id
                            });
                        }
                        this.content.set({
                            ...this.content(),
                            ...success,
                            created: true,

                            publicationMode: 'new',
                            validator: [{ id: this.loginService.getUser().id }]
                        });
                        this.libraryService
                            .approveContent(this.content() as Content, undefined)
                            .subscribe(
                                () => {
                                    this.contentImported.emit({
                                        index: this.content().index,
                                        status: true
                                    });

                                    this.content.set({
                                        ...this.content(),
                                        published: true
                                    });
                                },
                                (error: HttpErrorResponse) => {
                                    this.contentImported.emit({
                                        index: this.content().index,
                                        status: false
                                    });

                                    this.content.set({
                                        ...this.content(),
                                        published: false,
                                        comment: error.error.userMessage
                                    });
                                }
                            );
                    },
                    (error: HttpErrorResponse) => {
                        this.content.set({
                            ...this.content(),
                            created: false,
                            comment: error.error.userMessage
                        });
                        this.contentImported.emit({ index: this.content().index, status: false });
                    }
                );
            } else {
                const contentToEdit = {
                    id: this.ucodes()[this.content().ucode],
                    level: 'activity',
                    downloadUrl: this.content().downloadUrl
                };
                this.libraryService.duplicateContent(contentToEdit, false).subscribe(
                    (success: ContentForImport) => {
                        this.content.set({
                            ...this.content(),
                            id: success.id,
                            ucode: null,
                            desiredPublicationMode: 'update'
                        });
                        this.libraryService.updateActivity(this.content()).subscribe(
                            (success: ContentForImport) => {
                                this.content.set({
                                    ...success,
                                    created: true,
                                    publicationMode: 'minor-update',
                                    validator: [{ id: this.loginService.getUser().id }]
                                });
                                this.libraryService
                                    .approveContent(this.content() as Content, undefined)
                                    .subscribe(
                                        () => {
                                            this.contentImported.emit({
                                                index: this.content().index,
                                                status: true
                                            });

                                            this.content.set({
                                                ...this.content(),
                                                published: true
                                            });
                                        },
                                        (error: HttpErrorResponse) => {
                                            this.contentImported.emit({
                                                index: this.content().index,
                                                status: false
                                            });

                                            this.content.set({
                                                ...this.content(),
                                                published: false,
                                                comment: error.error.userMessage
                                            });
                                        }
                                    );
                            },
                            (error: HttpErrorResponse) => {
                                this.content.set({
                                    ...this.content(),
                                    created: false,
                                    comment: error.error.userMessage
                                });
                                this.contentImported.emit({
                                    index: this.content().index,
                                    status: false
                                });
                            }
                        );
                    },
                    (error: HttpErrorResponse) => {
                        this.content.set({
                            ...this.content(),
                            created: false,
                            comment: error.error.userMessage
                        });
                    }
                );
            }
        }
    }

    // #endregion Handlers

    // #region Internals

    private checkContent() {
        if (this.ucodes()[this.content().ucode] !== -1 && this.content().typeImport !== 'mineur') {
            throw new Error('Le code unique est déja utilisé');
        }
        if (!this.content().title) {
            throw new Error("Le champ 'titre' ne peut pas être vide");
        }

        if (!this.content().description) {
            throw new Error("Le champ 'description' ne peut pas être vide");
        }

        if (!this.content().domains) {
            throw new Error("Le champ 'domains' ne peut pas être vide");
        }

        if (!this.content().keywords) {
            throw new Error("Le champ 'keywords' ne peut pas être vide");
        }

        if (
            !this.content().level &&
            !this.content().duration.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g)
        ) {
            throw new Error("Le format du champ 'duree' n'est pas correct");
        }

        if (this.content().author && isNaN(Number(this.content().author))) {
            throw new Error("Le format du champ 'auteur' n'est pas correct");
        }

        if (this.content().hasprice) {
            if (this.content().hasprice > 3) {
                throw new Error("Le format du champ 'payant' n'est pas correct");
            }
            if (this.content().hasprice === 1 && isNaN(Number(this.content().price))) {
                throw new Error(
                    "Le format des champs 'payant' et 'prix' ne sont pas compatibles. Le prix doit être un nombre"
                );
            }
        }

        if (this.isActivityImport()) {
            if (!this.content().ucode && this.content().typeImport === 'mineur') {
                throw new Error('Code obligatoire en cas de mise à jour mineur');
            }

            if (
                this.ucodes()[this.content().ucode] === -1 &&
                this.content().typeImport === 'mineur'
            ) {
                throw new Error("Le code indiqué n'existe pas");
            }
            if (![undefined, '', 'mineur'].includes(this.content().typeImport)) {
                throw new Error("Le format du champ 'typeImport' n'est pas correct");
            }
            if (!this.content().category) {
                throw new Error("Le champ 'categorie' ne peut pas être vide");
            }

            if (
                ![
                    'corrige',
                    'devoir',
                    'preparation',
                    'presentiel',
                    'ressource',
                    'travail',
                    'tp',
                    'evaluation',
                    'positionnement'
                ].includes(this.content().category)
            ) {
                throw new Error("Le champ 'categorie' n'est pas valide");
            }
            if (!this.content().downloadUrl && !this.content().type) {
                throw new Error("Un des champs 'downloadUrl' et 'type' doit être renseigné");
            }
        } else {
            if (
                !['sequence', 'module', 'competence', 'bloc', 'parcours'].includes(
                    this.content().level
                )
            ) {
                throw new Error("Le champ 'level' n'est pas valide");
            }
            this.content()
                .ucodes_children.split('|')
                .map((ucode_children: string) => {
                    if (
                        !this.ucodes()[ucode_children] &&
                        this.ucodesChildren()[ucode_children] === -1
                    ) {
                        throw new Error("L'un des codes de 'code_contenu' n'existe pas");
                    }
                });
        }
    }

    private initContent() {
        this.content.set({
            ...this.content(),
            status: 'draft',
            hidden: false,
            type: this.content().type ? this.content().type : this.getFileType(),
            domains: (this.content().domains as string).trim().split('|'),
            keywords: (this.content().keywords as string).trim().split('|'),
            sites: this.content().sites ? (this.content().sites as string).split(',') : [],
            author: this.content().author ? this.content().author : this.loginService.getUser().id,
            hasprice: this.content().hasprice ? this.content().hasprice : 0,
            enabled: this.content().enabled ? Boolean(this.content().enabled) : true,
            ucode: this.content().ucode ? this.content().ucode : '',
            taxonomy: this.content().taxonomy ? this.content().taxonomy : '',
            provider: this.content().provider ? this.content().provider : '',
            pricetxt: this.content().pricetxt ? this.content().pricetxt : '',
            price: this.content().price ? this.content().price : 0,
            copyright: this.content().copyright ? this.content().copyright : 'UIAF',
            forceStatus: this.content().forceStatus ? this.content().forceStatus : true,
            downloadUrl: this.content().downloadUrl ? this.content().downloadUrl : null,
            localStructure: this.content().localStructure
                ? this.content().localStructure
                : this.loginService.getUser().structureid
        });

        if (!this.isActivityImport()) {
            this.content.set({ ...this.content(), children: this.getChildrenIdFromUcodes() });
        }
    }

    private getChildrenIdFromUcodes() {
        return this.content()
            .ucodes_children.split('|')
            .map((ucode, index) => ({
                id: this.ucodesChildren()[ucode],
                position: (index + 1) * 1000000
            }));
    }

    // #endregion Internals
}
