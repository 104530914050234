<div class="left-content">
    @if (isSelectedTab('assemblies')) {
        <div class="legend">Sélectionnez votre <b>niveau d'assemblage</b></div>
        <div class="tree">
            @for (content of contentsList().assemblies; track content.key) {
                <div
                    class="item"
                    [ngClass]="{ active: isSelectedKey(content.key) }"
                    (click)="handleClickSelectKey(content.key)"
                >
                    <div [ngClass]="content.icon"></div>
                    <div class="label">{{ content.title }} <span class="legend">({{content.legend}})</span></div>
                </div>
            }
        </div>
    }
    @if (isSelectedTab('creations')) {
        <div>Sélectionnez un type de <b>création digitale</b></div>
        @for (item of contentsList().creations | keyvalue: null; track item.value.label) {
            @if (displayCreationSection(item.key)) {
                <div class="label">{{ item.value.label }}</div>
                <div class="contents">
                    @for (content of item.value.contents; track content.key) {
                        <div
                            class="content"
                            [ngClass]="{
                                active: isSelectedKey(content.key),
                                spotlight: item.key === 'spotlight'
                            }"
                            (click)="handleClickSelectKey(content.key)"
                        >
                            <img [src]="getContentIcon(content)" />
                            <div>{{ content.title }}</div>
                        </div>
                    }
                </div>
            }
        }
    }
    @if (isSelectedTab('imports')) {
        <div>Sélectionnez ce que vous souhaitez <b>importer</b></div>
        @for (item of contentsList().imports | keyvalue: null; track item.value.label) {
            <div class="label">{{ item.value.label }}</div>
            <div class="contents">
                @for (content of item.value.contents; track content.key) {
                    <div
                        class="content"
                        [ngClass]="{
                            active: isSelectedKey(content.key),
                            spotlight: item.key === 'spotlight'
                        }"
                        (click)="handleClickSelectKey(content.key)"
                    >
                        <img [src]="getContentIcon(content)" />
                        <div>{{ content.title }}</div>
                    </div>
                }
            </div>
        }
    }
</div>
