// Internal dependencies
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {
    MatAutocompleteModule,
    MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatFormFieldModule } from '@angular/material/form-field';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';
import * as FileSaver from 'file-saver';

// Modules
import { SharedModule } from '@/modules/shared.module';

// Components
import { UserTrackingComponent } from './user-tracking/user-tracking.component';

// Services
import { ConfigService } from '@/services/config.service';
import { UserService } from '@/services/user.service';
import { GroupService } from '@/services/group.service';
import { LoadingService } from '@/services/loading.service';
import { LibraryService } from '@/services/library.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { AssignmentService } from '@/services/assignment.service';
import { DevoirService } from '@/services/devoir.service';
import { ReportingService } from '@/services/reporting.service';
import { DialogService } from '@/services/dialog.service';

// Interfaces
import { User } from '@/structures/user';
import { Group } from '@/structures/group';
import { Assignment } from '@/structures/assignment';
import { UserTracking } from '@/structures/user-tracking';

// Pipes
import { GroupSearchPipe } from '@/pipes/group-search.pipe';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-tracking',
    templateUrl: './tracking.component.html',
    styleUrls: ['./tracking.component.scss'],
    imports: [
        SharedModule,
        MatIconModule,
        MatInputModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        UserTrackingComponent,
        GroupSearchPipe
    ]
})
export class TrackingComponent implements OnInit {
    groups: Array<Group>;
    tracking: Array<UserTracking>;
    search: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { assignment: Assignment; target: Group },
        private dialogRef: MatDialogRef<TrackingComponent>,
        private configService: ConfigService,
        private userService: UserService,
        private groupService: GroupService,
        private loadingService: LoadingService,
        private assignmentService: AssignmentService,
        private devoirService: DevoirService,
        private flashMessageService: FlashMessageService,
        private sanitizer: DomSanitizer,
        private libraryService: LibraryService,
        private reportingService: ReportingService,
        private dialogService: DialogService
    ) {}

    ngOnInit() {
        this.tracking = [];
        this.search = '';
        this.getTracking();
    }

    // #region Getters

    getHeaderIcon() {
        if (this.isUserAssignment()) {
            return 'icon-acces-devoirs';
        } else {
            return 'icon-Corrige';
        }
    }

    getHeaderTitle(): string {
        if (this.isUserAssignment()) {
            return 'Remise de devoir';
        } else {
            return 'Suivi du groupe';
        }
    }

    displayIcon(key: string): boolean {
        if (key === 'HideAssignments') {
            return this.tracking.filter((trackingStatus) => trackingStatus.hidden).length === 0;
        } else if (key === 'ShowAssignments') {
            return !(this.tracking.filter((trackingStatus) => trackingStatus.hidden).length === 0);
        } else if (key === 'SendReminder') {
            return true;
        } else if (key === 'DownloadCSVReport') {
            return true;
        } else if (key === 'download') {
            return (
                this.data.assignment.type === 'devoir' &&
                this.tracking.some((userTracking: UserTracking) => userTracking.submitted_file)
            );
        } else if (key === 'UploadDevoirCorrectionGroup') {
            return this.data.assignment.type === 'devoir';
        } else if (key === 'rafraichir') {
            return this.data.assignment.type === 'devoir' || this.data.assignment.type === 'quiz';
        } else if (key === 'ViewGroupTestReporting') {
            return this.data.assignment.type === 'quiz';
        } else if (key === 'ViewGroupTestsReporting') {
            return !this.data.assignment.type;
        } else if (key === 'ResetGroupTracking') {
            return (
                this.data.assignment.type === 'devoir' &&
                (!this.data.assignment.is_anonymous || !this.data.assignment.is_revealed)
            );
        }
    }

    inSearchResult(user: UserTracking): boolean {
        const reg = new RegExp(this.accentFold(this.search), 'i');

        const firstLast = this.accentFold(user.firstname + ' ' + user.lastname.toUpperCase());
        const lastFirst = this.accentFold(user.lastname.toUpperCase() + ' ' + user.firstname);

        if (firstLast.search(reg) > -1 || lastFirst.search(reg) > -1) {
            return true;
        }

        return false;
    }

    isUserAssignment(): boolean {
        return !this.data.assignment.users;
    }

    isAnyTemporaryScore(): boolean {
        return this.tracking.some(
            (userTracking: UserTracking) => userTracking.temporaryScore && !userTracking.score
        );
    }

    getTracking(group?: MatAutocompleteSelectedEvent) {
        this.loadingService.startLoading('TrackingComponent', 'getWorkTracking');
        let action;
        if (this.isUserAssignment()) {
            action = this.userService.getUserDevoirTracking(this.data.assignment.assignmentId);
        } else {
            action = this.groupService.getGroupDevoirTracking(
                this.data.assignment.assignmentId,
                group?.option.value
            );
        }
        action.subscribe(
            (data: Array<UserTracking> | { too_many_users: boolean; groups: Array<Group> }) => {
                if (!this.isUserAssignment() && !Array.isArray(data) && data.too_many_users) {
                    this.groups = data.groups;
                    this.loadingService.stopLoading('TrackingComponent', 'getWorkTracking');
                } else {
                    this.search = '';
                    this.groups = [];
                    this.tracking = data as Array<UserTracking>;
                    if (this.tracking.some((userTracking: UserTracking) => userTracking.score)) {
                        this.tracking = this.tracking.map((userTracking: UserTracking) => ({
                            ...userTracking,
                            graded: true
                        }));
                    }
                    console.log(this.tracking);
                    if (this.data.assignment.level) {
                        this.groupService
                            .getGroupCompletion(
                                this.data.target.id,
                                this.data.assignment.assignmentId
                            )
                            .subscribe((completion) => {
                                this.tracking.map((userTracking: UserTracking) =>
                                    completion.users_completions.map((user: User) => {
                                        if (user.id === userTracking.userid) {
                                            userTracking.completion = user.completion;
                                        }
                                    })
                                );
                            });
                    }
                    this.loadingService.stopLoading('TrackingComponent', 'getWorkTracking');
                }
            },
            () => {
                this.loadingService.stopLoading('TrackingComponent');
            }
        );
    }

    getDevoirDates(date: string) {
        return new Date(new Date(date).getTime() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .slice(0, -1);
    }

    getTooltipUploadDevoir() {
        if (this.data.assignment.correction_file) {
            return 'Voir le corrigé général';
        } else {
            return 'Rendre un corrigé général';
        }
    }

    getDownloadAllDevoirsLink(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
            this.groupService.getGroupDevoirDownloadAllLink(this.data.assignment.assignmentId)
        );
    }

    getContentIcon(): string {
        return this.libraryService.getIcon(this.data.assignment);
    }

    showFooter(): boolean {
        return (
            this.data.assignment.type === 'devoir' &&
            (this.isAnyTemporaryScore() ||
                (this.isAnonymous() && !this.data.assignment.is_revealed))
        );
    }

    isAnonymous() {
        return this.data.assignment.is_anonymous;
    }

    isRevealButtonDisabled(): boolean {
        if (this.tracking) {
            return this.tracking.some((user) => {
                return user.status === 'completed' && user.submitted_file && !user.temporaryScore;
            });
        }
        return true;
    }

    isLoading(view: string): boolean {
        return this.loadingService.isLoading(view);
    }

    // #endregion Getters

    // #region Handlers

    handleClickHideAssignments(): void {
        this.groupService.hideGroupAssignment(this.data.assignment.assignmentId).subscribe(() => {
            this.tracking.map((trackingUser) => (trackingUser.hidden = true));
            this.flashMessageService.flash("L'assignation a été désassignée.");
        });
    }

    handleClickShowAssignments(): void {
        this.groupService.showGroupAssignment(this.data.assignment.assignmentId).subscribe(() => {
            this.tracking.map((trackingUser) => (trackingUser.hidden = false));
            this.flashMessageService.flash("L'assignation a été réassignée.");
        });
    }

    handleClickSendReminder(): void {
        this.assignmentService
            .remindAssignment({
                enrolmentSource: 'group',
                assignmentId: this.data.assignment.assignmentId
            })
            .subscribe(() => {
                this.flashMessageService.flash(`L'email a bien été envoyé`);
            });
    }

    handleClickDownloadCSVReport() {
        const filters = {
            groupId: this.data.target.id,
            contentId: this.data.assignment.id
        };
        this.reportingService.getCSVReporting(filters).subscribe((csvText: string) => {
            const date =
                (new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()) +
                '-' +
                (new Date().getMonth() + 1 < 10
                    ? '0' + (new Date().getMonth() + 1)
                    : new Date().getMonth() + 1) +
                '-' +
                new Date().getFullYear();
            const csvName = 'reporting_' + date + '.csv';
            const blob = new Blob(['\ufeff', csvText], {
                type: 'text/plain;charset=iso-8859-1;'
            });
            FileSaver.saveAs(blob, csvName);

            this.flashMessageService.flash("L'export CSV est terminé");
        });
    }

    handleClickResetGroupTracking() {
        const dialog = {
            title:
                this.data.assignment.type === 'devoir'
                    ? 'Proposer un nouveau dépot'
                    : 'Proposer une nouvelle tentative',
            body:
                this.data.assignment.type === 'devoir'
                    ? "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité sera réinitialisé<br>Le devoir rendu précédemment ainsi que la note seront supprimés définitivement<br><br><b>Continuer</b> ?"
                    : this.data.assignment.type === 'quiz'
                      ? "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité et la copie de l'apprenant seront réinitialisés.<br>Les résultats des anciennes tentatives seront conservés et visibles dans le rapport de test.<br><br><b>Continuer</b>"
                      : this.data.assignment.type === 'scorm'
                        ? "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité sera réinitialisé.<br>Le score de la tentative précédente ne sera pas conservée.<br>Seul le nombre de tentatives sera mis à jour dans le rapport de l'apprenant (.csv)<br><br><b>Continuer</b> ?"
                        : "Vous allez proposer une nouvelle tentative :<br><br>Le statut de l'activité sera réinitialisé.<br><br><b>Continuer</b> ?"
        };

        this.dialogService
            .openConfirmationDialog(dialog.title, dialog.body)
            .subscribe((valid: boolean) => {
                if (valid) {
                    this.groupService
                        .removeGroupTracking(this.data.target.id, this.data.assignment.id)
                        .subscribe(() => {
                            this.flashMessageService.flash(
                                'Le contenu <b>' +
                                    this.data.assignment.title +
                                    '</b> a été réinitialisé'
                            );
                            this.tracking.map((user) => {
                                user.status = 'not attempted';
                                user.score = null;
                                user.temporaryScore = null;
                                user.submitted_file = null;
                                user.submission_time = null;
                            });
                        });
                }
            });
    }

    handleClickViewGroupTestsReporting() {
        const quizList = this.getQuizsContentFromAssignments(this.data.assignment.children);
        window.open(
            this.configService.getReportingFrontEndpoint() +
                '#/?groupId=' +
                this.data.target.id +
                '&select=test&id=' +
                quizList.join('|') +
                '&name=' +
                this.data.assignment.title,
            '_blank'
        );
    }

    handleClickViewGroupTestReporting() {
        window.open(
            this.configService.getReportingFrontEndpoint() +
                '#/?groupId=' +
                this.data.target.id +
                '&select=test&id=' +
                this.data.assignment.quizId +
                '&name=' +
                this.data.assignment.title,
            '_blank'
        );
    }

    handleClickGradeGroup() {
        const allDevoirReady = !this.tracking.some(
            (userTracking: UserTracking) => !userTracking.temporaryScore
        );
        if (this.isUserAssignment()) {
            this.gradeUser();
        } else {
            if (allDevoirReady && !this.isAnonymous()) {
                this.gradeGroup();
            } else {
                this.dialogService
                    .openRevealDevoirConfirmationDialog(allDevoirReady, this.isAnonymous())
                    .subscribe((data: boolean | string) => {
                        if (data) {
                            this.gradeGroup(data);
                        }
                    });
            }
        }
    }

    handleClickUploadDevoirCorrectionGroup(): void {
        if (this.data.assignment.correction_file) {
            window.open(this.data.assignment.correction_file, '_blank');
        } else {
            this.dialogService
                .openUploadDevoirCorrectionDialog({
                    assignment: this.data.assignment,
                    group: this.data.target
                })
                .subscribe((uploadResponse: any) => {
                    if (uploadResponse.correction_file) {
                        this.data.assignment.correction_file = uploadResponse.correction_file;
                    }
                });
        }
    }

    handleCloseDialog(): void {
        this.closeDialog();
    }

    // #endregion Handlers

    // #region Internals

    private closeDialog() {
        this.dialogRef.close();
    }

    private accentFold(string: string) {
        return string.replace(
            /([àáâãäå])|([ç])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
            function (str, a, c, e, i, n, o, s, u, y, ae) {
                if (a) {
                    return 'a';
                } else if (c) {
                    return 'c';
                } else if (e) {
                    return 'e';
                } else if (i) {
                    return 'i';
                } else if (n) {
                    return 'n';
                } else if (o) {
                    return 'o';
                } else if (s) {
                    return 's';
                } else if (u) {
                    return 'u';
                } else if (y) {
                    return 'y';
                } else if (ae) {
                    return 'ae';
                }
            }
        );
    }

    private getQuizsContentFromAssignments(assignments): string[] {
        let quizList: string[] = [];
        let found: boolean;
        for (const i in assignments) {
            if (assignments[i].type === 'quiz') {
                found = false;
                for (const j in quizList) {
                    if (quizList[j] === assignments[i].quizId) {
                        found = true;
                    }
                }
                if (!found) {
                    quizList.push(assignments[i].quizId);
                }
            } else if (assignments[i].children) {
                quizList = quizList.concat(
                    this.getQuizsContentFromAssignments(assignments[i].children)
                );
            }
        }
        return quizList;
    }

    private gradeGroup(giveZeroToMissingNotes?) {
        this.devoirService
            .gradeGroupWork(this.data.assignment.assignmentId, 'grade', giveZeroToMissingNotes)
            .subscribe(() => {
                this.dialogRef.close(true);
            });
    }

    private gradeUser() {
        this.devoirService
            .gradeUserWork(this.data.assignment.assignmentId, 'grade')
            .subscribe(() => {
                this.flashMessageService.flash('La note a bien été enregistrée');
                this.dialogRef.close();
            });
    }

    // #endregion Internals
}
