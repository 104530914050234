<div class="admin-container">
    <div class="nav-menu">
        @for (tab of adminTabs; track tab.key) {
            <div class="tab" routerLink="{{ tab.key }}" routerLinkActive="active">
                <div class="icon-AdminListes"></div>
                <div class="label">{{ tab.label }}</div>
            </div>
        }
    </div>
    <div class="main-container">
        <router-outlet></router-outlet>
    </div>
</div>
