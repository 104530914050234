<div class="user-assignments">
    <div class="header">
        <div class="icon-contenusassignes"></div>
        <div class="name">{{ getUserName() }}</div>
        <div class="completion">{{ getUserCompletion() }}</div>
        <div class="icons">
            <div class="search-container">
                <input
                    class="library-search-field"
                    [(ngModel)]="filters.search"
                    placeholder="Chercher..."
                    (keyup.enter)="getUserAssignments()"
                />
                <span class="icon-search" (click)="getUserAssignments()"></span>
                <span
                    class="icon-Fermerdetails"
                    *ngIf="filters.search.length"
                    (click)="cancelSearch()"
                ></span>
            </div>
            <div
                class="icon-Nouvelleversioncontenu"
                [tooltip]="'Rafraichir la liste des assignations'"
                (click)="getUserAssignments()"
            ></div>
            <div
                class="icon-Suividesconnexions"
                [tooltip]="'Suivi de l\'apprenant'"
                (click)="openUserReporting()"
            ></div>
            <div
                class="icon-FicheUser"
                [tooltip]="'Voir la fiche de l\'utilisateur'"
                (click)="openUserDialog()"
            ></div>
            <div
                class="icon-reporting"
                [tooltip]="'Télécharger le rapport de l\'apprenant'"
                (click)="getUserReporting($event)"
            ></div>
            <div
                class="icon-radar"
                [tooltip]="'Rapports des tests de l\'apprenant'"
                (click)="openUserTestReporting($event)"
            ></div>
            <div class="icon-close" [tooltip]="'Retour'" (click)="goToStudentPanel()"></div>
        </div>
    </div>
    <div class="filters-container">
        <lib-uimm-dropdown
            [label]="'Domaine'"
            [labelWidth]="'110px'"
            [labelColor]="'white'"
            [(items)]="filters.domains"
            (itemsChange)="getUserAssignments()"
            multiselect="true"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            [label]="'Catégorie'"
            [labelWidth]="'110px'"
            [labelColor]="'white'"
            [(items)]="filters.categories"
            (itemsChange)="getUserAssignments()"
            multiselect="true"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            [label]="'Référent'"
            [labelWidth]="'110px'"
            [labelColor]="'white'"
            [(items)]="filters.referents"
            (itemsChange)="getUserAssignments()"
            multiselect="true"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            [label]="'Groupe'"
            [labelWidth]="'110px'"
            [labelColor]="'white'"
            [(items)]="filters.origin"
            (itemsChange)="getUserAssignments()"
            multiselect="true"
        ></lib-uimm-dropdown>
        <lib-uimm-dropdown
            [label]="'Statut'"
            [labelWidth]="'110px'"
            [labelColor]="'white'"
            [(items)]="filters.status"
            (itemsChange)="getUserAssignments()"
            multiselect="true"
        ></lib-uimm-dropdown>
        <div
            class="icon-Supprimerdugroupe"
            (click)="resetFilters()"
            *ngIf="showResetFilters()"
            tooltip="Annuler les filtres"
        ></div>
    </div>
    <div
        class="assignments-container"
        *ngIf="!isLoading()"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [alwaysCallback]="true"
        [scrollWindow]="false"
        (scrolled)="getNextAssignment()"
        (scrolledUp)="getPreviousAssignment()"
    >
        <div class="group-role-assignments-container" *ngFor="let origin of groupRoleAssignment">
            <div class="border"></div>

            <div class="legend">
                <div class="icon-porte-voix"></div>
                <div class="title">
                    Contenus assignés par défaut en tant que membre du groupe :
                    <span class="group-name" (click)="goToGroup(origin.id)">
                        {{ origin.name }}
                    </span>
                    ({{ getAssignmentFromOrigin(origin.name, true).length }})
                </div>
            </div>

            <ng-container
                *ngFor="let assignment of getAssignmentFromOrigin(origin.name); index as i"
            >
                <app-user-assignments-entry
                    [user]="user"
                    [assignment]="assignment"
                    [referents]="referents"
                    [parent]="{ children: getAssignmentFromOrigin(origin.name) }"
                    [index]="i"
                >
                </app-user-assignments-entry>
            </ng-container>
        </div>
        <div class="group-assignments-container" *ngFor="let origin of groupAssignment">
            <div class="border"></div>

            <div class="legend">
                <div class="icon-AssignationDeGroupe"></div>
                <div class="title">
                    Contenus assignés en tant que membre du groupe :
                    <span class="group-name" (click)="goToGroup(origin.id)">
                        {{ origin.name }}
                    </span>
                    ({{ getAssignmentFromOrigin(origin.name, true).length }})
                </div>
            </div>

            <ng-container
                *ngFor="let assignment of getAssignmentFromOrigin(origin.name); index as i"
            >
                <app-user-assignments-entry
                    [user]="user"
                    [assignment]="assignment"
                    [referents]="referents"
                    [parent]="{ children: getAssignmentFromOrigin(origin.name) }"
                    [index]="i"
                >
                </app-user-assignments-entry>
            </ng-container>
        </div>
        <div class="user-assignments-container">
            <div class="border"></div>
                    <div class="legend">
                        <div class="icon-apprenant"></div>
                        <div class="title">
                            Contenus assignés individuellement ({{
                                getAssignmentFromOrigin('individual', true).length
                            }})
                        </div>
                    </div>
                    <ng-container *ngIf="getAssignmentFromOrigin('individual').length > 0">
                        <ng-container
                            *ngFor="
                                let assignment of getAssignmentFromOrigin('individual');
                                index as i
                            "
                        >
                            <app-user-assignments-entry
                                class="individual-assignment-entry"
                                [user]="user"
                                [assignment]="assignment"
                                [referents]="referents"
                                [parent]="{ children: getAssignmentFromOrigin('individual') }"
                                [index]="i"
                                (childrenAssigned)="addToAssignments($event)"
                                (childrenMoved)="moveAssignment($event)"
                                (assignmentDeleted)="removeAssignment($event)"
                                (assignmentStatusChanged)="updateAssignmentStatus($event)"
                            >
                            </app-user-assignments-entry>
                        </ng-container>
                    </ng-container>

        </div>

        <app-user-assignments-entry
            *ngIf="getAssignmentFromOrigin('individual').length === 0"
            [user]="user"
            (childrenAssigned)="addToAssignments($event)"
        ></app-user-assignments-entry>
    </div>
    <div class="assignments-container" *ngIf="isLoading()">
        <app-loading></app-loading>
    </div>
    <div class="multiselection-container" *ngIf="!isSelectionEmpty()">
        <!-- Réactiver -->
        <div
            class="multiselection-action position1 orange show"
            tooltip="Ajouter une restriction calendaire"
            (click)="addDisponibility()"
        >
            <span class="icon-calendrier"></span>
        </div>
    </div>
</div>
