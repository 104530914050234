<div class="category-container">
    @if (!content().type) {
        <div class="element">
            <div class="label">Niveau de l'assemblage*</div>
            <div>
                <div class="icon" [ngClass]="getContentIcon()"></div>
                <div class="level">{{ getContentLevel() }}</div>
            </div>
        </div>
    }
    @if (content().type) {
        <div class="element">
            <div class="label">Catégorie *</div>
            <lib-uimm-dropdown
                [(items)]="categories"
                (itemsChange)="handleCategoriesChange()"
                [label]="'Choisir une catégorie'"
                [labelDefaultColor]="'#ff7200'"
                [labelWidth]="'200px'"
                [uniformWidth]="true"
            ></lib-uimm-dropdown>
        </div>
    }
    @if (content().type) {
        <div class="element">
            <div class="label">
                Durée *
                <div
                    class="icon icon-Aide"
                    [tooltip]="
                        'Heures:Minutes > 00:00<br>Heures jusqu\'à 9999<br>Minutes jusqu\'à 59'
                    "
                ></div>
            </div>
            <input
                type="text"
                placeholder="Durée de l'activité (hhhh:mm)"
                [(ngModel)]="content().duration"
                [ngClass]="{ 'invalid-duration': !isValidDuration() }"
            />
        </div>
    }
</div>
<div class="separator"></div>
<div class="restriction-container">
    <lib-uimm-dropdown
        [(items)]="restriction"
        (itemsChange)="handleRestrictionChange()"
        [label]="'Restriction'"
        [labelWidth]="'100%'"
        [labelDefaultColor]="'#ff7200'"
        [uniformWidth]="true"
    ></lib-uimm-dropdown>
    @if (showLocalDropdown()) {
        <lib-uimm-dropdown
            [(items)]="structures"
            (itemsChange)="handleStructureChange()"
            [label]="'Structure'"
            [labelWidth]="'100%'"
            [labelDefaultColor]="'#ff7200'"
            [uniformWidth]="true"
        ></lib-uimm-dropdown>
    }
    @if (showSiteDropdown()) {
        <lib-uimm-dropdown
            [(items)]="sites"
            (itemsChange)="handleSiteChange()"
            [label]="'Site'"
            [labelWidth]="'100%'"
            [labelDefaultColor]="'#ff7200'"
            [uniformWidth]="true"
            [multiselect]="true"
        ></lib-uimm-dropdown>
    }
</div>
<div class="separator"></div>
@if (!isLoading('MandatoryFieldComponent')) {
    <div class="main-container">
        <div class="element">
            <div class="label">Nom *</div>
            <input
                class="value"
                type="text"
                placeholder="Nom du contenu (250 caractères maximum)"
                [(ngModel)]="content().title"
            />
        </div>
        <div class="element">
            <div class="label">Description *</div>
            <textarea
                class="value"
                placeholder="Description (650 caractères maximum)"
                [(ngModel)]="content().description"
            ></textarea>
        </div>
        <div class="element">
            <div class="label">Domaine *</div>
            <lib-uimm-dropdown
                class="value"
                [(items)]="domains"
                (itemsChange)="handleDomainsChange()"
                [multiselect]="true"
                [label]="getDomainsDropDownLabel()"
                [labelWidth]="'150px'"
                [labelDefaultColor]="'#ff7200'"
                [headers]="{ search: true, count: true }"
            ></lib-uimm-dropdown>
        </div>
        <div class="element">
            <div class="label">Mot clés *</div>
            <input
                class="value"
                type="text"
                placeholder="Saisir les mots clés séparés par des ; (10 maximum)"
                [(ngModel)]="content().keywords"
            />
        </div>
        @if (content().type !== 'scorm' && content().type !== 'quiz' && !isAssembly()) {
            <div class="mark-as-completed">
                <div
                    class="icon"
                    [ngClass]="{
                        'icon-select': !content().mark_as_completed_on_open,
                        'icon-selected': content().mark_as_completed_on_open
                    }"
                    (click)="toggleMarkAsCompletedOnOpen()"
                ></div>
                <div class="text">Marquer comme terminé à l'ouverture</div>
            </div>
        }
        @if (showVisibleForAll()) {
            <div class="visible-for-all">
                <div
                    class="icon"
                    [ngClass]="{
                        'icon-select': content().visible_for_all,
                        'icon-selected': !content().visible_for_all
                    }"
                    (click)="toggleVisibleForAll()"
                ></div>
                <div class="text">Masquer ce contenu aux autres formateurs</div>
            </div>
        }
        <div class="legend">* Champs obligatoires</div>
    </div>
} @else {
    <app-loading></app-loading>
}

<div class="separator"></div>
