// Internal dependencies
import { Component, OnInit, ViewChild, model, output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LoadingService } from '@/services/loading.service';
import { LoginService } from '@/services/login.service';
import { ConfigService } from '@/services/config.service';

// Interfaces
import { Content } from '@/structures/content';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-catalog-field',
    templateUrl: './catalog-field.component.html',
    styleUrls: ['./catalog-field.component.scss'],
    imports: [MatExpansionModule, SharedModule]
})
export class CatalogFieldComponent implements OnInit {
    content = model<Content>();
    visuel = model<File>();
    showError = output<string>();

    @ViewChild('visuelInput', { static: false }) visuelInput;

    forcedStripe: Array<any>;
    stripeColors: Array<any>;
    new: Array<any>;
    visible: Array<any>;

    constructor(
        private loginService: LoginService,
        private configService: ConfigService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.new = [
            {
                key: true,
                title: 'Oui',
                selected: this.content().new !== false
            },
            {
                key: false,
                title: 'Non',
                selected: this.content().new === false
            }
        ];

        this.visible = [
            {
                key: true,
                title: 'Oui',
                selected: this.content().visible === true
            },
            {
                key: false,
                title: 'Non',
                selected: this.content().visible !== true
            }
        ];
        this.forcedStripe = [
            {
                key: true,
                title: 'Oui',
                selected: this.content().forced_stripe === true
            },
            {
                key: false,
                title: 'Non',
                selected: this.content().forced_stripe !== true
            }
        ];

        this.stripeColors = ['#007fad', '#035a77', '#955099', '#e8b600', '#f36', '#01a59b'];
        if (!this.content().forced_stripe_color) {
            this.selectStripeColor(this.stripeColors[0]);
        }
    }

    // #region Getters

    isAdmin() {
        return (
            this.loginService.getUser().roles.nationalAdmin ||
            this.loginService.getUser().roles.localAdmin
        );
    }

    isForcedStripe() {
        return this.forcedStripe.filter((status) => status.selected)[0].key === true;
    }

    isSelectedStripeColor(color) {
        return this.content().forced_stripe_color === color;
    }

    getCurrentVisuelLabel() {
        if (this.content().visuel || this.visuel()) {
            let tmp: string;
            if (this.content().visuel) {
                tmp = this.content().visuel.split('/')[this.content().visuel.split('/').length - 1];
            } else {
                tmp = this.visuel().name;
            }
            if (tmp.length < 32) {
                return decodeURIComponent(tmp) + '';
            } else {
                const hash = tmp.substring(0, 32);
                const regex = RegExp('^[a-z0-9]+$', 'g');
                if (!regex.test(hash)) {
                    return `${decodeURIComponent(tmp)}`;
                } else {
                    return `${decodeURIComponent(tmp.substring(32))}`;
                }
            }
        } else {
            return 'Glisser-déposer votre image ici (.png .jpg .gif)';
        }
    }

    isLoading(view: string): boolean {
        return this.loadingService.isLoading(view);
    }

    // #endregion Getters

    // #region Handlers

    handleNewChange(): void {
        const selectedNew = this.new.find((_new) => _new.selected);
        this.content.set({
            ...this.content(),
            new: selectedNew ? selectedNew.key : ''
        });
    }

    handleVisibleChange(): void {
        const selectedVisible = this.visible.find((visible) => visible.selected);
        this.content.set({
            ...this.content(),
            visible: selectedVisible ? selectedVisible.key : ''
        });
    }

    handleForcedStripeChange(): void {
        const selectedForcedStripe = this.forcedStripe.find(
            (forcedStripe) => forcedStripe.selected
        );
        this.content.set({
            ...this.content(),
            forced_stripe: selectedForcedStripe ? selectedForcedStripe.key : ''
        });
    }

    selectStripeColor(color: string) {
        this.content().forced_stripe_color = color;
    }

    openSelectVisuel() {
        this.visuelInput.nativeElement.click();
    }

    uploadVisuel(files: FileList) {
        if (files.item(0).size > this.getMaxFileSize()) {
            this.showError.emit('Votre fichier doit être inférieur à 200 Mo');
            return;
        }
        this.visuel.set(files.item(0));
        this.content().removevisuel = false;
    }

    deleteVisuel() {
        this.content().visuel = undefined;
        this.visuel.set(undefined);
        this.content().removevisuel = true;
    }
    // #endregion Handlers

    // #region Internals

    getMaxFileSize() {
        if (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.nationalAdmin
        ) {
            return this.configService.getConfig().maxFileSizeAdmin;
        } else {
            return this.configService.getConfig().maxFileSize;
        }
    }

    // #region Internals
}
