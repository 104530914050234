<div class="right-content">
    @if (isSelectedKey(undefined)) {
        <div class="no-type">
            <img src="./assets/img/library-default.png" />
            @if (activeTab() === 'assemblies') {
                <div>
                    <i>
                        L’<b>assemblage</b> permet d’<b>organiser les contenus</b> pédagogiques en
                        différents niveaux hiérarchiques, du plus large au spécifique.<br /><br />
                        Sélectionnez un niveau à gauche pour en savoir plus et démarrer la création
                        d’assemblage.
                    </i>
                </div>
            } @else {
                <div>
                    <i>Cliquer sur une activité pour en savoir plus</i>
                </div>
            }
        </div>
    } @else if (
        isSelectedKey('parcours') ||
        isSelectedKey('bloc') ||
        isSelectedKey('competence') ||
        isSelectedKey('module') ||
        isSelectedKey('sequence')
    ) {
        <div class="assembly">
            <div>
                <div class="legend">Vous allez créer un assemblage de type :</div>

                <div class="item">
                    <div [ngClass]="getAssemblyClass()"></div>
                    <div class="label">{{ activeContent().title }}</div>
                </div>
            </div>
            <div class="description" [innerHTML]="activeContent().description"></div>

            <div>
                <div class="legend">Cliquez sur le bouton ci-dessous pour continuer</div>

                <div class="button" (click)="handleClickConfirm()">Créer l'assemblage</div>
            </div>
        </div>
    } @else if (isSelectedKey('activity')) {
        <div class="activity">
            <div class="description" [innerHTML]="activeContent().description"></div>

            <div class="item">
                <div class="tab" (click)="handleClickSelectTab('creations')">
                    <div class="icon-video-interactive"></div>
                    Créations digitales
                </div>
                <div>
                    <b>Créations digitales</b> pour construire une activité à partir des nombreux
                    modèles à votre disposition.
                </div>
            </div>
            <div class="item">
                <div
                    class="tab"
                    [ngClass]="{ active: isSelectedTab('imports') }"
                    (click)="handleClickSelectTab('imports')"
                >
                    <div class="icon-Importer"></div>
                    Imports/Liens
                </div>
                <div>
                    <b>Imports</b> pour mettre à disposition de vos apprenants des activités qui ont
                    été réalisées par ailleurs.
                </div>
            </div>
        </div>
    } @else {
        <div class="content">
            <div class="example-container" [ngStyle]="{ 'background-image': getBackgroundStyle() }">
                @if (activeContent().example) {
                    <a class="example-button" href="{{ activeContent().example }}" target="_blank">
                        <div class="icon-voir"></div>
                        <div>Voir un exemple</div>
                    </a>
                }
            </div>
            <div class="description" [innerHTML]="activeContent().description"></div>
            @if (activeContent().data.h5p_type) {
                <div class="create-new-template">
                    <b>Pour créer à partir d'un template existant : </b>
                    <div>
                        1) Recherchez la séquence
                        <span class="red">TAF_séquence_EASi Création</span> dans la Bibliothèque
                        nationale
                    </div>
                    <div>
                        2) Recherchez ensuite le type d'activité, cliquez sur
                        <span class="icon-Voirplus"></span> puis
                        <span class="grey"> <span class="icon-Cloner"></span> Copier </span>
                    </div>
                    <i>Le contenu est alors disponible dans votre espace de création</i>
                </div>
            }
            <div class="confirm-button" (click)="handleClickConfirm()">Créer l'activité</div>
        </div>
    }
</div>
