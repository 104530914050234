<div class="header">
    <div class="icon-JustificationGauche"></div>
    <div class="label">Créer un fournisseur</div>
    <button mat-icon-button (click)="handleCloseDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="content">
    <label
        >Pour des raisons de performances, la modification apportée ne sera effective que dans 5
        minutes.</label
    >
    <mat-form-field>
        <mat-label>Nom du fournisseur</mat-label>
        <input fullWidth="true" matInput type="text" [(ngModel)]="provider" name="activity-name" />
    </mat-form-field>
</mat-dialog-content>
<div class="footer">
    <button class="button" mat-button (click)="handleClickProvider()">
        @if (isLoading()) {
            <app-loading></app-loading>
        } @else {
            Créer
        }
    </button>
</div>
